import React from 'react';
import * as yup from "yup";
import { Form, withFormik, Field } from "formik";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from "react-i18next";
import { InputLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";
import Button from '@material-ui/core/Button';

const commentSchema = yup.object().shape({
    userId: yup.string().required(),
    activityId: yup.string().required(),
    app: yup.string().required(),
    comment: yup.string().required(),
  });
  
const NewCommentForm = ({classes, submitForm}) => {
    const { t } = useTranslation("todolist");

    return (
      <Form>
        <GridItem xs={12}>
          <InputLabel className={classes.label}>
            {t("todolist.forms.fields.comment.label")}
          </InputLabel>
          <Field
            style={styles.textArea}
            name="comment"
            component="textarea"
            rows={10}
          />
        </GridItem>
        <GridItem xs={12}>
          <Button onClick={() => {submitForm()}} color="primary" variant="contained" style={styles.btnNext}>
            {t("todolist.add")}
          </Button>
        </GridItem>
      </Form>
    );
}

const styles = {
  textArea: {
    width: "100%",
    marginTop: "3%",
  },
  btnNext: {
    float: "right",
  },
};

export default withFormik({
  validationSchema: commentSchema,
  mapPropsToValues: ({activity, userId}) => {
    return {
      activityId: activity._id,
      app: activity.app,
      comment: "",
      userId: userId
    };
  },
  handleSubmit: (values, { props }) => {
    props.setOpenDrawer(false);
    props.newComment(values);
  },
})(withStyles(indexesPageStyle)(NewCommentForm));