import React from 'react'
import {useMutation, useQuery } from 'react-apollo-hooks';
import EditInstanceView from '../../views/Instances/EditInstanceView';
import { CITIES } from './gql/Queries';
import { UPDATE_INSTANCE, UPDATE_APP_TOKEN, ARCHIVED_ALL_APP_TOKENS } from './gql/Mutations';
import useGlobal from "../../store";
import { useTranslation } from "react-i18next";
import history from '../../utils/history';
import auth from '../../utils/auth';

const EditInstance = props => {
  const { t } = useTranslation("instances");
  const { match, location } = props;
  const { instance, paginationState } = location.state;
  const [{session}, globalActions] = useGlobal();
  const { data } = useQuery(CITIES);
  const updateInstance = useMutation(UPDATE_INSTANCE);
  const updateToken = useMutation(UPDATE_APP_TOKEN);
  const archivedAllTokens = useMutation(ARCHIVED_ALL_APP_TOKENS);

  const updateAppToken = async () => {
    const { id, token } = instance;
    const variables = {
      data: {
        app: id,
        token: token
      }
    }

    const resultMutation = await updateToken({ variables: variables});
    const { loading, error } = resultMutation;

    if (!loading) {
      if (!error) {
        // Add success notification
        globalActions.notification.openNotification(
          "tr",
          {
            message: t('instances.banners.upddatedToken.success'),
            color: "success"
          }
        );
      }else{
        globalActions.notification.openNotification('tr', {
            message: t('instances.banners.upddatedToken.error'),
            color: 'danger'
        });
      }
    }

  }

  const archivedAllappTokens = async () => {
    const { id } = instance;
    const variables = {
      data: {
        _id: id
      }
    }

    const resultMutation = await archivedAllTokens({ variables: variables});
    const { loading, error } = resultMutation;

    if (!loading) {
      if (!error) {
        // Add success notification
        globalActions.notification.openNotification(
          "tr",
          {
            message: t('instances.banners.upddatedToken.removedAlltokens'),
            color: "success"
          }
        );
      }else{
        globalActions.notification.openNotification('tr', {
            message: t('instances.banners.upddatedToken.error'),
            color: 'danger'
        });
      }
    }
  }

  if (
    session.user &&
    !auth.hasPermission("demoUpdate", session.user.role.permissions)
  ) {
    globalActions.notification.openNotification("tr", {
      message: "No tienes permisos",
      color: "danger",
    });

    history.push('/');
  } 

  return (
    <EditInstanceView
      paginationState={paginationState}
      updateAppToken={updateAppToken}
      archivedAllappTokens={archivedAllappTokens}
      instanceType={match.params.instance}
      instance={instance}
      cities={data}
      submitSuccess={async (values)=>{
        let file = values.icon;
        
        delete values.icon;
        delete values.icons;

        let variables = {
          data: {
            ...values
          },
          instanceId: {
            _id: match.params.id
          }
          
        }
        if (typeof file === 'object') {
          variables.file = file[file.length -1];
        }

        const resultMutation = await updateInstance({ variables: variables});

        if (!resultMutation.loading) {
          if (!resultMutation.error) {
            history.push(`/instances/${match.params.instance}`, { paginationState: paginationState });
            // Add success notification
            globalActions.notification.openNotification(
              "tr",
              {
                message: "Instancia Editada",
                color: "success"
              }
            );
          }else{
            globalActions.notification.openNotification('tr', {
                message: 'Error al editar la instancia',
                color: 'danger'
            });
          }
        }

      }}
    />

  );
};

export default EditInstance
