import React from "react";
import { Field } from "formik";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { useTranslation } from 'react-i18next';
import { hasError } from "../../../utils/forms/inputs";


const ContactIntance = props => {
  const { errors, touched } = props;
  const { t, } = useTranslation('instances');
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Field
          name="contact.name"
          render={({field}) => {
            return (
              <CustomInput
                labelText={t('instances.forms.fields.contactName.placeholder')}
                id="name"
                type={"text"}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{ ...field }}
                success={!hasError(errors, 'contact', 'name') && touched.contact}
                error={hasError(errors, 'contact', 'name')}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <Field
          name="contact.phone"
          render={({field}) => {
            return (
              <CustomInput
                labelText={t('instances.forms.fields.contactPhone.placeholder')}
                id="phone"
                type={"text"}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{ ...field }}
                success={!hasError(errors, 'contact', 'phone') && touched.contact}
                error={hasError(errors, 'contact', 'phone')}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <Field
          name="contact.email"
          render={({field}) => {
            return (
              <CustomInput
                labelText={t('instances.forms.fields.contactEmail.placeholder')}
                id="email"
                type={"text"}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{ ...field }}
                success={!hasError(errors, 'contact', 'email') && touched.contact}
                error={hasError(errors, 'contact', 'email')}
              />
            );
          }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default ContactIntance;
