import gql from "graphql-tag";

export const PARTNERS = gql`
  query PARTNERS($where: PartnerWhereinput, $skip: Int, $first: Int) {
    getAllPartners(where: $where, skip: $skip, first: $first ) {
      _id
      name
      logo
      logos
      address
      phone
      webPage
      city {
        cityName
        countryName
      }
      timezone {
        utc
        zone
      }
      location {
        type
        coordinates
      }
      isPartner
      archived
    }
  }
`;