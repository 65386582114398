import React, {useState} from 'react'
import {useQuery } from "react-apollo-hooks";
import { TUTORIALS_CATEGORIES_FEED } from './gql/Queries';
import TutorialsCategoriesListView from '../../views/Tutorials/TutorialsCategoriesListView';
import history from '../../utils/history';
import auth from '../../utils/auth';
import useGlobal from "../../store";

const TutorialsCategoryList = () => {
  const [{ session }, globalActions] = useGlobal();
    const [categories, setCategoriesFilter] = useState({
        first: 15,
        skip: 0
      });

      const {
        data: dataCategories,
        loading: loadingCategories,
      } = useQuery(TUTORIALS_CATEGORIES_FEED, {
        variables: categories,
        fetchPolicy: "network-only"
      });
      if (
        session.user &&
        !auth.hasPermission("categoryVideoView", session.user.role.permissions)
      ) {
        history.push("dashboard");
      }
    return (
        <TutorialsCategoriesListView categories={{ loading: loadingCategories, data: dataCategories }}/>
    )
}

export default TutorialsCategoryList;
