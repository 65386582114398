import React from 'react';
import {Route, Redirect} from "react-router-dom";
import session from "../session";
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    session.isAuthenticated() === true
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
);

export default PrivateRoute;
