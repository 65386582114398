import React from "react";
import { useMutation, useQuery } from "react-apollo-hooks";
import { UPDATE_INSTANCE, UPDATE_APP_TOKEN, ARCHIVED_ALL_APP_TOKENS } from "./gql/Mutations";
import useGlobal from "../../store";
import EditSubscriptionView from "../../views/Instances/EditSubscriptionView";
import { useTranslation } from "react-i18next";
import { PARTNERS } from '../Partners/gql/Queries';
import { CITIES } from './gql/Queries';
import auth from '../../utils/auth';

// utils
import history from "../../utils/history";

const EditSubscription = (props) => {
  const { t } = useTranslation("instances");
  const { match, location } = props;
  const { state } = location;
  const { instance, paginationState } = state;
  const [{session}, globalActions] = useGlobal();

  const createSubscription = useMutation(UPDATE_INSTANCE);
  const updateToken = useMutation(UPDATE_APP_TOKEN);
  const archivedAllTokens = useMutation(ARCHIVED_ALL_APP_TOKENS);
  const { data } = useQuery(CITIES);

  const { data: dataPartners } = useQuery(PARTNERS, {
    variables: {
      where: {

      },
    },
    fetchPolicy: "network-only",
  });

  const updateAppToken = async () => {
    const { id, token } = instance;
    const variables = {
      data: {
        app: id,
        token: token
      }
    }

    const resultMutation = await updateToken({ variables: variables});
    const { loading, error } = resultMutation;

    if (!loading) {
      if (!error) {
        // Add success notification
        globalActions.notification.openNotification(
          "tr",
          {
            message: t('instances.banners.upddatedToken.success'),
            color: "success"
          }
        );
      }else{
        globalActions.notification.openNotification('tr', {
            message: t('instances.banners.upddatedToken.error'),
            color: 'danger'
        });
      }
    }

  }

  const archivedAllappTokens = async () => {
    const { id } = instance;
    const variables = {
      data: {
        _id: id
      }
    }

    const resultMutation = await archivedAllTokens({ variables: variables});
    const { loading, error } = resultMutation;

    if (!loading) {
      if (!error) {
        // Add success notification
        globalActions.notification.openNotification(
          "tr",
          {
            message: t('instances.banners.upddatedToken.removedAlltokens'),
            color: "success"
          }
        );
      }else{
        globalActions.notification.openNotification('tr', {
            message: t('instances.banners.upddatedToken.error'),
            color: 'danger'
        });
      }
    }
  }

  if (
    session.user &&
    !auth.hasPermission("subscriptionUpdate", session.user.role.permissions)
  ) {
    globalActions.notification.openNotification("tr", {
      message: "No tienes permisos",
      color: "danger",
    });

    history.push('/');
  } 

  return (
    <EditSubscriptionView
      paginationState={paginationState}
      updateAppToken={updateAppToken}
      archivedAllappTokens={archivedAllappTokens}
      action={match.params.action}
      instance={instance}
      cities={data}
      partners={dataPartners && dataPartners.getAllPartners ? dataPartners.getAllPartners : []}
      submitSuccess={async (values) => {
        let file = values.icon;
        delete values.icon;
        delete values.icons;

        const variables = {
          data: {
            ...values,
          },
          instanceId: {
            _id: match.params.id,
          },
        };

        if (typeof file === 'object') {
          variables.file = file[file.length -1];
        }

        const resultMutation = await createSubscription({ variables: variables });
        const { loading, error } = resultMutation;
        if (!loading) {
          if (!error) {
            history.push(`/instances/subscriptions`, { paginationState: paginationState });
            // Add success notification
            globalActions.notification.openNotification("tr", {
              message: "Nueva subscripción",
              color: "success",
            });
          } else {
            globalActions.notification.openNotification("tr", {
              message: "Error al crear subscripción",
              color: "danger",
            });
          }
        }
      }}
    />
  );
};

export default EditSubscription;
