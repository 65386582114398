import React, { useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Form, withFormik, Field } from "formik";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import * as yup from "yup";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ButtonIcon from "../../components/styled/ButtonIcon";
import Send from "@material-ui/icons/Send";
import Datetime from "react-datetime";
import Cancel from "@material-ui/icons/Cancel";
import { NavLink } from "react-router-dom";
import Select from 'react-select';

const customMessageFormSchema = yup.object().shape({
    app: yup.string(),
    title: yup.string(),
    subject: yup.string(),
    contentHtml: yup.string(),
    expirationDate: yup.date()
  });

const CustomMessageForm = (props) => {
    const {
        setFieldValue,
        submitForm,
        apps
      } = props;

    const [ content, setContent ] = useState('');
    const handleChange = value => {
        setContent(value);
        setFieldValue('contentHtml', draftToHtml(convertToRaw(value.getCurrentContent())));
    }
    
    const { loading, data } = apps;
    const [selectApp, setSelectApp] = useState(0);

    const onAppsRender = params => {
      return params.map(param => {
        return { value: param._id, label: param.name }
      });
    }

    const selectHandleChange = selected => {
      setFieldValue('app', selected.value)
      setSelectApp(selected); 
    };

    return (
      <Form>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Select
              style={styles.selector}
              placeholder={"Cliente"}
              value={selectApp}
              options={
                !loading ? onAppsRender(data.getApps) : []
              }
              onChange={selectHandleChange}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              name="title"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText="Titulo: "
                    id="title"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...field
                    }}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              name="subject"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText="Asunto: "
                    id="subject"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...field
                    }}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Datetime
              name={"dateExpiration"}
              timeFormat={false}
              inputProps={{
                placeholder: "Fecha de caducidad",
                disabled: false,
                style: { marginBottom: "30px" }
              }}
              onChange={e => {
                if (e === "") {
                  setFieldValue("expirationDate", e);
                } else {
                  setFieldValue("expirationDate", e._d);
                }
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Editor
              editorState={content}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={handleChange}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={2}>
            <ButtonIcon
              icon={Send}
              label="Enviar"
              color={"info"}
              block={true}
              onClick={() => {
                submitForm();
              }}
            />
          </GridItem>
          <GridItem xs={3}>
            <NavLink to={"/message/customList"}>
              <ButtonIcon icon={Cancel} label="Cancelar" />
            </NavLink>
          </GridItem>
        </GridContainer>
      </Form>
    );
};

const styles = {
    editorHeight: {
        hetght: '300px'
    },
    selectors:{
      display: "flex",
      position: "absolute"
    },
}

const CustomOpportunityFormWF = withFormik({
    validationSchema: customMessageFormSchema,
    mapPropsToValues: (props) => {
      return {
        app: '',
        title: '',
        subject: '',
        contentHtml: '',
        expirationDate: ''
    }
    },
    handleSubmit: (values, {props}) => {
        props.submitSuccess(values);
    },
})(CustomMessageForm);

export default CustomOpportunityFormWF;
