import gql from "graphql-tag";

export const USERS_FEED = gql`
    query($where: UsersInput! $skip: Int $first: Int){
        users(where: $where skip: $skip first: $first){
            firstName
            lastName
            email
            access{
            roleName
            }
        }  	
    }
`;

export const ROLES_FEED = gql`
    query($where: TeamInput!){
        roles(where: $where){
            id
            name
            permissions
        }  	
    }
`;

export const TEAMS_FEED = gql`
    query($where: TeamInput!){
        teams(where: $where){
            id
            app
            name
        }  	
    }
`;
