import gql from "graphql-tag";

export const CREATE_TUTORIAL_CATEGORY_MUTATION = gql`
  mutation CreateTutorialInfoCategory($data: TutorialInfoCategoryInput!) {
    createTutorialInfoCategory(data: $data) {
      _id
      name
      position
      created
    }
  }
`;

export const UPDATE_TUTORIAL_CATEGORY_MUTATION = gql`
  mutation UpdateTutorialInfoCategory($data: TutorialInfoCategoryInput!, $where: TutorialInfoCategoryWhereUniqueInput!) {
    updateTutorialInfoCategory(data: $data, where: $where) {
      _id
      name
      position
      created
    }
  }
`;

export const CREATE_TUTORIAL_INFO_MUTATION = gql`
  mutation CreateTutorialInfo($data: TutorialInfoInput!) {
    createTutorialInfo(data: $data) {
      _id
      name
      description
      url
      type
      category{
        _id
        name
      }
      created
    }
  }
`;

export const UPDATE_TUTORIAL_INFO_MUTATION = gql`
  mutation UpdateTutorialInfo($data: TutorialInfoInput!, $where: TutorialInfoWhereUniqueInput!) {
    updateTutorialInfo(data: $data, where: $where) {
      _id
      name
      description
      url
      type
      category{
        _id
        name
      }
      created
    }
  }
`;