import cookie from "cookie";
import config from '../config';

const session = {
  isAuthenticated: () => {
    const currentCookie = cookie.parse(document.cookie);
    return !!currentCookie[config.all.BO_TOK];
  },
  setAuthToken: async (token) => {
    document.cookie = cookie.serialize(config.all.BO_TOK, token, {
      maxAge: 60 * 60 // 1 hour
    });
  },
  getAuthToken: async () => {
    const currentCookie = cookie.parse(document.cookie);
    return currentCookie[config.all.BO_TOK];
  },
  clearAuthToken: async () => {
    document.cookie = cookie.serialize(config.all.BO_TOK, '', {
      expires: new Date(1900, 1, 1)
    });
  }
};

export default session;
