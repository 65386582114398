import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { compose } from "recompose";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// own components
import LoginForm from "../../forms/Login/LoginForm";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const renderLoginErrors = (errors, t) => {
  return errors.map((error, index) => {
    return(<h6 key={index}>{t(`login.errors.${error}`)}</h6>)
  });
};

const LoginView = ({doSignIn, classes, t, errors}) => {
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <LoginForm classes={classes} t={t}
                     handleChange={(values) => {
                       doSignIn(values);
                     }} />
        </GridItem>
      </GridContainer>
      {
        errors.length > 0 ?
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4} style={{textAlign: "center"}}>
              {renderLoginErrors(errors, t)}
            </GridItem>
          </GridContainer> : []
      }
    </div>
  );
};

LoginView.propTypes = {
  doSignIn: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(loginPageStyle),
  withTranslation("login"),
)(LoginView);

