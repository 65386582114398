import React from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import UserView from '../../views/Users/UserView';
import { UPDATE_USER_BO } from './gql/Mutations';
import { ROLES_BO } from './gql/Queries';
import history from '../../utils/history';
import useGlobal from "../../store";
import { PARTNERS } from '../Partners/gql/Queries';
import auth from '../../utils/auth';

const EditUser = (props) => {
    const { location } = props;
    const { state } = location;
    const [{session}, globalActions] = useGlobal();
    const { data: dataRoles, loading: loadingRoles } = useQuery(ROLES_BO, {
        variables: {
            where:{
                archived: false
            }
        },
        fetchPolicy: "network-only"
      });

    const updateUser = useMutation(UPDATE_USER_BO);
    
    const { data: dataPartners, loading: loadingPartners } = useQuery(PARTNERS, {
      variables: { where: { }},
      fetchPolicy: "network-only",
    });

    if (
      session.user &&
      !auth.hasPermission("userUpdate", session.user.role.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: "No tienes permisos",
        color: "danger",
      });
  
      history.push('/');
    } 

    return (
      <UserView
        user={state.user}
        partners={!loadingPartners && dataPartners && dataPartners.getAllPartners ? dataPartners.getAllPartners : []}
        roles={
          !loadingRoles && dataRoles && dataRoles.rolesBo
            ? dataRoles.rolesBo
            : []
        }
        submitSuccess={async (values) => {

          const variables = {
            data: {
              ...values,
            },
            where: {
                _id: state.user._id
            }
          };

          const resultMutation = await updateUser({ variables: variables });
          if (!resultMutation.loading) {
            if (!resultMutation.error) {
              history.push(`/users/list`);
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: "Nuevo usuario creado",
                color: "success",
              });
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al crear usuario",
                color: "danger",
              });
            }
          }
        }}
      />
    );
}

export default EditUser
