import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { useTranslation } from "react-i18next";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PartnerFormWF from '../../forms/Partners/PartnerForm';

const NewPartnerView = (props) => {
    const { t } = useTranslation("partners");
    const { cities, submitSuccess, partner } = props;

    return (
      <GridContainer justify="center">
        <GridItem xs={9}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <SupervisorAccountIcon />
              </CardIcon>
              <h4>{t("partners.partner")}</h4>
            </CardHeader>
            <CardBody>
                <PartnerFormWF 
                    cities={cities}
                    partner={partner ? partner : {}}
                    submitSuccess={submitSuccess}
                />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

export default NewPartnerView;
