import React, { useState } from 'react';
import UpdateTutorialInfoView from '../../views/Tutorials/UpdateTutorialInfoView';
import { TUTORIALS_CATEGORIES_FEED } from './gql/Queries';
import { useQuery, useMutation } from 'react-apollo-hooks';
import useGlobal from "../../store";
import history from '../../utils/history';
import { UPDATE_TUTORIAL_INFO_MUTATION } from './gql/Mutations';
import auth from '../../utils/auth';

const UpdateTutorialInfo = ({ location }) => {
    const [{session}, globalActions] = useGlobal();
    const { state } = location;
    const [categories, ] = useState({});

    const { data: dataCategories, loading: loadingCategories } = useQuery(
      TUTORIALS_CATEGORIES_FEED,
      {
        variables: categories,
        fetchPolicy: "network-only"
      }
    );

    const updateTutorial = useMutation(UPDATE_TUTORIAL_INFO_MUTATION);

    if (
      session.user &&
      !auth.hasPermission("tutorialsUpdate", session.user.role.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: "No tienes permisos",
        color: "danger",
      });
  
      history.push('/');
    } 

    return (
        <UpdateTutorialInfoView
            categories={{ loading: loadingCategories, data: dataCategories }}
            tutorial={state.tutorial}
            submitSuccess={async (values) => {
              let variables = {
                data: {
                  ...values
                },
                where: {
                  _id: state.tutorial._id
                }
                
              }
              
              const resultMutation = await updateTutorial({ variables: variables});
              const { loading, error } = resultMutation;

              if (!loading) {
                if (!error) {
                  history.goBack();
                  // Add success notification
                  globalActions.notification.openNotification(
                    "tr",
                    {
                      message: "Tutorial Actualizado",
                      color: "success"
                    }
                  );
                }else{
                  globalActions.notification.openNotification('tr', {
                      message: 'Error al editar tutorial',
                      color: 'danger'
                  });
                }
              }
            }}
        />
    )
}

export default UpdateTutorialInfo;
