import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTable from "react-table";
import DivCenter from '../../components/styled-components/DivCenter';
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import Autorenew from "@material-ui/icons/Autorenew";

const UsersTable = (props) => {
    
    const { t } = useTranslation('users');
    const { users, archived, setUser, setOpenModal, setOpenModalPwd } = props;

    return (
      <ReactTable
        data={users}
        columns={[
          {
            Header: <DivCenter>{t("users.list.firstName")}</DivCenter>,
            accessor: "firstName",
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("users.list.lastName")}</DivCenter>,
            accessor: "firstName",
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("users.list.email")}</DivCenter>,
            accessor: "email",
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("users.list.role")}</DivCenter>,
            id: "id",
            accessor: (d) => {
              return d.role ? d.role.name : "";
            },
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("users.list.partner")}</DivCenter>,
            id: "partner",
            accessor: (d) => {
              return d.franchise && d.franchiseRef ? d.franchiseRef.name : "";
            },
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter> </DivCenter>,
            accessor: "actions",
            Cell: ({ row }) => (
              <div className="actions-right">
                <Tooltip title={t("users.resetPassword")}>
                  <Button
                    justIcon
                    round
                    simple
                    color="info"
                    onClick={() => {
                      setUser(row._original);
                      setOpenModalPwd(true);
                    }} 
                  >
                    <Autorenew />
                  </Button>
                </Tooltip>
                <Tooltip title={t("users.edit")}>
                  <Link
                    to={{
                      pathname: `/users/edit/` + row._original._id,
                      state: {
                        user: row._original,
                      },
                    }}
                  >
                    <Button justIcon round simple color="info">
                      <EditIcon />
                    </Button>
                  </Link>
                </Tooltip>
                <Tooltip title={t("users.archived")}>
                  <Button
                    justIcon
                    round
                    simple
                    color="danger"
                    onClick={() => {
                      setUser(row._original);
                      setOpenModal(true);
                    }}
                  >
                    <DeleteForever />
                  </Button>
                </Tooltip>
              </div>
            ),
            filterable: false,
            sortable: false,
          },
        ]}
        defaultPageSize={15}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
        style={{
          height: "700px",
          overflowY: "scroll",
        }}
      />
    );
}

export default UsersTable
