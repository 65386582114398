import React, { useState } from "react";
import TutorialInfoView from "../../views/Tutorials/TutorialInfoView";
import { useMutation, useQuery } from "react-apollo-hooks";
import useGlobal from "../../store";
import history from "../../utils/history";
import { CREATE_TUTORIAL_INFO_MUTATION } from "./gql/Mutations";
import { TUTORIALS_CATEGORIES_FEED } from "./gql/Queries";
import auth from "../../utils/auth";

const TutorialInfo = () => {
  const [{ session }, globalActions] = useGlobal();
  const [categories] = useState({});

  const { data: dataCategories, loading: loadingCategories } = useQuery(
    TUTORIALS_CATEGORIES_FEED,
    {
      variables: categories,
      fetchPolicy: "network-only",
    }
  );

  const createTutorial = useMutation(
    CREATE_TUTORIAL_INFO_MUTATION
  );
  if (
    session.user &&
    !auth.hasPermission("tutorialsCreate", session.user.role.permissions)
  ) {
    globalActions.notification.openNotification("tr", {
      message: "No tienes permisos",
      color: "danger",
    });

    history.push("dashboard");
  }
  return (
    <TutorialInfoView
      categories={{ loading: loadingCategories, data: dataCategories }}
      submitSuccess={async (values) => {
        if (values.category === "") {
          delete values.category;
        }

        let variables = {
          data: {
            ...values,
          },
        };

        const resultMutation = await createTutorial({ variables: variables });
        const { loading, error } = resultMutation;

        if (!loading) {
          if (!error) {
            // Add success notification
            globalActions.notification.openNotification("tr", {
              message: "Tutorial Creado",
              color: "success",
            });
            history.push(`/tutorials/tutorials`);
          } else {
            globalActions.notification.openNotification("tr", {
              message: "Error al crear tutorial",
              color: "danger",
            });
          }
        }
      }}
    />
  );
};

export default TutorialInfo;
