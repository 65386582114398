import React, { useEffect, useState } from 'react';
import ToDoListView from '../../views/ToDoList/ToDoListView';
import useGlobal from "../../store";
import { useMutation, useQuery } from 'react-apollo-hooks';
import { GET_ALL_ACTIVITIES } from './gql/Queries';
import { CREATE_ACTIVITY, CREATE_COMMENT, UPDATE_ACTIVITY } from './gql/Mutations';
import { useTranslation } from "react-i18next";
import { SEND_EMAIL_MUTATION } from '../Messages/gql/Mutation';

const ToDoList = (props) => {
    const { match, location } = props;
    const [{session}, globalActions] = useGlobal();
    const { t } = useTranslation("todolist");
    const app = match?.params?.app;
    const user = session?.user?._id;

    const [pagination, setPagination] = useState({
      first: 15,
      skip: 0,
      orderBy: "created_ASC",
    });

    const [filterOptions, setFilterOptions] = useState({
      app: app,
      userId: user ? user : '',
      archived: false,
      dateCreateIn: '',
      dateCreateEnd: '',
      onlyUser: true
    });

    useEffect(() => {
      let isMaster = false;
      if (session.user) {
        isMaster = session.user.role.master;
      }
      setFilterOptions({ ...filterOptions, userId: user, onlyUser: !isMaster });
    }, user);

    const { data, loading, refetch } = useQuery(GET_ALL_ACTIVITIES, { 
        variables: {
            where: {
                ...filterOptions
            },
            ...pagination
        }
     });

    const createTask = useMutation(CREATE_ACTIVITY);
    const createComment = useMutation(CREATE_COMMENT);
    const updateActivity = useMutation(UPDATE_ACTIVITY);
    const sendEmail = useMutation(SEND_EMAIL_MUTATION);

    const activities = data?.getAllActivitiesbyUser;

    async function paginationAction(values) {
        setPagination(values);
    }
    const filterAction = async (values) => {
      setFilterOptions({...filterOptions, ...values});
    };

    return (
      <ToDoListView
        paginationState={location?.state?.paginationState}
        instanceType={location?.state?.instanceType}
        app={app}
        userId={user}
        paginationAction={paginationAction}
        paginationOptions={pagination}
        filterAction={filterAction}
        activitiesData={{
          loading: loading,
          activities: activities ? activities : [],
        }}
        submitSuccess={async (values) => {
          const variables = {
            data: {
              ...values,
            },
          };

          const mutationResult = await createTask({ variables: variables });
          const { loading, error } = mutationResult;

          if (!loading) {
            if (!error) {
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: "Nueva Tarea",
                color: "success",
              });
              refetch();
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al crear tarea",
                color: "danger",
              });
            }
          }
        }}
        newComment={async (values) => {
          const variables = {
            data: {
              ...values,
            },
          };

          const mutationResult = await createComment({ variables: variables });
          const { loading, error } = mutationResult;

          if (!loading) {
            if (!error) {
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: "Nuevo Commentario",
                color: "success",
              });
              refetch();
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al crear comentario",
                color: "danger",
              });
            }
          }
        }}
        updateActivity={async (values, _id) => {
          delete values._id;
          delete values.comments;
          delete values.created;
          delete values.user;
          
          const variables = {
            data: {
              ...values,
            },
            where: {
              _id: _id,
            },
          };
          
          const mutationResult = await updateActivity({ variables: variables });
          const { loading, error } = mutationResult;

          if (!loading) {
            if (!error) {
              globalActions.notification.openNotification("tr", {
                message: "Tarea Actualizada",
                color: "success",
              });
              refetch();
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al actualizar",
                color: "danger",
              });
            }
          }
        }}
        sendEmail={async (values) => {
          const nameApp = location?.state?.instance?.name;
          const variables = {
            data: {
              subject: `${t('todolist.subjectEmail')} : ${nameApp}`,
              to: [values.email],
              contentHtml: `<p> ${t('todolist.body')} <span> ${nameApp} </span></p>`,
            }
          }

          const mutationResult = await sendEmail({ variables: variables });
          const { loading, error } = mutationResult;

          if (!loading) {
            if (!error) {
              globalActions.notification.openNotification("tr", {
                message: "Correo enviado",
                color: "success",
              });
              refetch();
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al enviar correo",
                color: "danger",
              });
            }
          }
          
        }}
      />
    );
}

export default ToDoList;
