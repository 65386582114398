import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const TablePagination = ({ paginationAction, filter, data }) => {
  return (
    <GridContainer>
      <GridItem xs={4}>
        <Button 
          style={styles.btnPreview}
          disabled={filter.skip <= 0}
          onClick={() => {
            paginationAction({ ...filter, skip: filter.skip - 15});
          }}
        >
          <KeyboardArrowLeft /> Anterior
        </Button>
      </GridItem>
      <GridItem xs={4}>
      </GridItem>
      <GridItem xs={4}>
        <Button
          style={styles.btnNext}
          disabled={data.length === 0 || data.length < 15}
          onClick={() => {
            paginationAction({ ...filter, skip: filter.skip + 15});
          }}
        >
          Siguiente <KeyboardArrowRight />
        </Button>
      </GridItem>
    </GridContainer>
  );
};

const styles = {
  btnPreview: {
    marginRight: "5px",
    float: "left"
  },
  btnNext: {
    marginRight: "5px",
    float: "right"
  },
}

export default TablePagination;
