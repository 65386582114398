import React, { useState } from "react";
import ReactTable from "react-table";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button.js";

import DeleteForever from "@material-ui/icons/DeleteForever";
import Autorenew from "@material-ui/icons/Autorenew";
import EditIcon from "@material-ui/icons/Edit";
import PersonAdd from "@material-ui/icons/PersonAdd";

import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import auth from '../../utils/auth';
import useGlobal from "../../store";
import DivCenter from '../../components/styled-components/DivCenter';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import delay from '../../utils/delay';
import TextField from '@material-ui/core/TextField';
import { APP_STATUS } from "../../config/constants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CardMembershipIcon from "@material-ui/icons/CardMembership";

const OportunityTable = (props) => {
  const { t } = useTranslation("instances");
  const {
    data,
    setInstance,
    setOpenModal,
    paginationAction,
    paginationOptions,
    filterAction,
    filterOptions,
  } = props;

  const [{ session }] = useGlobal();
  const [status, setStatus] = useState(filterOptions.status)

  let { orderBy } = paginationOptions;

  const convert = (row) => {
    if (
      session.user &&
      auth.hasPermission("opportunityToSubscription", session.user.role.permissions)
    ) {
      return (
        <Tooltip title={t("instances.tooltip.converToSubscription")}>
          <Link
            to={{
              pathname: `/instances/subscription/new/${row._original.id}`,
              state: {
                instance: row._original,
              },
            }}
          >
            <Button justIcon round simple color="info">
              <Autorenew />
            </Button>
          </Link>
        </Tooltip>
      );
    }
  };

  const edit = (row) => {
    return (
      <Tooltip title={t("instances.tooltip.edit")}>
        <Link
          to={{
            pathname: `/instances/edit/opportunity/${row._original.id}`,
            state: {
              instance: row._original,
              paginationState: paginationOptions
            },
          }}
        >
          <Button justIcon round simple color="info">
            <EditIcon />
          </Button>
        </Link>
      </Tooltip>
    );
  };

  const invitation = (row) => {
    if (
      session.user &&
      auth.hasPermission("instanceInvitation", session.user.role.permissions)
    ) {
      return (
        <Tooltip title={t("instances.tooltip.admins")}>
          <Link
            to={{
              pathname: `/instances/users/${row._original.id}`,
            }}
          >
            <Button justIcon round simple color="info">
              <PersonAdd />
            </Button>
          </Link>
        </Tooltip>
      );
    }
  };

  const archived = (row) => {
    if (
      session.user &&
      auth.hasPermission("opportunityDelete", session.user.role.permissions)
    ) {
      return (
        <Tooltip title={t("instances.tooltip.archived")}>
          <Button
            justIcon
            round
            simple
            color="danger"
            onClick={() => {
              setInstance(row._original);
              setOpenModal(true);
            }}
          >
            { !row._original.archived ?  <DeleteForever /> : <Autorenew /> }
          </Button>
        </Tooltip>
      );
    }
  };

  const sortElement = (key) => {
  
    if (orderBy) {
      orderBy = (orderBy === `${key}_ASC` ? `${key}_DESC` : `${key}_ASC`);
    } else{
      orderBy = `${key}_ASC`;
    }

    paginationAction({...paginationOptions, orderBy: orderBy });

  }

  const toDoList = (row) => {
    return (
      <Tooltip title={t("instances.tooltip.toDo")}>
        <Link
          to={{
            pathname: `/instances/subscription/${row._original.id}/toDo`,
            state: {
              instance: row._original,
              paginationState: paginationOptions,
              instanceType: 'opportunity'
            }
          }}
        >
          <Button justIcon round simple color="info">
            <PlaylistAddCheckIcon />
          </Button>
        </Link>
      </Tooltip>
    );
  }

  const plans = (row) => {
    if (
      session.user &&
      auth.hasPermission("planToInstanceView", session.user.role.permissions)
    ) {
      return (
        <Tooltip title={t("instances.tooltip.plan")}>
          <Link
            to={{
              pathname: `/instances/${row._original.id}/plan`,
              state: {
                instance: row._original,
              },
            }}
          >
            <Button justIcon round simple color="info">
              <CardMembershipIcon />
            </Button>
          </Link>
        </Tooltip>
      );
    }
  }

  return (
    <ReactTable
      data={data}
      filterable
      columns={[
        {
          Header: (
            <DivCenter
              style={styles.pointer}
              onClick={() => sortElement("name")}
            >
              {t("instances.opportunity.name")}
              {orderBy.split("_")[0] === "name" ? (
                orderBy === "name_ASC" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )
              ) : (
                <ImportExportIcon />
              )}
            </DivCenter>
          ),
          accessor: "name",
          sortable: false,
          Filter: () => (
            <TextField
              defaultValue={filterOptions.name}
              variant="outlined"
              style={{ width: "100%" }}
              onChange={async (event) => {
                const value = event.target.value;
                await delay(2000);
                filterAction({ ...filterOptions, name: value ? value : "" });
              }}
            />
          ),
        },
        {
          id: "instanceStatus",
          Header: t("instances.opportunity.status"),
          accessor: (d) => {
            return t(`instances.status.${d.instanceStatus.toUpperCase()}`);
          },
          sortable: false,
          Filter: () => (
            <Select
              style={{ width: "100%" }}
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                filterAction({ ...filterOptions, status: e.target.value });
              }}
            >
              <MenuItem
                value={APP_STATUS["ALL"]}
                key={APP_STATUS["ALL"]}
              >
                {t(`instances.status.ALL`)}
              </MenuItem>
              <MenuItem
                value={APP_STATUS["APPROACH"]}
                key={APP_STATUS["APPROACH"]}
              >
                {t(`instances.status.APPROACH`)}
              </MenuItem>
              <MenuItem
                value={APP_STATUS["PRESALE"]}
                key={APP_STATUS["PRESALE"]}
              >
                {t(`instances.status.PRESALE`)}
              </MenuItem>
              <MenuItem
                value={APP_STATUS["LOSE"]}
                key={APP_STATUS["LOSE"]}
              >
                {t(`instances.status.LOSE`)}
              </MenuItem>
            </Select>
          ),
        },
        {
          id: "opportunity.dateEnd",
          Header: t("instances.opportunity.endDate"),
          accessor: (d) => {
            if (d.opportunity.dateEnd) {
              return Moment(d.opportunity.dateEnd).local().format("DD-MM-YYYY");
            } else {
              return t(`instances.forms.fields.dateTestEnd.noDate`);
            }
          },
          filterable: false,
          sortable: false,
        },
        {
          id: "created",
          Header: (
            <DivCenter
              style={styles.pointer}
              onClick={() => sortElement("created")}
            >
              {t("instances.opportunity.created")}
              {orderBy.split("_")[0] === "created" ? (
                orderBy === "created_ASC" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )
              ) : (
                <ImportExportIcon />
              )}
            </DivCenter>
          ),
          accessor: (d) => {
            return Moment(d.created).local().format("DD-MM-YYYY");
          },
          filterable: false,
          sortable: false,
        },
        {
          Header: t("instances.demos.email"),
          accessor: "contact.email",
          filterable: false,
          sortable: false,
        },
        {
          header: "",
          id: "click-me-button",
          Cell: ({ row }) => (
            <div className="actions-right">
              {!row._original.archived ?  plans(row) : ''}
              {!row._original.archived ?  toDoList(row) : ''}
              {!row._original.archived ?  edit(row) : ''}
              {!row._original.archived ?  invitation(row) : ''}
              {!row._original.archived ?  convert(row) : ''}
              {archived(row)}
            </div>
          ),
          filterable: false,
          sortable: false,
        },
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

const styles = {
  pointer: {
    cursor: 'pointer'
  }
}

OportunityTable.prototype = {
  data: PropTypes.object,
};

export default OportunityTable;
