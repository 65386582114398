import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Form, withFormik, Field } from "formik";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from "draftjs-to-html";
import * as yup from "yup";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ButtonIcon from "../../components/styled/ButtonIcon";
import Send from "@material-ui/icons/Send";
import Datetime from "react-datetime";
import Cancel from "@material-ui/icons/Cancel";
import Select from "react-select";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import history from '../../utils/history';

const updateMessageFormSchema = yup.object().shape({
  app: yup.string(),
  to: yup.array(),
  title: yup.string(),
  subject: yup.string(),
  contentHtml: yup.string(),
  expirationDate: yup.date()
});

const UpdateMessageForm = props => {
  const {
    setFieldValue,
    submitForm,
    apps,
    message,
    users,
    setUserFilter
  } = props;

  const { loading, data } = apps;
  const { loadingUsers, dataUsers } = users;
  const { app, to, title, subject, contentHtml, expirationDate } = message;

  const contentBlock = htmlToDraft(contentHtml);
  const [content, setContent] = useState(EditorState.createWithContent(
    ContentState.createFromBlockArray(contentBlock.contentBlocks
    )
  ));
  const handleChange = value => {
    setContent(value);
    setFieldValue(
      "contentHtml",
      draftToHtml(convertToRaw(value.getCurrentContent()))
    );
  };

  

  const [selectApp, setSelectApp] = useState(0);

  const onAppsRender = params => {
    let options = params.map(param => {
      return { value: param.id, label: param.name };
    });

    return options;
  };

  const selectHandleChange = selected => {
    setFieldValue("app", selected.value);
    setSelectApp(selected);
  };
  return (
    <Form>
      {app ? (
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Select
              style={styles.selector}
              placeholder={"Cliente"}
              value={selectApp}
              options={!loading ? onAppsRender(data.getApps) : []}
              onChange={selectHandleChange}
            />
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      {to && to.length ? (
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Autocomplete
              multiple
              freeSolo
              id="tags-standard"
              options={
                !loadingUsers ? dataUsers.users.map(user => user.email) : []
              }
              defaultValue={to}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return <Chip label={option} {...getTagProps({ index })} />;
                })
              }
              onChange={(event, value) => setFieldValue("to", value)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Correo"
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      {title ? (
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              name="title"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText="Titulo: "
                    id="title"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...field
                    }}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}

      <GridContainer justify="center">
        <GridItem xs={12}>
          <Field
            name="subject"
            render={({ field }) => {
              return (
                <CustomInput
                  labelText="Asunto: "
                  id="subject"
                  type={"text"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    ...field
                  }}
                />
              );
            }}
          />
        </GridItem>
      </GridContainer>

      {expirationDate ? (
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Datetime
              name={"expirationDate"}
              timeFormat={false}
              inputProps={{
                placeholder: "Fecha de caducidad",
                disabled: false,
                style: { marginBottom: "30px" }
              }}
              defaultValue={new Date(expirationDate)}
              onChange={e => {
                if (e === "") {
                  setFieldValue("expirationDate", e);
                } else {
                  setFieldValue("expirationDate", e._d);
                }
              }}
            />
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}

      <GridContainer justify="center">
        <GridItem xs={12}>
          <Editor
            editorState={content}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            onEditorStateChange={handleChange}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={2}>
          <ButtonIcon
            icon={Send}
            label="Enviar"
            color={"info"}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
        <GridItem xs={3}>
            <ButtonIcon icon={Cancel} label="Cancelar" onClick={() => { history.goBack()}} />
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const styles = {
  editorHeight: {
    hetght: "300px",
    position: 'relative'
  },
  selector: {
    overflow: 'hidden',
     position: 'absolute'
  }
};


const UpdateMessageFormWF = withFormik({
  validationSchema: updateMessageFormSchema,
  mapPropsToValues: ({ message }) => {
    let renderMessage = {};
    const { app, to, title, subject, contentHtml, expirationDate } = message;

    if (app) {
      renderMessage.app = app;
    }
    if (title) {
      renderMessage.title = title;
    }
    if (to) {
      renderMessage.to = to;
    }
    if (subject) {
      renderMessage.subject = subject;
    }
    if (contentHtml) {
      renderMessage.contentHtml = contentHtml;
    }
    if (expirationDate) {
      renderMessage.expirationDate = expirationDate;
    }
    return renderMessage;
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(UpdateMessageForm);

export default UpdateMessageFormWF;
