import React, { useState } from "react";
import { useQuery } from "react-apollo-hooks";
import RolesView from "../../views/Users/RolesView";
import { ROLES_BO } from "./gql/Queries";
import auth from "../../utils/auth";
import useGlobal from "../../store";
import history from "../../utils/history";

const Roles = (props) => {
  const [{ session }] = useGlobal();
  const [filters, setFilters] = useState({
    where: {
      archived: false,
    },
    first: 15,
    skip: 0,
  });

  const { data, loading } = useQuery(ROLES_BO, {
    variables: filters,
    fetchPolicy: "network-only",
  });

  if (
    session.user &&
    !auth.hasPermission("roleView", session.user.role.permissions)
  ) {
    history.push("dashboard");
  }

  return <RolesView roles={data} loading={loading} />;
};

export default Roles;
