import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import {Form, Field, Formik} from 'formik';

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Clear from "@material-ui/icons/Clear";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";

import {hasError} from "../../utils/forms/inputs";

const loginValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required()
});

const InputAdornmentMail = () => {
  return (
    <InputAdornment position="end">
      <Email style={{color: '#555'}}/>
    </InputAdornment>
  );
};
const InputAdornmentError = () => {
  return (
    <InputAdornment position="end">
      <Clear style={{color: 'red'}}/>
    </InputAdornment>
  );
};
const InputAdornmentPassword = () => {
  return (
    <InputAdornment position="end">
      <Icon style={{color: '#555'}}>
        lock_outline
      </Icon>
    </InputAdornment>
  )
};

const LoginForm = ({classes, t, handleChange}) => {
  const [cardAnimation, setCardAnimation] = useState("cardHidden");

  useEffect(() => {
    let timer = setTimeout(() => {
      setCardAnimation("");
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Formik
      initialValues={{email: '', password: ''}}
      onSubmit={(values, actions) => {
        handleChange(values);
        actions.setSubmitting(false);
      }}
      validationSchema={loginValidationSchema}
      render={({errors}) => {
        return(
          <Form>
            <Card login className={classes[cardAnimation]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>{t('login.welcome')}</h4>
              </CardHeader>
              <CardBody>
                <Field name="email" render={({field}) => {
                  return (
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      type={"text"}
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={hasError(errors, 'email')}
                      inputProps={{
                        autoComplete: "true",
                        endAdornment: (
                          hasError(errors, 'email') ?
                            <InputAdornmentError/> :
                            <InputAdornmentMail/>
                        ),
                        ...field
                      }}
                    />
                  );
                }} />
                <Field name="password" render={({field}) => {
                  return (
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={hasError(errors, 'password')}
                      inputProps={{
                        autoComplete: "true",
                        endAdornment: (
                          hasError(errors, 'password') ?
                            <InputAdornmentError/> :
                            <InputAdornmentPassword/>
                        ),
                        type: "password",
                        ...field
                      }}
                    />
                  );
                }} />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="info" simple size="lg" block type="submit">
                  Iniciar sesión
                </Button>
              </CardFooter>
            </Card>
          </Form>
        );
      }}
    />
  );
};

LoginForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default LoginForm;
