import React from 'react'
import { useTranslation } from "react-i18next";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import EditSubscriptionForm from '../../forms/Instances/EditSubscriptionForm';

const NewSubscriptionView = (props) => {
    const {
      instance,
      submitSuccess,
      updateAppToken,
      archivedAllappTokens,
      cities,
      partners,
    } = props;
    const {t} = useTranslation('instances');

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="info">
                  <Apps />
                </CardIcon>
                <h4>{t("instances.subscription.newSubscription")}</h4>
              </CardHeader>
              <CardBody>
                <EditSubscriptionForm
                  cities={cities}
                  updateAppToken={updateAppToken}
                  archivedAllappTokens={archivedAllappTokens}
                  instance={instance}
                  submitSuccess={submitSuccess}
                  partners={partners}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
}

export default NewSubscriptionView;
