import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { compose } from "recompose";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";
import history from "../../utils/history";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Position from "components/Position";
import Button from "components/Button";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import CircularProgress from "@material-ui/core/CircularProgress";

import DemosTable from "../../tables/Instances/DemosTable";
import TablePagination from "../../paginations/table/TablePagination";
import FilterDemoForm from "../../forms/Instances/FilterDemoForm";
import ArchivedInstanceConfirmModal from "../../modals/ArchivedInstanceConfirmModal";

const DemosView = props => {
  const {
    classes,
    data,
    loading,
    paginationAction,
    paginationOptions,
    filterAction,
    filterOptions,
    archivedInstance
  } = props;
  const { t } = useTranslation("instances");

  const [instances, setInstances] = useState([]);
  const [paginationStatus, setPaginationStatus] = useState({
    hasPreviousPage: false,
    hasNextPage: true
  });

  const [openModal, setOpenModal] = useState(false);
  const [instance, setInstance] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  const getData = async () => {
    if (data && data?.instancesConnection) {
      const { instancesConnection } = await data;
      const { totalCount, edges, pageInfo } = await instancesConnection;
      setInstances(edges);
      setPaginationStatus(pageInfo);
      setTotalCount(totalCount);
    }
  }

  useEffect(() => {
    getData();
  });

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Position rigth>
            <Button
              id="demo-button"
              color={"primary"}
              onClick={() => history.push("/instances/new")}
            >
              {t("instances.demos.newDemo")}
            </Button>
            {/* <Button color={"danger"}>
              {t("instances.demos.archivedList")}
            </Button> */}
          </Position>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <Apps />
              </CardIcon>
              <div style={styles.right}>
                <Button
                  size="sm"
                  color="info"
                  onClick={() => {
                    filterAction({
                      ...filterOptions,
                      archived: !filterOptions.archived,
                    });

                    paginationAction({
                      ...paginationOptions,
                      numPage: 1,
                      first: 15, 
                      skip: 0,
                    });
                  }}
                >
                  {!filterOptions.archived
                    ? t("instances.demos.viewArchivedDemo")
                    : t("instances.demos.viewDemo")}
                </Button>
              </div>
              <h4>{t("instances.demos.title")}</h4>
            </CardHeader>
            <CardBody>
              <FilterDemoForm submitSuccess={filterAction} />

              <TablePagination
                classes={classes}
                paginationAction={paginationAction}
                paginationOptions={paginationOptions}
                paginationStatus={paginationStatus}
                totalCount={totalCount}
              />
              {loading ? (
                <div className={classes.progressCnt}>
                  <CircularProgress
                    id="loading-circle"
                    className={classes.progress}
                  />
                </div>
              ) : (
                <DemosTable
                  data={instances}
                  setInstance={setInstance}
                  setOpenModal={setOpenModal}
                  paginationAction={paginationAction}
                  paginationOptions={paginationOptions}
                  filterAction={filterAction}
                  filterOptions={filterOptions}
                />
              )}

              <TablePagination
                classes={classes}
                paginationAction={paginationAction}
                paginationOptions={paginationOptions}
                paginationStatus={paginationStatus}
                totalCount={totalCount}
              />
            </CardBody>
          </Card>
        </GridItem>
        <ArchivedInstanceConfirmModal
          instance={instance}
          openModal={openModal}
          setOpenModal={() => {
            setOpenModal(false);
          }}
          archivedInstance={archivedInstance}
        />
      </GridContainer>
    </React.Fragment>
  );
};

const styles = {
  right: {
    float: "right"
  }
};

DemosView.prototype = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
  paginationAction: PropTypes.func.isRequired,
  paginationOptions: PropTypes.bool.isRequired,
  filterAction: PropTypes.func.isRequired
};

export default compose(withStyles(indexesPageStyle))(DemosView);
