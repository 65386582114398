import React from 'react'
import GeneralMessageView from '../../views/Messages/GeneralMessageView';
import { MESSAGE_MUTATION } from './gql/Mutation';
import { useMutation } from "react-apollo-hooks";

import useGlobal from "../../store";
import history from '../../utils/history';
import auth from '../../utils/auth';

const GeneralMessages = () => {
    const [{session}, globalActions] = useGlobal();
    const createMessage = useMutation(MESSAGE_MUTATION);

    if (
      session.user &&
      !auth.hasPermission("messageGeneralCreate", session.user.role.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: "No tienes permisos",
        color: "danger",
      });

      history.push('dashboard');
    }

    return (
      <GeneralMessageView
        submitSuccess={async (values) => {

          let variables = {
            data: {
              ...values,
              franchise: session.user.franchise
            }
          };
          
          const resultMutation = await createMessage({ variables: variables });
          const { loading, error } = resultMutation;

          if (!loading) {
            if (!error) {
              
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: "Mensaje enviado",
                color: "success"
              });
              history.push(`/message/generalList`);
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al enviar mensaje",
                color: "danger"
              });
            }
          }
        }}
      />
    );
}

export default GeneralMessages;