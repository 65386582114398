import React, {useState} from 'react'
import { useMutation } from "react-apollo-hooks";
import { useQuery } from "react-apollo-hooks";

//own components
import RealtimeLocationsConfigTable from "../../tables/Instances/RealtimeLocationsConfigTable";
import RealtimeLocationsConfigUpsertForm from "../../forms/Instances/RealtimeLocationsConfigUpsertForm";

//custom components
import Button from "components/CustomButtons/Button.jsx";

import {REALTIME_LOCATION_CONFIGS, SEGMENTS_INSTANCE} from "./gql/Queries";
import {UPSERT_REALTIME_LOCATION_CONFIG} from "./gql/Mutations";

import useGlobal from "../../store";

const RealtimeLocationsConfig = ({instance}) => {

  const [editMode, setEditMode] = useState(false);
  const [currentRealtimeLocationsConfig, setCurrentRealtimeLocationsConfig] = useState(null);
  const [, globalActions] = useGlobal();
  const upsertConfig = useMutation(UPSERT_REALTIME_LOCATION_CONFIG);
  const {loading, data, refetch} = useQuery(REALTIME_LOCATION_CONFIGS, {
    variables: {
      instanceId: instance.id
    }
  });
  const {data: dataSegments} = useQuery(SEGMENTS_INSTANCE, {
    variables: {
      instanceId: instance.id
    }
  });

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <h4>Configuracion de Segmentos para localización</h4>
        {
          !editMode ?
            <div>
              <Button size="sm"
                      color="info"
                      onClick={() => {
                        setCurrentRealtimeLocationsConfig(null);
                        setEditMode(true);
                      }}>
                Agregar configuración
              </Button>
            </div> :
            []
        }
      </div>
      {
        editMode ?
          <RealtimeLocationsConfigUpsertForm
            segments={dataSegments ? dataSegments.segments : []}
            instance={instance}
            cancelEdit={() => {
              setEditMode(false);
            }}
            submitSuccess={async (values) => {
              setEditMode(false);
              await upsertConfig({
                variables: {
                  data: {
                    instanceId: instance.id,
                    segmentId: values.segmentId,
                    interval: parseInt(values.interval),
                    schedule: `${values.scheduleInit}-${values.scheduleEnd}`,
                    active: values.active
                  }
                }
              });
              await refetch();
              globalActions.notification.openNotification(
                "tr",
                {
                  message: "Se guardaron los cambios",
                  color: "success"
                }
              );
            }}
            config={currentRealtimeLocationsConfig} /> : []
      }
      {
        !loading ?
          <RealtimeLocationsConfigTable
            realtimeLocationsConfigs={data.realtimeLocationConfigs}
            realtimeLocationsConfigEdit={(realtimeLocationsConfig) => {
              setCurrentRealtimeLocationsConfig(realtimeLocationsConfig);
              setEditMode(true);
            }}
          />
          : []
      }
    </div>
  );
}

export default RealtimeLocationsConfig;
