import React, { useEffect, useState } from 'react'
import {  INSTANCES_FEED } from "./gql/Queries";
import { useQuery, useMutation } from "react-apollo-hooks";
import SubscriptionsView from '../../views/Instances/SubscriptionsView';
import auth from '../../utils/auth';
import useGlobal from "../../store";
import history from '../../utils/history';
import { ARCHIVED_INSTANCE } from './gql/Mutations';

const Subscriptions = (props) => {
  const [{ session }, ] = useGlobal();
  const { location } = props;
  const paginationState = location?.state?.paginationState;

  const [paginationOptions, setPaginationOptions] = useState({
    numPage: paginationState ? paginationState.numPage : 1,
    first: paginationState ? paginationState.first : 15,
    skip: paginationState ? paginationState.skip : 0,
    orderBy: 'created_ASC'
  });
  
  const [filterOptions, setFilterOptions] = useState({
      instanceType: 'Subscription',
      name: '',
      status: '',
      dateUpdateIn: '',
      dateUpdateEnd: '',
      dateCreateIn: '',
      dateCreateEnd: '',
      archived: false
  });

    useEffect(() => {
      const { user } = session;
      if (user) {
        if (user.role.master) {
          setFilterOptions({...filterOptions});
        }else{
          setFilterOptions({...filterOptions, franchise: user.franchise});
        }
      }
      
    }, [session]);

    const paginationAction = async values => {
      setPaginationOptions(values);
    };

    const filterAction = async values => {
      values.instanceType = "Subscription";
      setFilterOptions({...filterOptions, ...values});
    };

    const { data, error, loading, refetch } = useQuery(INSTANCES_FEED, {
      variables: {
        where: filterOptions,
        first: paginationOptions.first,
        skip: paginationOptions.skip,
        orderBy: paginationOptions.orderBy
      },
      fetchPolicy: "network-only"
    });

    const updateInstance = useMutation(ARCHIVED_INSTANCE);

    const archivedInstance = async (instance) => {
      // Get instance info for archived
      const resultMutation = await updateInstance({
        variables: {
          data: {
            archived: !filterOptions.archived,
          },
          where: {
            _id: instance.id,
          },
        },
      });

      if (!resultMutation.loading) {
        if (!resultMutation.error) {
          refetch();
        }
      }
    };

    if (
      session.user &&
      !auth.hasPermission("subscriptionView", session.user.role.permissions)
    ) {
      history.push('dashboard');
    } 

    return (
      <SubscriptionsView
        data={data}
        loading={loading}
        paginationAction={paginationAction}
        paginationOptions={paginationOptions}
        filterAction={filterAction}
        filterOptions={filterOptions}
        archivedInstance={archivedInstance}
      />
    );
}

export default Subscriptions;
