import { gql } from "apollo-boost";

export const CREATE_GEOFENCE_GROUP_MUTATION = gql`
  mutation createGeofenceGroup($data: GeofencesGroupInput!) {
    createGeofenceGroup(data: $data) {
      _id
      name
    }
}
`;

export const UPDATE_GEOFENCE_MUTATION = gql`
  mutation UPDATE_GEOFENCE(
    $data: GeofenceInput
    $where: GeofenceWhereUniqueInput
  ) {
    updateGeofence(data: $data, where: $where) {
      _id
      paymentGeofenceGroup
      name
      color
      type
      zoom
      shape
      created
    }
  }
`;

export const CREATE_GEOFENCE_MUTATION = gql`
  mutation CREATE_GEOFENCE(
    $data: GeofenceInput
  ) {
    createGeofence(data: $data) {
      _id
      paymentGeofenceGroup
      name
      color
      type
      zoom
      shape
      created
    }
  }
`;

export const UPDATE_GEOFENCE_GROUP_MUTATION = gql`
  mutation UPDATE_GEOFENCE_GROUP(
    $data: GeofencesGroupInput
    $where: GeofencesGroupWhereUniqueInput
  ) {
    updateGeofenceGroup(data: $data, where: $where) {
      _id
      name
      geofences {
        _id
        paymentGeofenceGroup
        name
        color
        type
        zoom
        shape
        created
      }
      archived
      created
    }
  }
`;

export const UPDATE_GEOFENCE_RECORD_MUTATION = gql`
  mutation UPDATE_GEOFENCE_RECORD(
    $data: PaymentGeofenceRecordInput
    $where: GeofencesGroupWhereUniqueInput
  ) {
    updateGeofenceRecord(data: $data, where: $where) {
      _id
      app
      activated
      created
      franchise
      masterFranchise
    }
  }
`;