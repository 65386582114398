import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";

import DivCenter from "../../components/styled-components/DivCenter";
import Button from "components/CustomButtons/Button.js";

import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";

const RealtimeLocationsConfigTable = ({ realtimeLocationsConfigs, realtimeLocationsConfigEdit }) => {
  console.log('realtimeLocationsConfigs', realtimeLocationsConfigs);

  return (
    <ReactTable
      data={realtimeLocationsConfigs}
      columns={[
        {
          Header: <DivCenter>Segmento</DivCenter>,
          accessor: "segmentRef.name",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10,
        },
        {
          Header: <DivCenter>Usuarios</DivCenter>,
          accessor: "usersCount",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10,
        },
        {
          Header: <DivCenter>Intervalo</DivCenter>,
          accessor: "interval",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10,
        },
        {
          Header: <DivCenter>Horario del dia</DivCenter>,
          accessor: "schedule",
          Cell: ({original}) => {
            const [start, end] = original.schedule.split('-');
            return <p>{`De ${start} a ${end} horas`}</p>
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10
        },
        {
          Header: "",
          id: "actions",
          Cell: ({original}) => {
            return (
              <div>
                <Tooltip title="Editar configuracion">
                  <Button justIcon round simple color="info" onClick={() => {
                    realtimeLocationsConfigEdit(original)
                  }}>
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10
        }
      ]}
      defaultPageSize={realtimeLocationsConfigs ? realtimeLocationsConfigs.length : 0}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

export default RealtimeLocationsConfigTable;
