import React, { useEffect, useState } from "react";
import GeneralMessageListView from "../../views/Messages/GeneralMessageListView";
import { useQuery } from "react-apollo-hooks";
import { MESSAGES_FEED } from "./gql/Queries";
import useGlobal from "../../store";
import history from '../../utils/history';
import auth from '../../utils/auth';


const GeneralMessagesList = () => {
  const [{session}, globalActions] = useGlobal();
  const [messagesGeneral, setMessageGeneral] = useState({
    where: {},
    skip: 0,
    first: 15
  });

  useEffect(() => {
    const { user } = session;
    if (user) {
      
      if (user.role.master) {
        setMessageGeneral({ ...messagesGeneral, where: { ...messagesGeneral.where }});
      }else{
        setMessageGeneral({ ...messagesGeneral, where: { ...messagesGeneral.where,  franchise: user.franchise  }});
      }
    }
    
  }, [session]);

  const paginationAction = (values) => {
    setMessageGeneral(values);
  }

  if (
    session.user &&
    !auth.hasPermission("messageGeneralView", session.user.role.permissions)
  ) {
    history.push("dashboard");
  }  

  const { data: dataMessageGeneral, loading: loadingMessageGeneral } = useQuery(
    MESSAGES_FEED,
    {
      variables: messagesGeneral,
      fetchPolicy: "network-only",
    }
  );

  return (
    <GeneralMessageListView
      messages={{
        loading: loadingMessageGeneral,
        data:
          dataMessageGeneral && dataMessageGeneral.getMessages
            ? dataMessageGeneral.getMessages
            : [],
      }}
      paginationAction={paginationAction}
      filter={messagesGeneral}
    />
  );
};

export default GeneralMessagesList;
