import React, {useState} from 'react'
import NewSubscriptionView from '../../views/Instances/NewSubscriptionView';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { ARCHIVED_ALL_APP_TOKENS, CREATE_SUBSCRIPTION, UPDATE_APP_TOKEN, UPDATE_INSTANCE } from './gql/Mutations';
import useGlobal from "../../store";
import { PARTNERS } from '../Partners/gql/Queries';
import history from '../../utils/history';
import { CITIES } from './gql/Queries';
import { useTranslation } from "react-i18next";
import { ValuesOfCorrectTypeRule } from 'graphql';

const NewSubscription = (props) => {
    const { t } = useTranslation("instances");
    const { match, location } = props;
    const { state } = location;
    const { instance } = state;
    const [, globalActions] = useGlobal();
    const createSubscription = useMutation(UPDATE_INSTANCE);
    const updateToken = useMutation(UPDATE_APP_TOKEN);
    const archivedAllTokens = useMutation(ARCHIVED_ALL_APP_TOKENS);
    const { data: dataPartners } = useQuery(PARTNERS, {
      variables: {
        where: {
  
        },
      },
      fetchPolicy: "network-only",
    });
    const { data } = useQuery(CITIES);
  
    const updateAppToken = async () => {
      const { id, token } = instance;
      const variables = {
        data: {
          app: id,
          token: token
        }
      }
  
      const resultMutation = await updateToken({ variables: variables});
      const { loading, error } = resultMutation;
  
      if (!loading) {
        if (!error) {
          // Add success notification
          globalActions.notification.openNotification(
            "tr",
            {
              message: t('instances.banners.upddatedToken.success'),
              color: "success"
            }
          );
        }else{
          globalActions.notification.openNotification('tr', {
              message: t('instances.banners.upddatedToken.error'),
              color: 'danger'
          });
        }
      }
  
    }
  
    const archivedAllappTokens = async () => {
      const { id } = instance;
      const variables = {
        data: {
          _id: id,
        },
      };

      const resultMutation = await archivedAllTokens({ variables: variables });
      const { loading, error } = resultMutation;

      if (!loading) {
        if (!error) {
          // Add success notification
          globalActions.notification.openNotification("tr", {
            message: t("instances.banners.upddatedToken.removedAlltokens"),
            color: "success",
          });
        } else {
          globalActions.notification.openNotification("tr", {
            message: t("instances.banners.upddatedToken.error"),
            color: "danger",
          });
        }
      }
    }

    return (
        <NewSubscriptionView
            action={match.params.action} 
            updateAppToken={updateAppToken}
            archivedAllappTokens={archivedAllappTokens}
            instance={instance}
            cities={data}
            partners={dataPartners && dataPartners.getAllPartners ? dataPartners.getAllPartners : []}
            submitSuccess={async (values)=>{
                let file = values.icon;
          
                delete values.icon;
                delete values.icons;
        
                let variables = {
                  data: {
                    ...values
                  },
                  instanceId: {
                    _id: match.params.id
                  }
                  
                }
                if (typeof file === 'object') {
                  variables.file = file[file.length -1];
                }

                
                const resultMutation = await createSubscription({variables: variables});
                const { loading, error } = resultMutation;

                if (!loading) {
                  if (!error) {
                    history.push(`/instances/subscriptions`);
                    // Add success notification
                    globalActions.notification.openNotification(
                      "tr",
                      {
                        message: "Nueva subscripción",
                        color: "success"
                      }
                    );
                  }else{
                    globalActions.notification.openNotification('tr', {
                        message: 'Error al crear subscripción',
                        color: 'danger'
                    });
                  }
                }

            }}
        />
    );
}

export default NewSubscription;
