import React from 'react';
import {Form, withFormik} from "formik";
import * as yup from 'yup';
import withStyles from "@material-ui/core/styles/withStyles";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Datetime from "react-datetime";
import { InputLabel } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import moment from 'moment';

/*
* Validation Schema for deFormSchema
* */
const filterDeemoFormSchema = yup.object().shape({
  dateIn: yup.string(),
  dateEnd: yup.string()
});

const FilterDemoForm = (props) => {
    // Formik bag
    const {
      setFieldValue,
      submitSuccess,
      values,
      classes
    } = props;

    return (
        <Form>
            <GridContainer>
              <GridItem xs={3}>
                <InputLabel className={classes.label}>
                  Fecha de inicio
                </InputLabel>
                <Datetime
                  name={'dateIn'}
                  timeFormat={false}
                  onChange={e => {
                    let date = e;
                    if (e === '') {
                      setFieldValue('dateCreateIn', e);
                    }else{
                      date = moment(e._d).startOf('day').toString();
                      setFieldValue('dateCreateIn', moment(e._d).startOf('day').toString());
                    }
                    submitSuccess({...values, dateCreateIn: date});     
                  }}
                />
              </GridItem>

              <GridItem xs={3}>
                <InputLabel className={classes.label}>
                  Fecha de Final
                </InputLabel>
                <Datetime
                  name={'dateEnd'}
                  timeFormat={false}
                  onChange={e => {
                    let date = e;
                    if (e === '') {
                      setFieldValue('dateCreateEnd', e);
                    }else{
                      date = moment(e._d).endOf('day').toString();
                      setFieldValue('dateCreateEnd', moment(e._d).endOf('day').toString());
                    }
                    submitSuccess({...values, dateCreateEnd: date});
                  }}
                />
              </GridItem>
            </GridContainer>
        </Form>
    )
}

FilterDemoForm.prototype = {
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

const FilterDemoFormWF = withFormik({
  validationSchema: filterDeemoFormSchema,
  mapPropsToValues: props => ({
    dateCreateIn: "",
    dateCreateEnd:""
  }),
  handleSubmit: (values, {props}) => {
    props.submitSuccess(values);
  },
})(withStyles(indexesPageStyle)(FilterDemoForm));

export default FilterDemoFormWF;