import React, { useEffect, useState } from "react";
import CustomMessageView from "../../views/Messages/CustomMessageView";
import { useQuery, useMutation } from "react-apollo-hooks";
import { APPS_FEED } from "./gql/Queries";
import { MESSAGE_MUTATION } from "./gql/Mutation";
import useGlobal from "../../store";
import history from "../../utils/history";
import auth from "../../utils/auth";

const CustomMessages = () => {
  const [{ session }, globalActions] = useGlobal();
  const [apps, setApps] = useState({
    where: {
      name: "",
      status: ""
    },
  });

  useEffect(() => {
    const { user } = session;
    if (user) {
      if (user.role.master) {
        setApps({ where:{...apps.where} });
      }else{
        setApps({ where : {...apps.where, franchise: user.franchise} });
      }
    }
  }, [session]);

  const { data: dataApps, loading: loadingApps } = useQuery(APPS_FEED, {
    variables: apps,
    fetchPolicy: "network-only",
  });

  const createMessage = useMutation(MESSAGE_MUTATION);

  if (
    session.user &&
    !auth.hasPermission("messageCustomCreate", session.user.role.permissions)
  ) {
    globalActions.notification.openNotification("tr", {
      message: "No tienes permisos",
      color: "danger",
    });

    history.push('dashboard');
  } 

  return (
    <CustomMessageView
      apps={{ loading: loadingApps, data: dataApps }}
      submitSuccess={async (values) => {
        let variables = {
          data: {
            ...values,
            franchise: session.user.franchise
          },
        };

        const resultMutation = await createMessage({ variables: variables });
        const { loading, error } = resultMutation;

        if (!loading) {
          if (!error) {
            // Add success notification
            globalActions.notification.openNotification("tr", {
              message: "Mensaje enviado",
              color: "success",
            });
            history.push(`/message/customList`);
          } else {
            globalActions.notification.openNotification("tr", {
              message: "Error al enviar mensaje",
              color: "danger",
            });
          }
        }
      }}
    />
  );
};

export default CustomMessages;
