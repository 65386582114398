import React from 'react'
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx"
import { CircularProgress } from '@material-ui/core';
import history from '../../utils/history';
import Button from "components/CustomButtons/Button.jsx";
import MessageTable from '../../tables/Messages/MessageTable';
import TablePagination from "../../paginations/table/SimpleTaablePagination";

const EmailMessagesListView = ({messages, paginationAction, filter}) => {
    const { loading, data } = messages;
    const config ={
      app: true,
      title: false,
      to: true,
      expirationDate: false
    }
    return (
      <Card>
        <CardHeader>
          <div style={styles.right}>
            <Button
              size="sm"
              color="info"
              onClick={() => history.push(`/message/email`)}
            >
              Nuevo correo
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <TablePagination
            data={data && data.getMessagesByApp ? data.getMessagesByApp : []}
            paginationAction={paginationAction}
            filter={filter}
          />
          {loading ? (
            <div style={styles.progressCnt}>
              <CircularProgress style={styles.progress} />
            </div>
          ) : (
            <MessageTable
              messages={data.getMessagesByApp ? data.getMessagesByApp : []}
              config={config}
            />
          )}
        </CardBody>
      </Card>
    );
}
const styles = {
  progress: {
    margin: "16px"
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%"
  },
  right: {
    float: "right"
  }
};

export default EmailMessagesListView;