import React from "react";
import useGlobal from '../../store';
import Layout from "../Layout";
import ResetPassword from "../Users/ResetPassword";

function Home(props) {
  const [ session , ] = useGlobal(
    state => state.session,
    actions => actions,
  );
  
  
  if(session.session.user && session.session.user.resetPassword === true){
    return <ResetPassword />
  }else{
    return <Layout currentUser={session.session.user} {...props}/>
  }
  
}

export default Home;
