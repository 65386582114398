import React, { useState } from "react";
import ReactTable from "react-table";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import PersonAdd from "@material-ui/icons/PersonAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import auth from '../../utils/auth';
import useGlobal from "../../store";
import DivCenter from '../../components/styled-components/DivCenter';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import delay from '../../utils/delay';
import TextField from '@material-ui/core/TextField';
import { APP_STATUS } from "../../config/constants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autorenew from "@material-ui/icons/Autorenew";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CardMembershipIcon from "@material-ui/icons/CardMembership";

const SubscriptionsTable = props => {
  const { t } = useTranslation("instances");
  const {
    data,
    setInstance,
    setOpenModal,
    paginationAction,
    paginationOptions,
    filterAction,
    filterOptions,
  } = props;

  const [{session}, ] = useGlobal();
  const [status, setStatus] = useState(filterOptions.status)

  let { orderBy } = paginationOptions;

  const toDoList = (row) => {
    return (
      <Tooltip title={t("instances.tooltip.toDo")}>
        <Link
          to={{
            pathname: `/instances/subscription/${row._original.id}/toDo`,
            state: {
              instance: row._original,
              paginationState: paginationOptions,
              instanceType: 'opportunity'
            }
          }}
        >
          <Button justIcon round simple color="info">
            <PlaylistAddCheckIcon />
          </Button>
        </Link>
      </Tooltip>
    );
  }

  const edit = (row) => {
    return (
      <Tooltip title={t("instances.tooltip.edit")}>
        <Link
          to={{
            pathname: `/instances/subscription/edit/${row._original.id}`,
            state: {
              instance: row._original,
              paginationState: paginationOptions
            },
          }}
        >
          <Button justIcon round simple color="info">
            <EditIcon />
          </Button>
        </Link>
      </Tooltip>
    );
  };

  const invitation = (row) => {
    if (
      session.user &&
      auth.hasPermission("instanceInvitation", session.user.role.permissions)
    ) {
      return (
        <Tooltip title={t("instances.tooltip.admins")}>
          <Link
            to={{
              pathname: `/instances/users/${row._original.id}`,
            }}
          >
            <Button justIcon round simple color="info">
              <PersonAdd />
            </Button>
          </Link>
        </Tooltip>
      );
    }
  };

  const archived = (row) => {
    if (
      session.user &&
      auth.hasPermission("subscriptionDelete", session.user.role.permissions)
    ) {
      return (
        <Tooltip title={t("instances.tooltip.archived")}>
          <Button
            justIcon
            round
            simple
            color="danger"
            onClick={() => {
              setInstance(row._original);
              setOpenModal(true);
            }}
          >
            { !row._original.archived ?  <DeleteForever /> : <Autorenew /> }
          </Button>
        </Tooltip>
      );
    }
  };

  const viewDashboard = (row) => {
    const appIdParamsEnv = window._env_.DATA_STUDIO_APP_ID_PARAMS || '';
    const appIdParam = appIdParamsEnv.split(';');
    const params = appIdParam.reduce((result, param) => ({
      ...result,
      [param]: row._original.id
    }), {});
    const paramsAsString = JSON.stringify(params);
    const encodedParams = encodeURIComponent(paramsAsString)

    return (
      <Tooltip title={t("instances.tooltip.viewDashboard")}>
        <a target="_blank"
           href={`${window._env_.DATA_STUDIO_APP_REPORT.replace('_email_', `${session.user.email}`)}?params=${encodedParams}`}>
        <Button justIcon round simple color="warning">
            <VisibilityIcon />
          </Button>
        </a>
      </Tooltip>
    );
  };

  const plans = (row) => {
    if (
      session.user &&
      auth.hasPermission("planToInstanceView", session.user.role.permissions)
    ) {
      return (
        <Tooltip title={t("instances.tooltip.plan")}>
          <Link
            to={{
              pathname: `/instances/${row._original.id}/plan`,
              state: {
                instance: row._original,
              },
            }}
          >
            <Button justIcon round simple color="info">
              <CardMembershipIcon />
            </Button>
          </Link>
        </Tooltip>
      );
    }
  }


  const sortElement = (key) => {

    if (orderBy) {
      orderBy = (orderBy === `${key}_ASC` ? `${key}_DESC` : `${key}_ASC`);
    } else{
      orderBy = `${key}_ASC`;
    }

    paginationAction({...paginationOptions, orderBy: orderBy });

  }

  return (
    <ReactTable
      data={data}
      filterable
      columns={[
        {
          Header: (
            <DivCenter
              style={styles.pointer}
              onClick={() => sortElement("name")}
            >
              {t("instances.subscription.name")}
              {orderBy.split("_")[0] === "name" ? (
                orderBy === "name_ASC" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )
              ) : (
                <ImportExportIcon />
              )}
            </DivCenter>
          ),
          accessor: "name",
          sortable: false,
          Filter: () => (
            <TextField
              defaultValue={filterOptions.name}
              variant="outlined"
              style={{ width: "100%" }}
              onChange={async (event) => {
                const value = event.target.value;
                await delay(2000);
                filterAction({ ...filterOptions, name: value ? value : "" });
              }}
            />
          ),
        },
        {
          id: "instanceStatus",
          Header: t("instances.subscription.status"),
          accessor: (d) => {
            return t(`instances.status.${d.instanceStatus.toUpperCase()}`);
          },
          sortable: false,
          Filter: () => (
            <Select
              style={{ width: "100%" }}
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                filterAction({ ...filterOptions, status: e.target.value });
              }}
            >
              <MenuItem
                value={APP_STATUS["ALL"]}
                key={APP_STATUS["ALL"]}
              >
                {t(`instances.status.ALL`)}
              </MenuItem>

              <MenuItem
                value={APP_STATUS["ACTIVE"]}
                key={APP_STATUS["ACTIVE"]}
              >
                {t(`instances.status.ACTIVE`)}
              </MenuItem>

              <MenuItem
                value={APP_STATUS["LOSE"]}
                key={APP_STATUS["LOSE"]}
              >
                {t(`instances.status.LOSE`)}
              </MenuItem>
            </Select>
          ),
        },
        {
          id: "updateInstance",
          Header: (
            <DivCenter
              style={styles.pointer}
              onClick={() => sortElement("updateInstance")}
            >
              {t("instances.subscription.updateDate")}
              {orderBy.split("_")[0] === "updateInstance" ? (
                orderBy === "updateInstance_ASC" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )
              ) : (
                <ImportExportIcon />
              )}
            </DivCenter>
          ),
          accessor: (d) => {
            if (d.updateInstance) {
              return Moment(d.updateInstance).local().format("DD-MM-YYYY");
            } else {
              return t(`instances.forms.fields.dateTestEnd.noDate`);
            }
          },
          filterable: false,
          sortable: false,
        },
        {
          id: "created",
          Header: (
            <DivCenter
              style={styles.pointer}
              onClick={() => sortElement("created")}
            >
              {t("instances.subscription.created")}
              {orderBy.split("_")[0] === "created" ? (
                orderBy === "created_ASC" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )
              ) : (
                <ImportExportIcon />
              )}
            </DivCenter>
          ),
          Filter: () => (
            <TextField
              defaultValue={filterOptions.name}
              variant="outlined"
              style={{ width: "100%" }}
              onChange={async (event) => {
                const value = event.target.value;
                await delay(2000);
                filterAction({ ...filterOptions, name: value ? value : "" });
              }}
            />
          ),
          accessor: (d) => {
            return Moment(d.created).local().format("DD-MM-YYYY");
          },
          filterable: false,
          sortable: false,
        },
        {
          id: "nameContact",
          Header: t("instances.subscription.contact"),
          accessor: (d) => {
            return d.contact.email;
          },
          filterable: false,
          sortable: false,
        },
        {
          header: "",
          id: "click-me-button",
          Cell: ({ row }) => (
            <div className="actions-right">
              {!row._original.archived ? plans(row) : ''}
              {!row._original.archived ? toDoList(row) : ''}
              {!row._original.archived ? edit(row) : ''}
              {!row._original.archived ? invitation(row) : ''}
              {viewDashboard(row)}
              {archived(row)}
            </div>
          ),
          filterable: false,
          sortable: false,
        },
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

const styles = {
  pointer: {
    cursor: 'pointer'
  }
}

SubscriptionsTable.prototype = {
  data: PropTypes.object
};

export default SubscriptionsTable;
