import React from 'react'
import * as yup from "yup";
import { Form, withFormik, Field } from "formik";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import Button from "components/CustomButtons/Button.jsx";
import { hasError } from "../../utils/forms/inputs";
import ShapeMaps from './ShapeMaps';
import GeofencesTable from '../../tables/Geofences/GeofencesTable';


const geofenceSchema = yup.object().shape({
    name: yup.string().required()
  });
 
const GeofencesForm = (props) => {
    const {
      errors,
      touched,
      submitForm,
      geofenceGroup,
      archived,
    } = props;
    const { t } = useTranslation("geofences");

    return (
      <Form>
        <GridContainer>
          <GridItem xs={8}>
            <Field
              name="name"
              render={({ field }) => {
                return (
                  <CustomInput
                    desable
                    labelText={t("geofences.forms.fields.groupName.label")}
                    id="name"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      ...field
                    }}
                    success={!hasError(errors, "name") && touched.name}
                    error={hasError(errors, "name")}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={4}>
            <Button size="md" color="info" onClick={()=> submitForm()}>
              {t("geofences.save")}
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={6}>
                <ShapeMaps geofences={geofenceGroup.geofences}  />
              </GridItem>
              <GridItem xs={6}>
                <GeofencesTable geofences={geofenceGroup.geofences} archived={archived}/>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
    );
}

const styles = {
  right: {
    float: "right",
  },
};

const GeofenceGroupFormFK = withFormik({
  validationSchema: geofenceSchema,
  mapPropsToValues: (props) => {
    const { geofenceGroup } = props;
    const { name } = geofenceGroup;
    return {
      name: name ? name : "",
    };
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

})(GeofencesForm);

export default GeofenceGroupFormFK;