import React, { useEffect } from "react";
import * as yup from "yup";
import { Form, withFormik, Field } from "formik";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from "react-i18next";
import { hasError } from "../../utils/forms/inputs";
import Datetime from "react-datetime";
import { InputLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const newTaskSchema = yup.object().shape({
  title: yup.string().required(),
  app: yup.string().required(),
  description: yup.string(),
  userId: yup.string().required(),
  estimatedDate: yup.string(),
});

const NewTaskForm = (props) => {
  const {
    errors,
    touched,
    setFieldValue,
    submitForm,
    classes,
    setOpenModal,
    userId,
    handleChange
  } = props;
  const { t } = useTranslation("todolist");

  useEffect(() => {
    if (userId) {
      setFieldValue('userId', userId);      
    }
  }, userId);
  

  return (
    <Form>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <Field
              name="title"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText={t("todolist.forms.fields.title.label")}
                    id="title"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      ...field,
                    }}
                    success={!hasError(errors, "title") && touched.title}
                    error={hasError(errors, "title")}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={6}>
            <InputLabel className={classes.label}>
              {t("todolist.forms.fields.estimatedDate.label")}
            </InputLabel>
            <Datetime
              name={"estimatedDate"}
              timeFormat={false}
              onChange={(e) => {
                let date = e;
                if (e === "") {
                  setFieldValue("estimatedDate", date);
                } else {
                  date = e._d;
                  setFieldValue("estimatedDate", date);
                }
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <InputLabel className={classes.label}>
              {t("todolist.forms.fields.description.label")}
            </InputLabel>
            <Field
              style={styles.textArea}
              name="description"
              component="textarea"
              rows={20}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenModal(false)} color="primary">
          {t("todolist.cancel")}
        </Button>
        <Button
          onClick={() => {
            submitForm();
          }}
          color="primary"
          autoFocus
        >
          {t("todolist.save")}
        </Button>
      </DialogActions>
    </Form>
  );
};

const styles = {
  textArea: {
    width: '100%',
    marginTop: '7%'
  }
}

const NewTaskFormFK = withFormik({
  validationSchema: newTaskSchema,
  mapPropsToValues: ({ app, userId }) => {
    return {
      title: "",
      description: "",
      estimatedDate: "",
      app: app,
      userId: userId,
    };
  },
  handleSubmit: (values, {props, resetForm}) => {
    resetForm({ 
      title: "",
      description: "",
      estimatedDate: "",
      app: props.app,
      userId: props.userId,
     })
    props.submitSuccess(values);
    props.setOpenModal(false);
  }
})(withStyles(indexesPageStyle)(NewTaskForm));

export default NewTaskFormFK;
