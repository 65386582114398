import React, {useState, useEffect} from 'react';
import ReactTable from "react-table";
import Moment from 'moment';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css' 
import { useTranslation } from "react-i18next";
import DivCenter from '../../components/styled-components/DivCenter';

const ActivityIndexesTable = (props) => {
  const { t } = useTranslation('indexes');
  const [info, setInfo] = useState([]);

  async function getData() {
    const { data } = await props
    setInfo(data);
  }

  useEffect(() => {
    getData();
  });

  return (
    <ReactTable
      data={info}
      filterable
      columns={[
        {
          Header: <DivCenter>{t("indexes.nameApp")}</DivCenter>,
          accessor: "name",
          filterable: false,
          sortable: false,
          width: 200,
          fixed: "left"
        },
        {
          id: "instanceType",
          Header: <DivCenter>{t("indexes.type")}</DivCenter>,
          accessor: d => {
            return t(`indexes.${d.instanceType.toUpperCase()}`);
          },
          filterable: false,
          sortable: false,
          width: 200,
          fixed: "left"
        },
        {
          id: "instanceStatus",
          Header: <DivCenter>{t("indexes.status")}</DivCenter>,
          accessor: d => {
            return t(`indexes.${d.instanceStatus.toUpperCase()}`);
          },
          filterable: false,
          sortable: false,
          width: 150,
          fixed: "left"
        },
        {
          id: "created",
          Header: <DivCenter>{t("indexes.year")}</DivCenter>,
          accessor: d => {
            return Moment(d.created)
              .local()
              .format("YYYY");
          },
          filterable: false,
          sortable: false,
          width: 100,
          fixed: "left"
        },
        /* {
          Header: <DivCenter># {t("indexes.activeVisit")}</DivCenter>,
          accessor: "numActiveVisits",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        },
        {
          Header: `# ${t('indexes.closeVisit')}`,
          accessor: "numClosedVisits",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        }, */
        {
          Header: <DivCenter># {t("indexes.users")} {t("indexes.mobile")}</DivCenter>,
          accessor: "numUsersMobile",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        },
        /* {
          Header: `# ${t('indexes.formSend')}`,
          accessor: "numQuestionEngineSend",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        },
        {
          Header: `# ${t('indexes.reportDownload')}`,
          accessor: "numReportsDownloadUser",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        },
        {
          Header: `# ${t('indexes.reportSend')}`,
          accessor: "numReportautoSend",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        }, */
        {
          Header: <DivCenter># {t("indexes.segments")}</DivCenter>,
          accessor: "numSegments",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        },
        {
          Header: <DivCenter># {t("indexes.admins")}</DivCenter>,
          accessor: "numAdmins",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        },
        {
          Header: <DivCenter># {t("indexes.screens")}</DivCenter>,
          accessor: "numScreens",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        },
        {
          Header: <DivCenter># {t("indexes.modules")}</DivCenter>,
          accessor: "numModules",
          filterable: false,
          sortable: false,
          width: 150,
          style: { textAlign: "center" }
        }
        /* {
          Header: "# Question Engine Not Visit",
          accessor: "QuestionEngineNotVisit",
          filterable: false,
          minWidth: 150,
          style: { textAlign: "center" }
        },
        {
          Header: "# Report Visits",
          accessor: "numReportVisits",
          filterable: false,
          minWidth: 150,
          style: { textAlign: "center" }
        },
        {
          Header: "# Report Question Engine",
          accessor: "numReportQuestionEngine",
          filterable: false,
          minWidth: 150,
          style: { textAlign: "center" }
        } */
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped"
    />
  );

};

ActivityIndexesTable.propTypes = {
};

export default ActivityIndexesTable;