const indexesPageStyle = {
    cardIconTitle: {
      color: "black",
      marginTop: "15px",
      marginBottom: "0px"
    },
    btnPreview: {
      marginRight: "5px",
      float: "left"
    },
    btnNext: {
      marginRight: "5px",
      float: "right"
    },
    Field: {
      width: "100% !important",
      lineHeight: "1.7 !important"
    },
    progressCnt:{
      width: '100px !important',
      margin: 'auto'
    },
    progress: {
      margin: '16px'
    },
    paginationPage: {
        marginTop: '30px',
        textAlign: 'center',
        lineHeight: '3rem',
    }, 
    label: {
      cursor: "pointer",
      display: "inline-flex",
      fontSize: "0.8rem"
    },
    select: {
      lineHeight: '1.6875em'
    },
    formControl : {
      paddingTop: '27px'
    },
    missingOppositeContent: {
      "&:before": {
        display: "none"
      }
    }
};

export default indexesPageStyle;