import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useTranslation } from "react-i18next";
import { CircularProgress } from '@material-ui/core';
import UsersTable from "../../tables/Users/UsersTable";
import TablePagination from "../../paginations/table/SimpleTaablePagination";
import Position from "components/Position";
import Button from "components/Button";
import history from "../../utils/history";
import ArchivedUserModal from '../../modals/ArchivedUserModal'
import ResetPasswordModal from "../../modals/ResetPasswordModal";

const UsersView = (props) => {
    const { t } = useTranslation("users");
    const { users, loading, paginationAction, filter, archivedUser, resetPassword } = props;
    const [openModal, setOpenModal] = useState(false);
    const [openModalPwd, setOpenModalPwd] = useState(false);
    const [user, setUser] = useState(null);

    return (
      <GridContainer justify="center">
        <GridItem xs={8}>
          <Position rigth>
            <Button
              color={"primary"}
              onClick={() => {
                history.push("/users/new");
              }}
            >
              {t("users.newUser")}
            </Button>
          </Position>
        </GridItem>
        <GridItem xs={8}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <GroupAddIcon />
              </CardIcon>
              <h4>{t("users.title")}</h4>
            </CardHeader>
            <CardBody>
              <TablePagination
                data={users && users.usersBo ? users.usersBo : []}
                paginationAction={paginationAction}
                filter={filter}
              />
              {loading ? (
                <div style={styles.progressCnt}>
                  <CircularProgress style={styles.progress} />
                </div>
              ) : (
                <UsersTable
                  users={users && users.usersBo ? users.usersBo : []}
                  setUser={setUser}
                  setOpenModal={setOpenModal}
                  setOpenModalPwd={setOpenModalPwd}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <ArchivedUserModal
          user={user}
          openModal={openModal}
          setOpenModal={() => {
            setOpenModal(false);
          }}
          archivedUser={archivedUser}
        />

        <ResetPasswordModal
          user={user}
          openModal={openModalPwd}
          setOpenModal={() => {
            setOpenModalPwd(false);
          }}
          resetPassword={resetPassword}
        />
      </GridContainer>
    );
};  
const styles = {
    progressCnt: {
      width: "100px !important",
      margin: "auto",
    },
    progress: {
      margin: "16px",
    },
  };
export default UsersView;
