import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
  Rectangle,
  Polygon,
} from "react-google-maps";
import { compose, withProps, lifecycle } from "recompose";
import _ from "lodash";

const google = window.google;
const ShapeMaps = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDe9IxqJ9JxwmqtVVeKpJozUpNXytWLgLw&v=3&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};
      const { geofences } = this.props;

      this.setState({
        bounds: null,
        center: {
          lat: 19.431596,
          lng: -99.133117,
        },
        zoom: 15,
        geofences: geofences,
        onMapMounted: (ref) => {
          refs.map = ref;

          let centerOfShapes = geofences.map(geofence => {
            const shape = geofence.shape;
            if(shape) {
                const center = shape.center;
                if(center) {
                    return center;
                }
            }
            return undefined;
          });

          const bounds = new google.maps.LatLngBounds();
          if(centerOfShapes.length > 0) {
            centerOfShapes.map((center) => {
                if(center) {
                    bounds.extend(center);
                }
            });
            const center = bounds.getCenter();

            if (refs.map) {
              if(centerOfShapes.length > 1) {
                refs.map.fitBounds(bounds);
              }
              
              this.setState({
                ...this.state,
                bounds: bounds,
                center: {
                  lat: center.lat(),
                  lng: center.lng()
                },
                
              });
            }
            
        }

        },
        onBoundsChanged: _.debounce(
          () => {
            if (refs.map) {
              this.setState({
                ...this.state,
                bounds: refs.map.getBounds(),
                center: refs.map.getCenter(),
              });
            }
            
          },
          100,
          { maxWait: 500 }
        ),
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      ref={props.onMapMounted}
      zoom={props.zoom}
      onBoundsChanged={props.onBoundsChanged}
      center={props.center}
    >
      {props.geofences.map((geofence, index) => {
        switch (geofence.type) {
          case 1:
            return (
              <Circle
                key={index}
                editable={false}
                center={{
                  lat: geofence.shape.center.lat,
                  lng: geofence.shape.center.lng,
                }}
                radius={geofence.shape.radius}
                options={{
                  strokeColor: geofence.color,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: geofence.color,
                }}
              />
            );

          case 2:
            return (
              <Rectangle
                key={index}
                editable={false}
                bounds={{
                  north: geofence.shape.north,
                  south: geofence.shape.south,
                  east: geofence.shape.east,
                  west: geofence.shape.west,
                }}
                options={{
                  strokeColor: geofence.color,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: geofence.color,
                }}
              />
            );
          case 3:
            return (
              <Polygon
                key={index}
                paths={geofence.shape.coordinates}
                options={{
                  strokeColor: geofence.color,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: geofence.color,
                }}
              />
            );
        }
      })}
    </GoogleMap>
  );
});

export default ShapeMaps;
