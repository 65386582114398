import React from 'react';
import {Field, Form, withFormik} from "formik";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import withStyles from "@material-ui/core/styles/withStyles";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Datetime from "react-datetime";

//Custom components
import ButtonIcon from "../../components/styled/ButtonIcon";

// icons
import Search from "@material-ui/icons/Search";
import { InputLabel } from '@material-ui/core';
import { PropTypes } from 'prop-types';

/*
* Validation Schema for deFormSchema
* */
const filterOportunityFormSchema = yup.object().shape({
  name: yup.string(),
  dateCreateIn: yup.string(),
  dateCreateEnd: yup.string()
});

const FilterOportunityForm = (props) => {
    // Formik bag
    const {
      setFieldValue,
      submitSuccess,
      classes,
      values
    } = props;
    const { t } = useTranslation("instances");
    return (
      <Form>
        <GridContainer>
          <GridItem xs={3}>
            <InputLabel className={classes.label}>
              Fecha de Renovación inicio
            </InputLabel>
            <Datetime
              name={"dateUpdateIn"}
              timeFormat={false}
              onChange={e => {
                let date = e;
                if (e === "") {
                  setFieldValue("dateUpdateIn", e);
                } else {
                  setFieldValue("dateUpdateIn", e._d);
                }
                submitSuccess({...values, dateUpdateIn: date});
              }}
            />
          </GridItem>

          <GridItem xs={3}>
            <InputLabel className={classes.label}>
              Fecha de Renovación Final
            </InputLabel>
            <Datetime
              name={"dateUpdateEnd"}
              timeFormat={false}
              onChange={e => {
                let date = e;
                if (e === "") {
                  setFieldValue("dateUpdateEnd", e);
                } else {
                  setFieldValue("dateUpdateEnd", e._d);
                }
                submitSuccess({...values, dateUpdateEnd: date});
              }}
            />
          </GridItem>

          <GridItem xs={3}>
            <InputLabel className={classes.label}>
              Fecha de creación inicio
            </InputLabel>
            <Datetime
              name={"dateCreateIn"}
              timeFormat={false}
              onChange={e => {
                let date = e;
                if (e === "") {
                  setFieldValue("dateCreateIn", e);
                } else {
                  setFieldValue("dateCreateIn", e._d);
                }
                submitSuccess({...values, dateCreateIn: date});
              }}
            />
          </GridItem>

          <GridItem xs={3}>
            <InputLabel className={classes.label}>
              Fecha de creación Final
            </InputLabel>
            <Datetime
              name={"dateCreateEnd"}
              timeFormat={false}
              onChange={e => {
                let date = e;
                if (e === "") {
                  setFieldValue("dateCreateEnd", e);
                } else {
                  setFieldValue("dateCreateEnd", e._d);
                }
                submitSuccess({...values, dateCreateEnd: date});
              }}
            />
          </GridItem>
        </GridContainer>
      </Form>
    );
}

FilterOportunityForm.prototype = {
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

const FilterOportunityFormWF = withFormik({
  validationSchema: filterOportunityFormSchema,
  mapPropsToValues: props => ({
    name: "",
    status: "",
    dateUpdateIn: "",
    dateUpdateEnd: "",
    dateCreateIn: "",
    dateCreateEnd:""
  }),
  handleSubmit: (values, {props}) => {
    props.submitSuccess(values);
  },
})(withStyles(indexesPageStyle)(FilterOportunityForm));

export default FilterOportunityFormWF;
