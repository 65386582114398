import React from "react";
import { Field } from "formik";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { InputLabel } from '@material-ui/core';
import Datetime from "react-datetime";
import { useTranslation } from 'react-i18next';

const DateTestIntance = props => {
  const { setFieldValue } = props;
  const { t, } = useTranslation('instances');

  return (
    <GridContainer>
      <GridItem xs={6}>
        <InputLabel>
          {t("instances.forms.fields.dateTestIn.label")}
        </InputLabel>
        <Field
          name="opportunity.dateIn"
          render={({ field }) => {
            return (
              <Datetime
                name={"dateIn"}
                timeFormat={false}
                defaultValue={field.value}
                onChange={e => {
                  if (e === "") {
                    setFieldValue("opportunity.dateIn", e);
                  } else {
                    setFieldValue("opportunity.dateIn", e._d);
                  }
                }}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={6}>
        <InputLabel>
          {t("instances.forms.fields.dateTestEnd.label")}
        </InputLabel>
        <Field
          name="opportunity.dateEnd"
          render={({ field }) => {
            return (
              <Datetime
                name={"dateEnd"}
                timeFormat={false}
                defaultValue={field.value}
                onChange={e => {
                  if (e === "") {
                    setFieldValue("opportunity.dateEnd", e);
                  } else {
                    setFieldValue("opportunity.dateEnd", e._d);
                  }
                }}
              />
            );
          }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default DateTestIntance;
