export const openNotification = async (store, place, notification) => {
    await store.setState({
      notifications: {
        ...store.notifications,
        [place]: {
          ...notification,
          open: true
        }
      }
    })
  };
  
  export const closeNotification = async (store, place) => {
    await store.setState({
      notifications: {
        ...store.notifications,
        [place]: {
          ...store.state.notifications[place],
          open: false
        }
      }
    })
  };