import React, { useState, useEffect } from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { compose } from "recompose";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import CircularProgress from '@material-ui/core/CircularProgress';

import TablePagination from '../../paginations/table/TablePagination';
import SubscriptionsTable from '../../tables/Instances/SubscriptionsTable';
import FilterSubscriptionFormWF from '../../forms/Instances/FilterSubscriptionForm';
import ArchivedInstanceConfirmModal from "../../modals/ArchivedInstanceConfirmModal";
import Button from "components/Button";

const SubscriptionsView = (props) => {
    const {
      data,
      loading,
      classes,
      paginationAction,
      paginationOptions,
      filterAction,
      archivedInstance,
      filterOptions,
    } = props;
    const { t } = useTranslation('instances');
    const [instances, setInstances] = useState([]);
    const [paginationStatus, setPaginationStatus] = useState({
        hasPreviousPage: false,
        hasNextPage: true
    });

    const [openModal, setOpenModal] = useState(false);
    const [instance, setInstance] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    async function getData() {
        if (data && data?.instancesConnection) {
            const { instancesConnection } = await data;
            const { totalCount, edges, pageInfo } = await instancesConnection;
            setInstances(edges);
            setPaginationStatus(pageInfo);
            setTotalCount(totalCount);
        }
    }
    
    useEffect(()=>{
       getData();
    });
    
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="info">
                  <Apps />
                </CardIcon>
                <div style={styles.right}>
                  <Button
                    size="sm"
                    color="info"
                    onClick={() => {
                      filterAction({
                        ...filterOptions,
                        archived: !filterOptions.archived,
                      });

                      paginationAction({
                        ...paginationOptions,
                        numPage: 1,
                        first: 15, 
                        skip: 0,
                      });
                    }}
                  >
                    {!filterOptions.archived
                      ? t("instances.subscription.viewArchivedSubscriptions")
                      : t("instances.subscription.viewSubscriptions")}
                  </Button>
                </div>
                <h4>{t("instances.subscription.title")}</h4>
              </CardHeader>
              <CardBody>
                <FilterSubscriptionFormWF submitSuccess={filterAction} />
                <TablePagination
                  classes={classes}
                  paginationAction={paginationAction}
                  paginationOptions={paginationOptions}
                  paginationStatus={paginationStatus}
                  totalCount={totalCount}
                />
                {loading ? (
                  <div className={classes.progressCnt}>
                    <CircularProgress className={classes.progress} />
                  </div>
                ) : (
                  <SubscriptionsTable
                    data={instances}
                    setInstance={setInstance}
                    setOpenModal={setOpenModal}
                    paginationAction={paginationAction}
                    paginationOptions={paginationOptions}
                    filterAction={filterAction}
                    filterOptions={filterOptions}
                  />
                )}

                <TablePagination
                  classes={classes}
                  paginationAction={paginationAction}
                  paginationOptions={paginationOptions}
                  paginationStatus={paginationStatus}
                  totalCount={totalCount}
                />
              </CardBody>
            </Card>
          </GridItem>
          <ArchivedInstanceConfirmModal
            instance={instance}
            openModal={openModal}
            setOpenModal={() => {
              setOpenModal(false);
            }}
            archivedInstance={archivedInstance}
          />
        </GridContainer>
      </React.Fragment>
    );
}

const styles = {
  right: {
    float: "right"
  }
};

export default compose(
    withStyles(indexesPageStyle),
)(SubscriptionsView);
