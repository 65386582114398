import React, { useEffect, useState } from "react";
import EmailMessageView from "../../views/Messages/EmailMessageView";
import { useQuery, useMutation } from "react-apollo-hooks";
import { APPS_FEED, USERS_FEED } from "./gql/Queries";
import useGlobal from "../../store";
import history from "../../utils/history";
import { SEND_EMAIL_MUTATION } from "./gql/Mutation";
import auth from '../../utils/auth';


const EmailMessages = () => {
  const [{session}, globalActions] = useGlobal();
  const [apps, setApps] = useState({
    where: {
      name: "",
      status: "",
    }
  });

  const [users, setUsers] = useState({
    where: {}
  });

  useEffect(() => {
    const { user } = session;
    if (user) {
      if (user.role.master) {
        setApps({ where:{...apps.where} });
      }else{
        setApps({ where : {...apps.where, franchise: user.franchise} });
      }
    }
  }, [session]);

  const { data: dataApps, loading: loadingApps } = useQuery(APPS_FEED, {
    variables: apps,
    fetchPolicy: "network-only"
  });

  const { data: dataUsers, loading: loadingUsers } = useQuery(USERS_FEED, {
    variables: users,
    fetchPolicy: "network-only"
  });

  async function setUserFilter(app) {
    setUsers({ where: { app: app } });
  }

  const sendEmail = useMutation(SEND_EMAIL_MUTATION);

  if (
    session.user &&
    !auth.hasPermission("messageEmailCreate", session.user.role.permissions)
  ) {
    globalActions.notification.openNotification("tr", {
      message: "No tienes permisos",
      color: "danger",
    });

    history.push('dashboard');
  } 

  return (
    <EmailMessageView
      apps={{ loading: loadingApps, data: dataApps }}
      users={{ loadingUsers: loadingUsers, dataUsers: dataUsers }}
      setUserFilter={setUserFilter}
      submitSuccess={async (values) => {
        let variables = {
          data: {
            ...values,
            franchise: session.user.franchise
          }
        };
        
        const resultMutation = await sendEmail({ variables: variables });
        const { loading, error } = resultMutation;

        if (!loading) {
          if (!error) {
            // Add success notification
            globalActions.notification.openNotification("tr", {
              message: "Mensaje enviado",
              color: "success"
            });
            history.push(`/message/emailList`);
          } else {
            globalActions.notification.openNotification("tr", {
              message: "Error al enviar mensaje",
              color: "danger"
            });
          }
        }
      }}
    />
  );
};

export default EmailMessages;
