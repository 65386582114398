import gql from "graphql-tag";

export const GEOFENCE_GROUP = gql`
    query GeofencesGroup($where: GeofencesGroupInput, $orderBy: GeofenceReportOrderByInput) {
    geofencesGroup(where: $where, orderBy: $orderBy) {
        _id,
        name,
        geofences{
            _id
            name
            color
            type
            zoom
            shape
            created
        }
        archived
        created
    }
    }
`;

export const GEOFENCE_GROUP_BY_ID = gql`
  query GeofenceGroup($where: GeofencesGroupWhereUniqueInput) {
    getGeofenceGroupbyId(where: $where) {
      _id
      name
      geofences {
        _id
        paymentGeofenceGroup
        name
        color
        type
        zoom
        shape
        created
      }
      archived
      created
    }
  }
`;

export const GEOFENCE_RECORD = gql`
  query GEOFENCE_RECORD($where: GeofencesRecordWhereInput) {
    getPaymentGeofenceRecord(where: $where) {
      _id
      app
      appRef {
        name
      }
      paymentGeofenceGroup {
        _id
        name
      }
      archived
      activated
      created
      franchise
      masterFranchise
    }
  }
`;