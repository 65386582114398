import gql from "graphql-tag";

export const TUTORIALS_CATEGORIES_FEED = gql`
  query TUTORIALS_CATEGORIES_FEED($first: Int, $skip: Int) {
    getAllTutorialInfoCategory(first: $first, skip: $skip){
        _id
        name
        position
        created
    }
  }
`;

export const TUTORIALS_INFO_FEED = gql`
  query TUTORIALS_INFO_FEED($where: TutorialInfoInput!, $first: Int, $skip: Int) {
    getAllTutorialInfo(where: $where, first: $first, skip: $skip){
      _id
      name
      description
      url
      type
      category{
        _id
        name
      }
      created
      }
  }
`;