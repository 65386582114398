import React from 'react';
import ReactTable from "react-table";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import DivCenter from '../../components/styled-components/DivCenter';
import ChatIcon from '@material-ui/icons/Chat';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { Tooltip } from "@material-ui/core";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const ToDoListTable = ({
  activities,
  setOpenDrawer,
  setActivity,
  updateActivity,
  setOpenModalEmail,
  paginationAction,
  paginationOptions,
}) => {
  const { t } = useTranslation("todolist");

  let { orderBy } = paginationOptions;

  const sortElement = (key) => {
    if (orderBy) {
      orderBy = orderBy === `${key}_ASC` ? `${key}_DESC` : `${key}_ASC`;
    } else {
      orderBy = `${key}_ASC`;
    }

    paginationAction({ ...paginationOptions, orderBy: orderBy });
  };

  return (
    <ReactTable
      data={activities}
      filterable
      columns={[
        {
          id: "status",
          Header: (
            <DivCenter
              style={styles.pointer}
              onClick={() => sortElement("status")}
            >
              {t("todolist.list.status")}
              {orderBy.split("_")[0] === "status" ? (
                orderBy === "status_ASC" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )
              ) : (
                <ImportExportIcon />
              )}
            </DivCenter>
          ),
          accessor: (d) => {
            return d.status === "Pending" ? (
              <CheckCircleIcon
                style={styles.pointer}
                onClick={() => {
                  setOpenModalEmail(true);
                  updateActivity({ ...d, status: "Done" }, d._id);
                }}
              />
            ) : (
              <CheckCircleIcon
                style={{ ...styles.pointer, ...styles.success }}
                onClick={() => {
                  updateActivity({ ...d, status: "Pending" }, d._id);
                }}
              />
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          width: 100,
        },
        {
          id: "task",
          Header: (
            <DivCenter style={styles.pointer}>
              {t("todolist.list.task")}
            </DivCenter>
          ),
          accessor: (d) => {
            return (
              <div>
                <p style={styles.title}>{d.title}</p>
                <p style={styles.subTitle}>
                  {d.description ? d.description : ""}
                </p>
              </div>
            );
          },
          sortable: false,
          filterable: false,
        },
        {
          id: "user",
          Header: (
            <DivCenter style={styles.pointer}>
              {t("todolist.list.user")}
            </DivCenter>
          ),
          accessor: (d) => {
            if(d.user) { 
              return `${d.user.firstName} ${d.user.lastName}`;
            }else{
              return '';
            }
            
          },
          sortable: false,
          filterable: false,
          style: { textAlign: "center" },
        },
        {
          id: "estimatedDate",
          Header: (
            <DivCenter
              style={styles.pointer}
              onClick={() => sortElement("estimatedDate")}
            >
              {t("todolist.list.estimatedDate")}
              {orderBy.split("_")[0] === "estimatedDate" ? (
                orderBy === "estimatedDate_ASC" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )
              ) : (
                <ImportExportIcon />
              )}
            </DivCenter>
          ),
          accessor: (d) => {
            const date = d?.estimatedDate;
            return date ? Moment(date).local().format("DD-MM-YYYY") : "";
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
        },
        {
          id: "created",
          Header: (
            <DivCenter
              style={styles.pointer}
              onClick={() => sortElement("created")}
            >
              {t("todolist.list.created")}
              {orderBy.split("_")[0] === "created" ? (
                orderBy === "created_ASC" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )
              ) : (
                <ImportExportIcon />
              )}
            </DivCenter>
          ),
          accessor: (d) => {
            return Moment(d.created).local().format("DD-MM-YYYY");
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
        },
        {
          id: "comments",
          Header: <DivCenter>{t("todolist.list.comments")}</DivCenter>,
          accessor: (d) => {
            const commenstCount = d.comments.length;
            return commenstCount ? (
              <div
                style={styles.pointer}
                onClick={() => {
                  setActivity(d);
                  setOpenDrawer(true);
                }}
              >
                <ChatIcon />
                {commenstCount}
              </div>
            ) : (
              ""
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
        },
        {
          header: "",
          id: "click-me-button",
          Cell: ({ row }) => (
            <div className="actions-right">
              <Tooltip title={t("todolist.tooltip.edit")}>
                <Button
                  justIcon
                  round
                  simple
                  color="info"
                  onClick={() => {
                    setActivity(row._original);
                    setOpenDrawer(true);
                  }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("todolist.tooltip.archived")}>
                <Button
                  justIcon
                  round
                  simple
                  color="danger"
                  onClick={() => {
                    updateActivity(
                      { ...row._original, archived: true },
                      row._original._id
                    );
                  }}
                >
                  <DeleteForever />
                </Button>
              </Tooltip>
            </div>
          ),
          filterable: false,
          sortable: false,
        },
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

const styles = {
    pointer: {
      cursor: 'pointer'
    },
    title: {
        fontWeight: 'bold'
    },
    subTitle: {
        fontStyle: 'italic'
    },
    success: {
      color: 'green'
    },
    successTask: {
      textDecoration: 'line-through',
      fontStyle: 'italic' 
    }
}

export default ToDoListTable;
