import gql from "graphql-tag";

export const ROLES_BO = gql`
  query ROLES_BO($where: RoleBoInput, $skip: Int, $first: Int) {
    rolesBo(where: $where, skip: $skip, first: $first) {
      _id
      name
      master
      permissions
      archived
    }
  }
`;

export const USERS_BO = gql`
  query USERS_BO($where: UserBoWhereInput, $skip: Int, $first: Int) {
    usersBo(where: $where, skip: $skip, first: $first) {
      _id
      firstName
      lastName
      email
      role {
        _id
        name
        permissions
      }
      franchise
      franchiseRef{
        name
      }
      archived
      profileImage
      created
    }
  }
`;

export const USER_BO = gql`
  query USER_BO($where: UserWhereUniqueInput) {
    userBoById(where: $where) {
      _id
      firstName
      lastName
      role {
        _id
        name
        permissions
      }
      archived
      profileImage
      created
      resetPassword
    }
  }
`;