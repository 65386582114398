import React from 'react'
import { useTranslation } from 'react-i18next';
import ReactTable from "react-table";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import DivCenter from '../../components/styled-components/DivCenter';
import auth from '../../utils/auth';
import useGlobal from "../../store";

const PartnersTable = (props) => {
    const { partners, setPartner, setOpenModal } = props;
    const { t } = useTranslation('partners')
    const [{session}, ] = useGlobal();

    const edit = (row) => {
      if (
        session.user &&
        auth.hasPermission("partnerUpdate", session.user.role.permissions)
      ) {
        return (
          <Tooltip title={t("partners.edit")}>
            <Link
              to={{
                pathname: `/partners/edit/${row._original._id}`,
                state: {
                  partner: row._original,
                },
              }}
            >
              <Button justIcon round simple color="info">
                <EditIcon />
              </Button>
            </Link>
          </Tooltip>
        );
      }
    };
  
    const archived = (row) => {
      if (
        session.user &&
        auth.hasPermission("partnerDelete", session.user.role.permissions)
      ) {
        return (
          <Tooltip title={t("partners.archived")}>
            <Button
              justIcon
              round
              simple
              color="danger"
              onClick={() => {
                setPartner(row._original);
                setOpenModal(true);
              }}
            >
              <DeleteForever />
            </Button>
          </Tooltip>
        );
      }
    };

    return (
      <ReactTable
        data={partners}
        columns={[
          {
            Header: <DivCenter>{t("partners.list.name")}</DivCenter>,
            accessor: "name",
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("partners.list.city")}</DivCenter>,
            id: "city",
            accessor: (d) => {
                return d.city.cityName;
            },
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("partners.list.country")}</DivCenter>,
            id: "country",
            accessor: (d) => {
                return d.city.countryName;
            },
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter></DivCenter>,
            accessor: "actions",
            Cell: ({ row }) => (
                <div className="actions-right">
                  {edit(row)}
                  {archived(row)}
                </div>
              ),
            filterable: false,
            sortable: false,
          }
        ]}
        defaultPageSize={15}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
}

export default PartnersTable;
