import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Circle, Rectangle, Polygon } from "react-google-maps";
import { compose, withProps, lifecycle } from "recompose";
import _ from "lodash";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";

const google = window.google;

const GoogleMaps = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDe9IxqJ9JxwmqtVVeKpJozUpNXytWLgLw&v=3&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};
      const { setFieldValue, geofence } = this.props;

      this.setState({
        bounds: null,
        center: {
          lat: 19.431596,
          lng: -99.133117,
        },
        shapes: [],
        onMapMounted: (ref) => {
          refs.map = ref;

          if (!_.isEmpty(geofence.shape)) {
            let center;
            switch (geofence.type) {
              case 1:
                this.setState({
                  ...this.state,
                  center: {
                    lat: geofence.shape.center.lat,
                    lng: geofence.shape.center.lng,
                  }
                });
                break;
              case 2:
                const rectangle = new google.maps.Rectangle({
                  bounds: {
                    north: geofence.shape.north,
                    south: geofence.shape.south,
                    east: geofence.shape.east,
                    west: geofence.shape.west
                  },
                });

                this.setState({
                  ...this.state,
                  bounds: rectangle.getBounds(),
                  center: rectangle.getBounds().getCenter()
                });
                break;
              case 3:
                const bounds = new google.maps.LatLngBounds();
                let i;
      
                for (i = 0; i < geofence.shape.coordinates.length; i++) {
                  bounds.extend(geofence.shape.coordinates[i]);
                }
      
                center = bounds.getCenter();
                
                this.setState({
                  ...this.state,
                  center: {
                    lat: center.lat(),
                    lng: center.lng(),
                  },
                });
                break;
            }
          }
        },
        onBoundsChanged: _.debounce(
          () => {
            if (refs.map) {
              this.setState({
                ...this.state,
                bounds: refs.map.getBounds(),
                center: refs.map.getCenter(),
              });
            }
          },
          100,
          { maxWait: 500 }
        ),
        handleOverlayComplete: (e) => {    
            this.state.deleteShapes();    
            const shape = e.overlay;
            google.maps.event.addListener(shape, "click", () => {
              this.state.toggleSelection(shape);
            });
            this.state.toggleSelection(shape);
            this.state.shapes.push(shape);
            this.setState({
                ...this.state,
                shapes: [shape]
            })
            switch (e.type) {
                case 'circle':
                    const radius = shape.getRadius();
                    setFieldValue('type',1);
                    setFieldValue('shape',{
                        radius: radius,
                        center: {
                            lat: shape.center.lat(),
                            lng: shape.center.lng()
                        }
                    })
        
                  break;
                case 'rectangle':
                    
                    const bounds = shape.getBounds();
                    const center = bounds.getCenter();
                    const areaBounds = {
                        north: bounds.getNorthEast().lat(),
                        south: bounds.getSouthWest().lat(),
                        east: bounds.getNorthEast().lng(),
                        west: bounds.getSouthWest().lng()
                    };
                    setFieldValue('type',2);
                    setFieldValue('shape',{
                        center: {
                            lat: center.lat(),
                            lng: center.lng()
                        },
                        north: areaBounds.north,
                        south: areaBounds.south,
                        east: areaBounds.east,
                        west: areaBounds.west
                    });
        
                  break;
                case 'polygon':
                    const mapCenter = shape.map.center
        
                    let polygon = [];
        
                    shape.getPath().getArray().map(coor => {
                        polygon.push({
                            lat: coor.lat(),
                            lng: coor.lng()
                        })
                    });
                    setFieldValue('type',3);
                    setFieldValue('shape',{
                        center: {
                            lat: mapCenter.lat(),
                            lng: mapCenter.lng()
                        },
                        coordinates: polygon
                    })
                  break;
              }
        },
        toggleSelection: (shape) => {
            shape.setEditable(true);
        },
        deleteShapes: () => {
            this.state.shapes.forEach(shape => shape.setMap(null));
        },
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new google.maps.LatLngBounds();

          places.forEach((place) => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map((place) => ({
            position: place.geometry.location,
          }));

          const nextCenter = _.get(
            nextMarkers,
            "0.position",
            this.state.center
          );

          this.setState({
            ...this.state,
            center: nextCenter,
            markers: nextMarkers,
          });
        },
      });

      
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  
  const renderShape = () => {
    if (!_.isEmpty(props.geofence.shape)) {
      switch (props.geofence.type) {
        
        case 1:
          return (
            <Circle
              editable={false}
              center={{
                lat: props.geofence.shape.center.lat,
                lng: props.geofence.shape.center.lng,
              }}
              radius={props.geofence.shape.radius}
              options={{
                strokeColor: props.color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: props.color,
              }}
            />
          );
  
        case 2:
          return (
            <Rectangle
              editable={false}
              bounds={{
                north: props.geofence.shape.north,
                south: props.geofence.shape.south,
                east: props.geofence.shape.east,
                west: props.geofence.shape.west,
              }}
              options={{
                strokeColor: props.color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: props.color,
              }}
            />
          );
        case 3:
          return (
            <Polygon
              editable={false}
              paths={props.geofence.shape.coordinates}
              options={{
                strokeColor: props.color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: props.color,
              }}
            />
          );
      }
    }else{
      return null;
    }
    
  }

  return (
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={15}
      onBoundsChanged={props.onBoundsChanged}
      center={props.center}
      onClick={props.onMapClick}
    >
      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder={props.placeholder}
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            marginTop: `20px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox>
      <DrawingManager
        defaultDrawingMode={google.maps.drawing.OverlayType.CIRCLE}
        defaultOptions={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
                google.maps.drawing.OverlayType.CIRCLE,
                google.maps.drawing.OverlayType.POLYGON,
                google.maps.drawing.OverlayType.RECTANGLE,
              ],
          },
          circleOptions: {
            fillColor: props.color,
            fillOpacity: 0.3,
            strokeWeight: 1,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
          rectangleOptions: {
            fillColor: props.color,
            fillOpacity: 0.3,
            strokeWeight: 1,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
          polygonOptions: {
            fillColor: props.color,
            fillOpacity: 0.3,
            strokeWeight: 1,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        }}
        onOverlayComplete={props.handleOverlayComplete}
      />

      {renderShape()}
    </GoogleMap>
  );
});

export default GoogleMaps;
