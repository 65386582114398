import React from 'react'
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx"
import { CircularProgress } from '@material-ui/core';
import history from '../../utils/history';
import Button from "components/CustomButtons/Button.jsx";
import CategoriesTable from '../../tables/Tutorials/CategoriesTable';

const TutorialsCategoriesListView = ({ categories }) => {
    const { loading, data } = categories;
    return (
        <Card>
          <CardHeader>
              <div  style={styles.right}>
              <Button size="sm" color="info" onClick={() => history.push(`/tutorials/category/new`)}>Nueva Categoria</Button>
              </div>
          </CardHeader>
          <CardBody>
          
            {loading ? (
              <div style={styles.progressCnt}>
                <CircularProgress style={styles.progress} />
              </div>
            ) : (
              <CategoriesTable categories={data.getAllTutorialInfoCategory ? data.getAllTutorialInfoCategory : []} />
            )}
          </CardBody>
        </Card>
      );
  }

  const styles = {
    progress: {
      margin: "16px"
    },
    progressCnt: {
      width: "100px !important",
      marginLeft: "50%",
      marginRight: "50%"
    },
    right: {
      float: "right"
    }
  };

export default TutorialsCategoriesListView;
