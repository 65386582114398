import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from "react-i18next";
import TaskForm from '../../forms/ToDoList/TaskForm';
import NewCommentForm from '../../forms/ToDoList/NewCommentForm';

const DrawerTask = ({ activity, openDrawer, setOpenDrawer, newComment, updateActivity, userId }) => {
    const { t } = useTranslation("todolist");
    const toggleDrawer = (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setOpenDrawer(open);
    };

    return (
      <Drawer
        type="temporary"
        width="50%"
        anchor={"right"}
        open={openDrawer}
        onClose={toggleDrawer(false)}
      >
        <GridContainer>
          <GridItem xs={12}>
            <TaskForm
              activity={activity}
              setOpenDrawer={setOpenDrawer}
              updateActivity={updateActivity}
            />
          </GridItem>
          <GridItem xs={12}>
            <NewCommentForm
              userId={userId}
              activity={activity}
              newComment={newComment}
              setOpenDrawer={setOpenDrawer}
            />
          </GridItem>
        </GridContainer>
      </Drawer>
    );
}

export default DrawerTask;