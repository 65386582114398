import React from 'react'
import {useQuery ,useMutation } from 'react-apollo-hooks';
import NewIntanceView from '../../views/Instances/NewIntanceView';

import useGlobal from "../../store";
import {CREATE_INSTANCE_MUTATION} from "./gql/Mutations";
import {CITIES} from './gql/Queries';
// utils
import history from '../../utils/history';
import auth from '../../utils/auth';
import { APPS_FEATURES } from '../../config/constants';

const NewInstance = (props) => {
    const { match, location } = props;
    const [{session}, globalActions] = useGlobal();
    const createInstance = useMutation(CREATE_INSTANCE_MUTATION);
    const { data, } = useQuery(CITIES);

    if (
      session.user &&
      !auth.hasPermission("demoCreate", session.user.role.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: "No tienes permisos",
        color: "danger",
      });

      history.push('dashboard');
    } 

    return (
        <NewIntanceView
            match={match}
            location={location}
            cities={data}
            submitSuccess={async (values)=>{
                let image = values.icon;
                delete values.icon;

                //tmp created app features
                const appFeatures = APPS_FEATURES.map(appFeature => appFeature.name);

                let variables = {
                    data: {
                        ...values,
                        appFeatures: appFeatures
                    },
                    file: image[0]
                };

                
                const mutationResult = await createInstance({
                  variables: variables
                });

                const { loading, error } = mutationResult;

                if (!loading) {
                  if (!error) {
                    history.push("/instances/demos");
                    // Add success notification
                    globalActions.notification.openNotification(
                      "tr",
                      {
                        message: "Nueva Instancia Creada",
                        color: "success"
                      }
                    );
                  }else{
                    globalActions.notification.openNotification('tr', {
                        message: 'Error al crear la instancia',
                        color: 'danger'
                    });
                  }
                }
            }}
        />
    )
}

export default NewInstance;