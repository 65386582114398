import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { useTranslation } from "react-i18next";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import PlanToInstanceFormWF from '../../forms/Plans/PlanToInstanceForm';
import CircularProgress from "@material-ui/core/CircularProgress";

const AddPlanInstanceView = ({ plans, addPlanToInstance, instance, disabled }) => {
    const { t } = useTranslation("plans");
    const { dataPlans, loading } = plans;
    
    return (
      <GridContainer justify="center">
        <GridItem xs={7}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <CardMembershipIcon />
              </CardIcon>
              <h4>{instance?.name}</h4>
            </CardHeader>
            <CardBody>
              {loading ? (
                <div>
                  <CircularProgress id="loading-circle" />
                </div>
              ) : (
                <PlanToInstanceFormWF
                  disabled={disabled}
                  plans={dataPlans}
                  customPlanFeatures={instance?.customPlanFeatures ? instance.customPlanFeatures : []}
                  planId={instance?.planId}
                  addPlanToInstance={addPlanToInstance}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

export default AddPlanInstanceView;