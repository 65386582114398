import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

/* const {NODE_ENV} = window._env_; */

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    /* debug: NODE_ENV === 'development', */
    defaultNS: 'common',
    lng: 'es',
    fallbackLng: 'es',

    interpolation: {
      escapeValue: false
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      useSuspense: false,
      wait: true
    }
  });


export default i18n;
