import gql from "graphql-tag";

export const UPDATE_PLAN = gql`
  mutation updatePlan($data: PlanInput!, $where: PlanUniqueWhereInput!) {
    updatePlan(data: $data, where: $where) {
      _id
      name
      description
      features {
        _id
        name
        type
        value
      }
      created
    }
  }
`;

export const ADD_PLAN_TO_INSTANCE = gql`
  mutation setPlanToInstance($data: PlanToInstanceInput!, $where: InstanceWhereUniqueInput!) {
    setPlanToInstance(data: $data, where: $where) {
      name
      planId
      customPlanFeatures {
        _id
        name
        type
        value
      }
    }
  }
`;