import React from 'react';
import * as yup from "yup";
import { Form, withFormik, Field } from "formik";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { useTranslation } from "react-i18next";
import { hasError } from "../../utils/forms/inputs";
import Datetime from "react-datetime";
import { InputLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";
import Button from '@material-ui/core/Button';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Grid from '@material-ui/core/Grid';

const taskSchema = yup.object().shape({
    title: yup.string().required(),
    app: yup.string().required(),
    description: yup.string(),
    comments: yup.array(),
    userId: yup.string().required(),
    estimatedDate: yup.string(),
  });

const TaskForm = (props) => {
    const {
      errors,
      touched,
      setFieldValue,
      submitForm,
      classes,
      values,
      activity
    } = props;
    const { t } = useTranslation("todolist");
    return (
      <Form>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={2}>
              <PlaylistAddCheckIcon style={styles.icon} />
            </GridItem>
            <GridItem xs={8}>
              <h3>{t("todolist.list.task")}</h3>
            </GridItem>
            <GridItem xs={2}>
              <Button
                onClick={() => {
                  submitForm();
                }}
                color="primary"
                variant="contained"
                style={styles.btnNext}
              >
                {t("todolist.save")}
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>

        <Divider />
        <GridItem xs={12}>
          <Field
            name="title"
            render={({ field }) => {
              return (
                <CustomInput
                  labelText={t("todolist.forms.fields.title.label")}
                  id="title"
                  type={"text"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    disabled: true,
                    ...field,
                  }}
                  success={!hasError(errors, "title") && touched.title}
                  error={hasError(errors, "title")}
                />
              );
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <InputLabel className={classes.label}>
            {t("todolist.forms.fields.estimatedDate.label")}
          </InputLabel>
          <Field
            name="estimatedDate"
            render={({ field }) => {
              return (
                <Datetime
                  name={"estimatedDate"}
                  timeFormat={false}
                  defaultValue={field.value}
                  inputProps={{
                    disabled: activity.status !== "Done" ? false : true,
                  }}
                  onChange={(e) => {
                    if (e === "") {
                      setFieldValue("estimatedDate", e);
                    } else {
                      setFieldValue("estimatedDate", e._d);
                    }
                  }}
                />
              );
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <InputLabel className={classes.label}>
            {t("todolist.forms.fields.description.label")}
          </InputLabel>
          <Field
            disabled={true}
            style={styles.textArea}
            name="description"
            component="textarea"
            rows={10}
          />
        </GridItem>
        <GridItem xs={12}>
          <Typography component="p">{t('todolist.list.comments')}</Typography>
          <Timeline align="left">
            {values.comments.map((comment, index) => (
              <TimelineItem
                key={comment._id}
                className={classes.missingOppositeContent}
              >
                <TimelineSeparator>
                  <TimelineDot>
                    <AccountCircleIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent style={styles.timeLineContent}>
                  <Paper elevation={3} style={styles.paper}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography style={styles.textBold}>
                          {
                            `${comment.user ? comment.user.firstName : activity.user.firstName} 
                            ${comment.user ? comment.user.lastName : activity.user.lastName}
                          `}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary" style={styles.right}>
                          {moment(comment.updated).local().format("DD-MM-YY : hh:mm A")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography component="p" style={styles.breakWord}>
                        {comment.comment}
                      </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </GridItem>
      </Form>
    );
}

const styles = {
    timeLineContent: {
      width: '80px'
    },
    textArea: {
      width: '100%',
      marginTop: '3%' 
    },
    paper: {
        padding: '6px 16px',
        width: '100%',
    },
    breakWord: {
      wordWrap: "break-word"
    },
    icon: {
      fontSize: '40px',
      textAlign: 'center',
      marginLeft: '50%',
      marginRight: '50%',
      marginTop: '15%'
  },
  btnNext: {
    float: "right",
    margin: "20%"
  },
  textBold: {
    fontWeight: 'bold'
  },
  right: {
    float: "right",
  },

}


export default withFormik({
    validationSchema: taskSchema,
    mapPropsToValues: ({activity}) => {
        const {
          app,
          userId,
          title,
          description,
          estimatedDate,
          comments,
        } = activity;


        return {
            title: title,
            description: description,
            estimatedDate: estimatedDate ? new Date(estimatedDate) : '',
            app: app,
            userId: userId,
            comments: comments,
        }
    },
    handleSubmit: (values, {props}) => {
      props.setOpenDrawer(false);
      props.updateActivity({...values}, props.activity._id);
    }
})(withStyles(indexesPageStyle)(TaskForm));