import React, { useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Form, withFormik, Field } from "formik";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import * as yup from "yup";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import ButtonIcon from "../../components/styled/ButtonIcon";
import Send from "@material-ui/icons/Send";
import Cancel from "@material-ui/icons/Cancel";
import { NavLink } from "react-router-dom";
import Select from 'react-select';

const emailMessageFormSchema = yup.object().shape({
    app: yup.string(), 
    subject: yup.string(),
    to: yup.array(),
    contentHtml: yup.string()
    
  });

const EmailMessageForm = (props) => {
    const {
        setFieldValue,
        submitForm,
        apps,
        setUserFilter,
        users
      } = props;

    const [ content, setContent ] = useState('');
    const handleChange = value => {
        setContent(value);
        setFieldValue('contentHtml', draftToHtml(convertToRaw(value.getCurrentContent())));

    }

  
    const { loading, data } = apps;
    const { loadingUsers, dataUsers } = users;

    const onAppsRender = params => {
      return params.map(param => {
        return { value: param._id, label: param.name }
      });
    }

    const [selectApp, setSelectApp] = useState(0);

    const selectHandleChange = selected => {
      setFieldValue('app', selected.value)
      setSelectApp(selected); 
      setUserFilter(selected.value);
    };

    return (
      <Form>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Select
              style={styles.selector}
              placeholder={"Cliente"}
              value={selectApp}
              options={
                !loading ? onAppsRender(data.getApps) : []
              }
              onChange={selectHandleChange}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Autocomplete
              multiple
              freeSolo
              id="tags-standard"
              options={
                !loadingUsers ? dataUsers.users.map(user => user.email) : []
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return <Chip label={option} {...getTagProps({ index })} />;
                })
              }
              onChange={(event, value) => setFieldValue("to", value)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Correo"
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              name="subject"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText="Asunto: "
                    id="subject"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...field
                    }}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Editor
              editorState={content}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              onEditorStateChange={handleChange}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={2}>
            <ButtonIcon
              icon={Send}
              label="Enviar"
              color={"info"}
              block={true}
              onClick={() => {
                submitForm();
              }}
            />
          </GridItem>
          <GridItem xs={3}>
            <NavLink to={"/message/emailList"}>
              <ButtonIcon icon={Cancel} label="Cancelar" />
            </NavLink>
          </GridItem>
        </GridContainer>
      </Form>
    );
};

const styles = {
    editorHeight: {
        hetght: '300px'
    },
    selectors:{
      display: "flex",
      position: "absolute"
    },
}

const EditOpportunityFormWF = withFormik({
    validationSchema: emailMessageFormSchema,
    mapPropsToValues: (props) => {
      return {
        app: '',
        subject: '',
        to: '',
        contentHtml: '',
        saveEmail: true
    }
    },
    handleSubmit: (values, {props}) => {
        props.submitSuccess(values);
    },
})(EmailMessageForm);

export default EditOpportunityFormWF;
