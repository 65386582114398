import React from 'react'
import { NewGeofenceGroupView } from '../../views/Geofences/NewGeofenceGroupView';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { GEOFENCE_GROUP_BY_ID } from './gql/Queries';
import { UPDATE_GEOFENCE_MUTATION, UPDATE_GEOFENCE_GROUP_MUTATION } from './gql/Mutations';
import useGlobal from "../../store";
import history from '../../utils/history';

const NewGeofence = (props) => {
  const { match } = props;
  const { data, loading } = useQuery(GEOFENCE_GROUP_BY_ID, {
    variables: {
      where: {
        _id: match.params.id
      }
    },
    fetchPolicy: "network-only"
  });

  const updateGeofence = useMutation(UPDATE_GEOFENCE_MUTATION);
  const updateGeofenceGroup = useMutation(UPDATE_GEOFENCE_GROUP_MUTATION);
  const [, globalActions] = useGlobal();

  if (!loading && data) {
    return (
      <NewGeofenceGroupView
        geofenceGroup={data.getGeofenceGroupbyId}
        archived={async (values) => {
          let variables = {
            data: {
              ...values,
              archived: true,
            },
            where: {
              _id: values._id,
            },
          };

          delete variables.data._id;

          const resultMutation = await updateGeofence({ variables: variables });

          if (!resultMutation.loading) {
            if (!resultMutation.error) {
              
              window.location.reload();
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: "Geocerca Actualizada",
                color: "success",
              });
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al actualizar geocerca",
                color: "danger",
              });
            }
          }
        }}
        onSubmit={async (values) => {
          const variables = {
            data: {
              name: values.name,
              geofences: data.getGeofenceGroupbyId.geofences,
              archived: data.getGeofenceGroupbyId.archived,
              created: data.getGeofenceGroupbyId.created
            },
            where: {
              _id: match.params.id
            }
          }

          

          const resultGeofenceGroupMutation = await updateGeofenceGroup({ variables: variables });

          if (!resultGeofenceGroupMutation.loading) {
            if (!resultGeofenceGroupMutation.error) {
              history.push(`/geofence-group/list`);
              globalActions.notification.openNotification("tr", {
                message: "Geocerca Actualizada",
                color: "success",
              });
            }else{
              globalActions.notification.openNotification("tr", {
                message: "Error al actualizar geocerca",
                color: "danger",
              });
            }
          }
        }}
      />
    );
  }else{
    return null;
  }
  
}

export default NewGeofence;
