import React, { useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Position from "components/Position";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import { CircularProgress } from '@material-ui/core';
import GeofencesGroupTale from '../../tables/Geofences/GeofencesGroupTale';
import GeofenceGroupModalForm from '../../forms/Geofences/GeofenceGroupForm';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


const GeofencesGroupView = (props) => {
    const { t } = useTranslation("geofences");
    const {geofences, loading, onSubmit, updateGeofence} = props;
    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };
    return (
      <GridContainer justify="center">
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle id="classic-modal-slide-title" disableTypography>
            <h4>{t("geofences.newGeofence")}</h4>
          </DialogTitle>
          <DialogContent id="modal-slide-description">
            <GeofenceGroupModalForm
              handleClose={handleClose}
              onSubmit={onSubmit}
            />
          </DialogContent>
        </Dialog>
        <GridItem xs={8}>
          <Position rigth>
            <Button
              color={"primary"}
              onClick={() => {
                /* history.push('/geofence-group/new') */
                setOpen(true);
              }}
            >
              {t("geofences.newGeofence")}
            </Button>
          </Position>
        </GridItem>
        <GridItem xs={8}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <FormatShapesIcon />
              </CardIcon>
              <h4>{t("geofences.geofenceList")}</h4>
            </CardHeader>
            <CardBody>
              {loading ? (
                <div style={styles.progressCnt}>
                  <CircularProgress style={styles.progress} />
                </div>
              ) : (
                <GeofencesGroupTale
                  updateGeofence={updateGeofence}
                  geofences={
                    geofences && geofences.geofencesGroup
                      ? geofences.geofencesGroup
                      : []
                  }
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

const styles = {
  progressCnt: {
    width: "100px !important",
    margin: "auto",
  },
  progress: {
    margin: "16px",
  },
};

export default GeofencesGroupView;
