import React from 'react'
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { useTranslation } from 'react-i18next';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import GeofenceFormFK from '../../forms/Geofences/AddGeofenceForm';

const AddGeofenceView = (props) => {
    const { t } = useTranslation("geofences");
    const { onSubmit, geofence } = props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <FormatShapesIcon />
              </CardIcon>
              {/* <div style={styles.right}>
                <Button
                  size="sm"
                  color="info"
                  onClick={() => history.push(`/geofence-group/add`)}
                >
                  {t("geofences.newGeofence")}
                </Button>
              </div> */}
              <h4>{t("geofences.forms.addGeofence")}</h4>
            </CardHeader>
            <CardBody>
              <GeofenceFormFK geofence={geofence && geofence.geofence ? geofence.geofence : {}} onSubmit={onSubmit} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

export default AddGeofenceView;
