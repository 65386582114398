import React from 'react'
import { useTranslation } from 'react-i18next';
import ReactTable from "react-table";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import DivCenter from '../../components/styled-components/DivCenter';
import auth from '../../utils/auth';
import useGlobal from "../../store";

const GeofencesGroupTale = (props) => {
    const { geofences, updateGeofence } = props;
    const { t } = useTranslation('geofences');
    const [{session}, ] = useGlobal();
    
    const edit = (row) => {
      if (
        session.user &&
        auth.hasPermission("geofenceUpdate", session.user.role.permissions)
      ) {
        return (
          <Tooltip title={t("instances.tooltip.edit")}>
            <Link
              to={{
                pathname: `/geofence-group/edit/${row._original._id}`,
                state: {
                  instance: row._original,
                },
              }}
            >
              <Button justIcon round simple color="info">
                <EditIcon />
              </Button>
            </Link>
          </Tooltip>
        );
      }
    };
  
    const archived = (row) => {
      if (
        session.user &&
        auth.hasPermission("geofenceDelete", session.user.role.permissions)
      ) {
        return (
          <Tooltip title={t("instances.tooltip.archived")}>
            <Button
              justIcon
              round
              simple
              color="danger"
              onClick={() => updateGeofence(row._original)}
            >
              <DeleteForever />
            </Button>
          </Tooltip>
        );
      }
    };

    return (
      <ReactTable
        data={geofences}
        columns={[
          {
          Header: <DivCenter>{t("geofences.list.name")}</DivCenter>,
            accessor: "name",
            filterable: false,
            sortable: false,
          },
          {
          Header: <DivCenter>{t("geofences.list.geofences")}</DivCenter>,
            id: 'geofences',
            accessor: d => {
                return d.geofences.length;
            },
            style: { textAlign: "center" },
            filterable: false,
            sortable: false,
          },
          {
            Header: "",
            accessor: "actions",
            Cell: ({ row }) => (
                <div className="actions-right">
                  {edit(row)}
                  {archived(row)}
                </div>
              ),
            filterable: false,
            sortable: false,
          }
        ]}
        defaultPageSize={15}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
}

export default GeofencesGroupTale
