import React from 'react'
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { useTranslation } from "react-i18next";
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import { CircularProgress } from '@material-ui/core';
import GeofenceRecordTable from '../../tables/Geofences/GeofenceRecordTable';

const GeofenceRecordView = (props) => {
    const { t } = useTranslation("geofences");
    const { loading, geofences, updatedGeofence } = props;

    return (
      <GridContainer justify="center">
        <GridItem xs={8}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <FormatShapesIcon />
              </CardIcon>
              <h4>{t("geofences.geofenceList")}</h4>
            </CardHeader>
            <CardBody>
              {loading ? (
                <div style={styles.progressCnt}>
                  <CircularProgress style={styles.progress} />
                </div>
              ) : (
                <GeofenceRecordTable
                  updatedGeofence={updatedGeofence}
                  geofences={
                    geofences && geofences.getPaymentGeofenceRecord
                      ? geofences.getPaymentGeofenceRecord
                      : []
                  }
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}
const styles = {
    progressCnt: {
      width: "100px !important",
      margin: "auto",
    },
    progress: {
      margin: "16px",
    },
  };
  
export default GeofenceRecordView
