import gql from "graphql-tag";

export const INSTANCES_FEED = gql`
  query INSTANCES_FEED($where: InstanceWhereInput!, $first: Int!, $skip: Int! $orderBy: CommonSort) {
    instancesConnection(where: $where, first: $first, skip: $skip, orderBy: $orderBy ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        id
        name
        description
        requirementDescription
        icon
        icons
        address
        webPage
        appType
        phone
        identification {
          type
          value
        }
        contact {
          name
          phone
          email
        }
        timezone {
          zone
          utc
        }
        location {
          coordinates
        }
        status
        instanceType
        instanceStatus
        category
        opportunity {
          dateIn
          dateEnd
        }
        city {
          id
          timezone
          continentName
          geonameId
          continentCode
          countryIsoCode
          subdivisionName
          cityName
          countryName
        }
        opportunity {
          dateIn
          dateEnd
        }
        updateInstance
        created
        features {
          id
          app
          features
        }
        token
        firebaseProject
        maxAppUsers
        franchise
        archived
        planId
        customPlanFeatures {
          _id
          name
          type
          value
        }
      }
    }
  }
`;

export const GET_INSTANCE = gql `
    query($where: InstanceWhereUniqueInput!){
        getInstance(where: $where){
                id
            created
            phone
            name
            description
            requirementDescription
            icon
            address
            webPage
            appType
            identification{
                type
                value
            }
            contact{
                name
                phone
                email
            }
            timezone{
                zone
                utc
            }
            location{
                coordinates
            }
            status
            instanceType
            instanceStatus
            category
                opportunity{
                dateIn
                dateEnd
            }
        }
    }
`;

export const CITIES = gql`
    query{
        cities{
            id
            cityName
            countryName
            countryIsoCode
            continentName
            continentCode
            subdivisionName
            geonameId
            timezone
        }
    }
`;


export const REALTIME_LOCATION_CONFIGS = gql`
  query RealtimeLocationsConfigs($instanceId: ID!) {
    realtimeLocationConfigs  (where: {instanceId: $instanceId, active: true}){
      _id
      segmentId
      segmentRef {
        name
      }
      instanceId
      usersCount
      interval
      schedule
      active
    }
  }
`;

export const SEGMENTS_INSTANCE = gql`
  query SegmentsInstance($instanceId: ID!) {
    segments(where: {instanceId: $instanceId archived: false}) {
      _id
      name
      archived
    }
  }
`;
