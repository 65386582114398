import React, { useState } from 'react';
import { Switch, makeStyles } from '@material-ui/core';

const SwitchContent = ({ value, onChange, name, disabled }) => {
    const [currentValue, setCurrentValue] = useState(value === 'on' ? true : false);
    const classes = useStyles();

    return (
      <Switch
        disabled={disabled}
        checked={currentValue}
        onChange={(e)=>{
            setCurrentValue(e?.target?.checked || false);
            onChange(e?.target?.checked || false);
        }}
        color="primary"
        inputProps={{ "aria-label": "primary checkbox" }}
        name={name}
        classes={{
          switchBase: classes.switchBase,
          checked: classes.checked,
          track: classes.track,
        }}
      />
    );
}

const useStyles = makeStyles((theme) => ({
  switchBase: {
    "&$checked": {
      color: "#6ab3d4"
    },
    "&$checked + $track": {
      backgroundColor: "#6fadca"
    }
  },
  checked: {},
  track: {}
}));

export default SwitchContent;
