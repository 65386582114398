import React from 'react'
import {Field, Form, withFormik} from "formik";
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

//Custom components
import ButtonIcon from "../../components/styled/ButtonIcon";
import SelectTo from 'react-select';
// icons
import Send from "@material-ui/icons/Send";
import Cancel from "@material-ui/icons/Cancel";

const AddUserForm = (props) => {

    // Formik bag
    const {
        setFieldValue,
        submitForm,
        roles,
        teams, 
        app
      } = props;

    const {t} = useTranslation('instances');

    return (
      <Form>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              name="email"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText={t("instances.forms.fields.email.label")}
                    id="email"
                    type={"email"}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      ...field,
                    }}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              name="role"
              render={({ field }) => {
                return (
                  <SelectTo
                    onChange={(selectedOption) =>
                      setFieldValue("role", selectedOption.id)
                    }
                    placeholder={t("instances.forms.fields.role.label")}
                    options={roles ? roles : []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => `${option.id}`}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              name="team"
              render={({ field }) => {
                return (
                  <SelectTo
                    onChange={(selectedOption) => {
                      setFieldValue("team", selectedOption.id);
                    }}
                    placeholder={t("instances.forms.fields.team.label")}
                    options={teams}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => `${option.id}`}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <GridContainer justify="center">
              <GridItem xs={3}>
                <NavLink to={`/instances/users/${app}`}>
                  <ButtonIcon icon={Cancel} label={t("instances.cancel")} />
                </NavLink>
              </GridItem>
              <GridItem xs={3}>
                <ButtonIcon
                  icon={Send}
                  label={t("instances.send")}
                  color={"info"}
                  onClick={() => {
                    submitForm();
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
    );
}

const AddUserFormWF = withFormik({
    mapPropsToValues: props => ({
      email: "",
      role: "",
      team:""
    }),
    handleSubmit: (values, {props}) => {
      props.submitSuccess(values);
    },
  })(AddUserForm);

export default AddUserFormWF;
