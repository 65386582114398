import React, { useEffect} from "react";
import { Form, withFormik, Field } from "formik";
import * as yup from "yup";
import { PropTypes } from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import Assignment from "@material-ui/icons/Assignment";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import ButtonIcon from "../../components/styled/ButtonIcon";

import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";

import InfoInstance from "./components/InfoInstance";
import IndentityIntance from "./components/IndentityIntance";
import ContactIntance from "./components/ContactIntance";

import ImageUploader from 'react-images-upload';
import moment from 'moment-timezone';
import useGlobal from "../../store";
import { hasError } from '../../utils/forms/inputs';

/*
 * Validation Schema for NewInstanceForm
 * */
const newDemoFormSchema = yup.object().shape({
  name: yup.string().required(),
  appType: yup.number(),
  address: yup.string(),
  category: yup.number(),
  phone: yup.string(),
  description: yup.string(),
  webPage: yup.string(),
  users: yup.number(),
  instanceStatus: yup.string(),
  requirementDescription: yup.string(),
  identification: yup.object().shape({
    type: yup.number(),
    value: yup.string()
  }),
  contact: yup.object().shape({
    name: yup.string(),
    phone: yup.string(),
    email: yup.string()
  }),
  timezone: yup.object().shape({
    zone: yup.string(),
    utc: yup.string()
  }),
  icon: yup.array().required()
});

const NewInstanceForm = props => {
  // Formik bag
  const {
    errors,
    touched,
    setFieldValue,
    submitForm,
    submitCount,
    isSubmitting,
    isValid,
    cities,
  } = props;
  const { t } = useTranslation("instances");
  const [, globalActions] = useGlobal();

  // Submit validation failed effect
  useEffect(() => {

    if (submitCount > 0 && !isSubmitting && !isValid) {

      const fields = Object.keys(errors);

      if (fields.length) {
        globalActions.notification.openNotification('tr', {
          message: t('instances.forms.new.validation.failed'),
          color: 'danger'
        });
      }
    }

  }, [submitCount, isSubmitting]);

  return (
    <Form>
      <GridContainer>
        <GridItem xs={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader>
                  <CardIcon color="info">
                    <Apps />
                  </CardIcon>
                  <h4>{t("instances.new.dataInfo")}</h4>
                </CardHeader>
                <CardBody>
                  <InfoInstance
                    errors={errors}
                    touched={touched}
                    cities={cities}
                    setFieldValue={setFieldValue}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader>
                  <CardIcon color="info">
                    <Assignment />
                  </CardIcon>
                  <h4>{t("instances.new.idetityInfo")}</h4>
                </CardHeader>
                <CardBody>
                  <IndentityIntance touched={touched} errors={errors} />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12}>
              <Card>
                <CardHeader>
                  <CardIcon color="info">
                    <AssignmentInd />
                  </CardIcon>
                  <h4>{t("instances.new.contactInfo")}</h4>
                </CardHeader>
                <CardBody>
                  <ContactIntance touched={touched} errors={errors} />
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12}>
              <Field 
                name={"icon"}
                render={({field})=>{
                  return (
                    <ImageUploader
                      withIcon={true}
                      singleImage={true}
                      withLabel={false}
                      withPreview={true}
                      buttonText={t(
                        "instances.forms.fields.image.label"
                      )}
                      onChange={image => {
                        setFieldValue("icon", image);
                      }}
                      imgExtension={[".jpg", ".png"]}
                      maxFileSize={5242880}
                      buttonStyles={hasError(errors, "icon") ? styles.error : {}}
                    />
                  );
                }}
              />
              
            </GridItem>

            <GridContainer justify="center">
              <GridItem xs={3}>
                <NavLink to={"/instances/demos"}>
                  <ButtonIcon icon={Cancel} label={t("instances.cancel")} />
                </NavLink>
              </GridItem>
              <GridItem xs={3}>
                <ButtonIcon
                  id='save-instance-button'
                  icon={Save}
                  label={t("instances.save")}
                  color={"info"}
                  onClick={() => {
                    submitForm();
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const styles ={
  marginTop: {
      marginTop: '4%'
  },
  error: {
      backgroundColor: "red"
  }
}

NewInstanceForm.prototype = {
  classes: PropTypes.object.isRequired,
  submitSuccess: PropTypes.func.isRequired,
  setTimezone: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  data: PropTypes.object, 
  setSelectCity: PropTypes.func.isRequired,
  selectedCity: PropTypes.object.isRequired,
};

const NewInstanceFormWF = withFormik({
  validationSchema: newDemoFormSchema,
  mapPropsToValues: (props) => {
    return {
      name: "",
      appType: 1,
      address: "",
      category: 1,
      phone: "",
      description: "",
      webPage: "",
      maxAppUsers: 20,
      instanceStatus: "Demo",
      instanceType: "Demo",
      requirementDescription: "",
      identification: {
        type: 0,
        value: ""
      },
      contact: {
        name: "",
        phone: "",
        email: ""
      },
      timezone: {
        zone: moment.tz.guess(),
        utc: ""
      },
      location:{
        coordinates: [-99.138498, 19.414254]
      },
      city: {
        id : "53ca9109745628ea47b6b458",
        timezone : "",
        continentName : "North America",
        geonameId : 3530597,
        continentCode : "NA",
        countryIsoCode : "MX",
        subdivisionName : "The Federal District",
        cityName : "Mexico City",
        countryName : "Mexico"
      },
      icon: ''
    }
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(NewInstanceForm);

export default NewInstanceFormWF;
