import gql from "graphql-tag";

export const CHECK_SESSION = gql`
  query checkSession($token: String!) {
    checkSession(token: $token) {
      user {
        _id
        firstName
        lastName
        email
        role{
          _id
          name
          permissions
          master
        }
        franchise
      }
      expiration
      token
    }
  }  
`;
