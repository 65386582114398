import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import CircularProgress from '@material-ui/core/CircularProgress';

import IndexesForm from '../../forms/Indexes/IndexesForm';
import TablePagination from '../../paginations/table/TablePagination';
import ActivityIndexesTable from '../../tables/ActivityIndexes/ActivityIndexesTable';


export const ActivityIndexView = ({ classes, loading, data, paginationAction, paginationOptions, filterAction, filterOptions, t }) =>{
    const [indexes, setIndixes] = useState([]);

    const [paginationStatus, setPaginationStatus] = useState({
        hasPreviousPage: false,
        hasNextPage: true
    });

    const [totalCount, setTotalCount] = useState(0);

    async function getData() {
        if (data) {
            const { totalCount, edges, pageInfo } = await data;
            setIndixes(edges);
            setPaginationStatus(pageInfo);
            setTotalCount(totalCount);
        }
    }
    
    useEffect(()=>{
       getData();
    });
    

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Timeline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t("indexes.title")}</h4>
              </CardHeader>
              <CardBody>
                <IndexesForm
                  classes={classes}
                  filterAction={filterAction}
                  paginationAction={paginationAction}
                  filterOptions={filterOptions}
                  t={t}
                />
                {!loading ? (
                  <TablePagination
                    classes={classes}
                    paginationAction={paginationAction}
                    paginationOptions={paginationOptions}
                    paginationStatus={paginationStatus}
                    totalCount={totalCount}
                  />
                ) : null}

                {loading ? (
                  <div className={classes.progressCnt}>
                    <CircularProgress className={classes.progress} />
                  </div>
                ) : (
                  <ActivityIndexesTable data={indexes} t={t} />
                )}

                {!loading ? (
                  <TablePagination
                    classes={classes}
                    paginationAction={paginationAction}
                    paginationOptions={paginationOptions}
                    paginationStatus={paginationStatus}
                    totalCount={totalCount}
                  />
                ) : null}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
}



ActivityIndexView.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object,
    paginationAction: PropTypes.func.isRequired,
    paginationOptions: PropTypes.object.isRequired,
    filterAction: PropTypes.func.isRequired,
    filterOptions: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default compose(
    withStyles(indexesPageStyle),
    withTranslation("indexes")
)(ActivityIndexView);