import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { compose } from "recompose";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";
import { PropTypes } from 'prop-types';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import NewInstanceForm from '../../forms/Instances/NewInstanceForm';

const NewIntanceView = (props) => {
    const {
      location,
      submitSuccess,
      cities,
    } = props;

    const [t,] = useTranslation('instances');

    const { state } = location;

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <h2>{t('instances.demos.newDemo')}</h2>
          </GridItem>
          <GridItem xs={12}>
            <NewInstanceForm
              data={state}
              submitSuccess={submitSuccess}
              cities={cities}
            />
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
}

NewIntanceView.prototype = {
    location: PropTypes.object.isRequired,
    submitSuccess: PropTypes.func.isRequired,
}

export default compose(
    withStyles(indexesPageStyle)
)(NewIntanceView);
