import React, { useEffect, useState } from 'react';
import { Form, withFormik, Field } from "formik";
import * as yup from "yup";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SelectTo from 'react-select';
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";
import ButtonIcon from "../../components/styled/ButtonIcon";
import { NavLink } from "react-router-dom";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { useTranslation } from "react-i18next";
import { hasError } from '../../utils/forms/inputs';

const userFormSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  role: yup.string().required(),
  franchise: yup.string().required(),
});

function EditUserForm(props) {
    const {
        errors, touched, setFieldValue, roles, submitForm, values, partners
    } = props;

    const { t } = useTranslation("users");

    const [role, setRole] = useState('');
    const [partner, setPartner] = useState('');
    
    useEffect(() => {
      if (roles.length) {
        setRole(roles.find(role => role._id === values.role));
      }

      if (partners.length) {
        setPartner(partners.find(partner => partner._id === values.franchise));
      }
    }, [props]);

    return (
      <Form>
        <GridContainer justify="center">
          <GridItem xs={8}>
            <Field
              name="firstName"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText={t("users.forms.fields.firstName.label")}
                    id="firstName"
                    type={"text"}
                    success={
                      !hasError(errors, "firstName") && touched.firstName
                    }
                    error={hasError(errors, "firstName")}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    inputProps={{
                      ...field,
                    }}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={8}>
            <Field
              name="lastName"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText={t("users.forms.fields.lastName.label")}
                    id="lastName"
                    type={"text"}
                    success={!hasError(errors, "lastName") && touched.lastName}
                    error={hasError(errors, "lastName")}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    inputProps={{
                      ...field,
                    }}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={8}>
            <Field
              name="email"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText={t("users.forms.fields.email.label")}
                    id="email"
                    type={"text"}
                    success={!hasError(errors, "email") && touched.email}
                    error={hasError(errors, "email")}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    inputProps={{
                      ...field,
                    }}
                  />
                );
              }}
            />
          </GridItem>

          <GridItem xs={8} style={styles.marginTop}>
            <Field
              name="role"
              render={({ field }) => {
                return (
                  <SelectTo
                    value={role}
                    onChange={(selectedOption) => {
                      setRole(selectedOption);
                      setFieldValue("role", selectedOption._id);
                    }}
                    options={roles ? roles : []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option}
                    placeholder={
                      <div>{t("users.forms.fields.role.label")}</div>
                    }
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={8} style={styles.marginTop}>
            <Field
              name="franchise"
              render={({ field }) => {
                return (
                  <SelectTo
                    value={partner}
                    onChange={(selectedOption) => {
                      setPartner(selectedOption);
                      setFieldValue("franchise", selectedOption._id);
                    }}
                    options={partners ? partners : []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option}
                    placeholder={
                      <div>{t("users.forms.fields.partner.label")}</div>
                    }
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={8} style={styles.marginTop}>
            <GridContainer justify="center">
              <GridItem xs={6}>
                <NavLink to={"/users/list"}>
                  <ButtonIcon icon={Cancel} label={t("users.cancel")} />
                </NavLink>
              </GridItem>
              <GridItem xs={6}>
                <ButtonIcon
                  icon={Save}
                  label={t("users.save")}
                  color={"info"}
                  onClick={() => {
                    submitForm();
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
    );
}

const styles = {
  marginTop: {
    marginTop: "2%",
    marginBottom: "2%",
  },
  groupStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  groupBadgeStyles: {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  },
  error: {
    backgroundColor: "red",
  },
  formControl: {
    margin: '1rem',
    minWidth: 120,
  }
};

const EditUserFormWF = withFormik({
    validationSchema: userFormSchema,
    mapPropsToValues: ({ user }) => {
        const { firstName, lastName, email, role, franchise } = user;
        return {
            firstName: firstName ? firstName : '',            
            lastName: lastName ? lastName : '',        
            email: email ? email : '',        
            role: role ? role._id : null,
            franchise: franchise ? franchise : null       
        }
    },
    handleSubmit: (values, { props }) => {
        props.submitSuccess(values);
    }
})(EditUserForm);

export default EditUserFormWF; 
