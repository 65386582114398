import React, { useMemo, useState } from "react";
import DivCenter from "../../components/styled-components/DivCenter";
import ReactTable from "react-table";
import TextFieldContentWidth from "../../views/PlansView/components/TextFieldContentWidth";
import SwitchContent from "../../views/PlansView/components/SwitchContent";
import { INSTANCE_FEATURES } from "../../config/constants";
import { useTranslation } from "react-i18next";

const PlansTable = ({ data, updatePLan }) => {
  const { t } = useTranslation("plans");

  const [currentEditField, setCurrentEditField] = useState(null);

  const dataTable = useMemo(() => {
    const features = INSTANCE_FEATURES.map((feature) => ({ ...feature }));

    const columns = data.length
      ? data.map((plan) => ({
          Header: () => {
            if (
              currentEditField &&
              currentEditField.featureId === 0 &&
              currentEditField.planId === plan._id
            ) {
              return (
                <DivCenter>
                  <TextFieldContentWidth
                    value={plan.name}
                    type="text"
                    onChange={(newValue) => {
                      if (plan.name !== newValue.toString()) {
                        let currentPlan = plan;
                        currentPlan.name = newValue.toString();
        
                        const updatePlan = {
                          name: currentPlan.name,
                          description: currentPlan.description,
                          features: [...currentPlan.features],
                        };
        
                        updatePLan({
                          variables: {
                            data: updatePlan,
                            where: {
                              _id: plan._id,
                            },
                          },
                        }); 
                      }
                    }}
                    endEdit={() => {
                      setCurrentEditField(null);
                    }}
                  />
                </DivCenter>
              );
            } else {
              return (
                <DivDoubleClick
                  callback={() => {
                    setCurrentEditField({
                      featureId: 0,
                      planId: plan._id,
                    });
                  }}
                >
                  {plan.name}
                </DivDoubleClick>
              );
            }
          },
          accessor: plan.name,
          sortable: false,
          filterable: false,
          with: 200,
          style: { cursor: "pointer" },
          Cell: ({ original }) => {
            const features = plan.features;
            const feature = features.find(
              (feature) => feature.name === original.name
            );
            return rendetTypeField[feature.type](
              feature.value,
              feature._id,
              plan._id
            );
          },
        }))
      : [];

    if (columns.length) {
      columns.unshift({
        Header: <DivCenter>{t("plans.featuresTitle")}</DivCenter>,
        fixed: "left",
        accessor: "name",
        sortable: false,
        filterable: false,
        with: 200,
        style: { textAlign: "center", whiteSpace: "unset", fontWeight: "400" },
        Cell: ({ original }) => {
          return t(`plans.features.${original.name}`);
        },
      });
    }

    return {
      data: features,
      columns: columns,
    };
  }, [data, currentEditField]);

  const DivDoubleClick = ({ callback, children }) => {
    const [clickCount, setClickCount] = useState(0);
    return (
      <DivCenter
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (clickCount === 0) {
            setClickCount(1);
            setTimeout(() => {
              setClickCount(0);
            }, 200);
          } else {
            callback();
          }
        }}
      >
        {children}
      </DivCenter>
    );
  };

  const rendetTypeField = {
    Number: (value, featureId, planId) => {
      if (
        currentEditField &&
        currentEditField.featureId === featureId &&
        currentEditField.planId === planId
      ) {
        return (
          <DivCenter>
            <TextFieldContentWidth
              value={value}
              type="number"
              onChange={(newValue) => {
                if (value !== newValue.toString()) {
                  const currentPlan = data.find((plan) => plan._id === planId);

                  let currentFeature = currentPlan.features.find(
                    (feature) => feature._id === featureId
                  );
                  currentFeature.value = newValue.toString();
  
                  const currentFeatures = currentPlan.features.filter(
                    (feature) => feature._id !== featureId
                  );
  
                  const updatePlan = {
                    name: currentPlan.name,
                    description: currentPlan.description,
                    features: [...currentFeatures, currentFeature],
                  };
  
                  updatePLan({
                    variables: {
                      data: updatePlan,
                      where: {
                        _id: planId,
                      },
                    },
                  }); 
                }
              }}
              endEdit={() => {
                setCurrentEditField(null);
              }}
            />
          </DivCenter>
        );
      } else {
        return (
          <DivDoubleClick
            callback={() => {
              setCurrentEditField({
                featureId: featureId,
                planId: planId,
              });
            }}
          >
            {value}
          </DivDoubleClick>
        );
      }
    },
    Boolean: (value, featureId, planId) => {
      return (
        <DivCenter>
          <SwitchContent
            value={value}
            onChange={(newValue) => {
              const currentPlan = data.find((plan) => plan._id === planId);

              let currentFeature = currentPlan.features.find(
                (feature) => feature._id === featureId
              );
              currentFeature.value = newValue ? "on" : "off";

              const currentFeatures = currentPlan.features.filter(
                (feature) => feature._id !== featureId
              );

              const updatePlan = {
                name: currentPlan.name,
                description: currentPlan.description,
                features: [...currentFeatures, currentFeature],
              };

              updatePLan({
                variables: {
                  data: updatePlan,
                  where: {
                    _id: planId,
                  },
                },
              });
            }}
            name={featureId}
          />
        </DivCenter>
      );
    },
  };

  return (
    <ReactTable
      data={dataTable.data}
      columns={dataTable.columns}
      defaultPageSize={dataTable.data.length}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
      style={{
        maxHeight: "650px",
        overflowY: "scroll",
      }}
    />
  );
};

export default PlansTable;
