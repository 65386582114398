import React from 'react'
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import RateReviewIcon from '@material-ui/icons/RateReview';
import CustomOpportunityFormWF from '../../forms/Messages/CustomMessageForm';


const CustomMessageView = ({apps, submitSuccess}) => {

    return (
        <GridContainer justify="center">
        <GridItem xs={8}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <RateReviewIcon />
              </CardIcon>
              <h4>Redacción de mensaje</h4>
            </CardHeader>
            <CardBody>
                <CustomOpportunityFormWF apps={apps} submitSuccess={submitSuccess} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
}

export default CustomMessageView;
