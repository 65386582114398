import React from 'react'
import { useTranslation } from 'react-i18next';
import ReactTable from "react-table";
import DivCenter from '../../components/styled-components/DivCenter';
import moment from "moment";
import Checkbox from '@material-ui/core/Checkbox';

const GeofenceRecordTable = (props) => {
    const { geofences, updatedGeofence } = props;
    const { t } = useTranslation("geofences");

    return (
      <ReactTable
        data={geofences}
        columns={[
          {
            Header: <DivCenter>{t("geofences.list.app")}</DivCenter>,
            id: "app",
            accessor: (d) => {
                return d.appRef.name;
            },
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("geofences.list.geofence")}</DivCenter>,
            id: "geofence",
            accessor: (d) => {
              return d.paymentGeofenceGroup.name
            },
            style: { textAlign: "center" },
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("geofences.list.date")}</DivCenter>,
            id: "created",
            accessor: (d) => {
                return moment(d.created).local().format("DD-MM-YY");
            },
            style: { textAlign: "center" },
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("geofences.list.activated")}</DivCenter>,
            id: "activated",
            accessor: (d) => {
                
                return (
                  <Checkbox
                    checked={d.activated}
                    onChange={() => updatedGeofence(d)}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                );
            },
            style: { textAlign: "center" },
            filterable: false,
            sortable: false,
          },
        ]}
        defaultPageSize={geofences.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
        style={{
          height: "600px",
          overflowY: "scroll",
        }}
      />
    );
}

export default GeofenceRecordTable;
