import gql from "graphql-tag";

export const APPS_FEED = gql`
  query APPS_FEED($where: IndexesInput!) {
    getApps(where: $where) {
      _id
      name
    }
  }
`;

export const USERS_FEED = gql`
  query($where: UsersInput!) {
    users(where: $where) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const MESSAGES_FEED = gql`
  query($where: MessageWhereInput, $first: Int, $skip: Int) {
    getMessages(where: $where, first: $first, skip: $skip) {
      _id
      app
      to
      title
      subject
      contentHtml
      expirationDate
      created
      franchise
    }
  }
`;

export const MESSAGES_APP_FEED = gql`
  query($where: MessageWhereInput, $first: Int, $skip: Int) {
    getMessagesByApp(where: $where, first: $first, skip: $skip) {
      _id
      app
      name
      to
      title
      subject
      contentHtml
      expirationDate
      created
      franchise
    }
  }
`;
