import React, {useState} from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Form, withFormik, Field } from "formik";
import * as yup from "yup";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ButtonIcon from "../../components/styled/ButtonIcon";
import Send from "@material-ui/icons/Send";
import Cancel from "@material-ui/icons/Cancel";
import { NavLink } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';

const tutorialInfoFormSchema = yup.object().shape({
    name: yup.string(),
    description: yup.string(),
    type: yup.string(),
    url: yup.string(),
    category: yup.string()
  });

const TutorialInfoForm = (props) => {
    const {
        submitForm,
        setFieldValue,
        categories
      } = props;
    
    const [typeValue, setTypeValue] = useState('Y');
    const [category, setCategory] = useState(0);

    const onCategoryRender = params => {
      return params.map(param => {
        return { value: param._id, label: param.name }
      });
    }

    const selectCategory = selected => {
      setFieldValue('category', selected.value);
      setCategory(selected); 
    };

    const selectType = event => {
        let selectValue = event.target.value;
        setTypeValue(selectValue);
        setFieldValue('type', selectValue)

        if (selectValue === 'D') {
            setFieldValue('category', '');
        }
    }
    return (
      <Form>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              name="name"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText="Nombre: "
                    id="name"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...field
                    }}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              name="description"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText="Descripción: "
                    id="description"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...field
                    }}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12}>
            <RadioGroup
              aria-label="position"
              name="type"
              value={typeValue}
              onChange={selectType}
              row
            >
              <FormControlLabel
                value="Y"
                control={<Radio color="primary" />}
                label="Video"
              />
              {/* <FormControlLabel
                value="D"
                control={<Radio color="primary" />}
                label="Documento"
              /> */}
            </RadioGroup>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              name="url"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText="Url: "
                    id="url"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...field
                    }}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>

        {typeValue === "Y" ? (
          <GridContainer justify="center">
            <GridItem xs={12}>
              <Select
                style={styles.selector}
                placeholder={"Categoria"}
                value={category}
                options={onCategoryRender(categories)}
                onChange={selectCategory}
              />
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}

        <GridContainer justify="center">
          <GridItem xs={2}>
            <ButtonIcon
              icon={Send}
              label="Enviar"
              color={"info"}
              block={true}
              onClick={() => {
                submitForm();
              }}
            />
          </GridItem>
          <GridItem xs={3}>
            <NavLink to={"/tutorials/tutorials"}>
              <ButtonIcon icon={Cancel} label="Cancelar" />
            </NavLink>
          </GridItem>
        </GridContainer>
      </Form>
    );
};

const styles = {
    selectors:{
      display: "flex",
      position: "absolute"
    },
}

const TutorialInfoFormWF = withFormik({
  validationSchema: tutorialInfoFormSchema,
  mapPropsToValues: props => {
    return {
      name: "",
      description: "",
      type: "Y",
      url: "",
      category: ""
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(TutorialInfoForm);

export default TutorialInfoFormWF;
