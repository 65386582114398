import React from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import UserView from '../../views/Users/UserView';
import { CREATE_USER_BO } from './gql/Mutations';
import { ROLES_BO } from './gql/Queries';
import history from '../../utils/history';
import useGlobal from "../../store";
import { PARTNERS } from '../Partners/gql/Queries';
import auth from '../../utils/auth';

const NewUser = (props) => {
    const [{session}, globalActions] = useGlobal();
    const { data: dataRoles, loading: loadingRoles } = useQuery(ROLES_BO, {
        variables: {
            where:{
                archived: false
            }
        },
        fetchPolicy: "network-only"
      });
    const { data: dataPartners, loading: loadingPartners } = useQuery(PARTNERS, {
      variables: { where: { }},
      fetchPolicy: "network-only",
    });


    const createUser = useMutation(CREATE_USER_BO);
    if (
      session.user &&
      !auth.hasPermission("userCreate", session.user.role.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: "No tienes permisos",
        color: "danger",
      });

      history.push('dashboard');
    }

    return (
      <UserView
        user={{}}
        partners={!loadingPartners && dataPartners && dataPartners.getAllPartners ? dataPartners.getAllPartners : []}
        roles={
          !loadingRoles && dataRoles && dataRoles.rolesBo
            ? dataRoles.rolesBo
            : []
        }
        submitSuccess={async (values) => {
          delete values.passwordConfirm;

          const variables = {
            data: {
              ...values,
            },
          };

          const resultMutation = await createUser({ variables: variables });
          if (!resultMutation.loading) {
            if (!resultMutation.error) {
              history.push(`/users/list`);
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: "Nuevo usuario creado",
                color: "success",
              });
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al crear usuario",
                color: "danger",
              });
            }
          }
        }}
      />
    );
}

export default NewUser;
