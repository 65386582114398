import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { useTranslation } from "react-i18next";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import { CircularProgress } from "@material-ui/core";
import PlansTable from "../../tables/Plans/PlansTable";

const PlansListView = ({ plans, loading, updatePLan }) => {
  const { t } = useTranslation("plans");
  return (
    <GridContainer justify="center">
      <GridItem xs={7}>
        <Card>
          <CardHeader>
            <CardIcon color="info">
              <CardMembershipIcon />
            </CardIcon>
            <h4>{t("plans.title")}</h4>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div>
                <CircularProgress id="loading-circle" />
              </div>
            ) : (
              <PlansTable data={plans} updatePLan={updatePLan} />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default PlansListView;
