import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import SendIcon from '@material-ui/icons/Send';
import EmailMessageForm from '../../forms/Messages/EmailMessageForm';

const EmailMessageView = ({ apps, users, setUserFilter, submitSuccess }) => {
    
    return (
      <GridContainer justify="center">
        <GridItem xs={8}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <SendIcon />
              </CardIcon>
              <h4>Redacción de correo</h4>
            </CardHeader>
            <CardBody>
                <EmailMessageForm
                  apps={apps}
                  users={users}
                  setUserFilter={setUserFilter}
                  submitSuccess={submitSuccess}
                />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

export default EmailMessageView;