import React, { useEffect, useState } from 'react';
import auth from "../../utils/auth";
import useGlobal from "../../store";
import { PLANS_FEED } from './gql/Queries';
import history from "../../utils/history";
import PlansListView from '../../views/PlansView/PlansListView';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import { UPDATE_PLAN } from './gql/Mutations';

const PlansList = () => {
    const [{ session }, globalActions] = useGlobal();
    const [filters] = useState({
        where: {
            archived: false
        }
    });

    const [getPlans, { data, loading, refetch }] = useLazyQuery(PLANS_FEED);
    const [updatePLan] = useMutation(UPDATE_PLAN, {
      onCompleted: (result) => {
        globalActions.notification.openNotification("tr", {
          message: "Plan modificado",
          color: "success",
        });
      },
      onError: (error) => {
        globalActions.notification.openNotification("tr", {
          message: "Error al modificar plan",
          color: "danger",
        });
      },
    });

    useEffect(()=> {
        if (session.user && auth.hasPermission("plansGlobal", session.user.role.permissions)) {
            getPlans({ variables: filters });
        } else {
          history.push("dashboard");
        }
    }, [session]);

    return (
        <PlansListView 
            plans={data?.plans ? data.plans : []}
            loading={loading}
            updatePLan={updatePLan}
        />
    )
}

export default PlansList;
