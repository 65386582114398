import React, { useState } from "react";
import { useQuery } from "react-apollo-hooks";
import TutorialsInfoListView from "../../views/Tutorials/TutorialsInfoListView";
import { TUTORIALS_INFO_FEED } from "./gql/Queries";
import auth from "../../utils/auth";
import useGlobal from "../../store";
import history from "../../utils/history";

const TutorialInfoList = () => {
  const [{ session }, ] = useGlobal();
  const [tutorials, setFilterFilter] = useState({
    where: {},
  });

  const { data: dataTutorialInfo, loading: loadingTutorialInfo } = useQuery(
    TUTORIALS_INFO_FEED,
    {
      variables: tutorials,
      fetchPolicy: "network-only",
    }
  );

  /* const filterAction = async (values) => {
        let params = {
          where: values,
          first: 15,
          skip: 0
        }

        setFilterFilter(params);
      } */

  if (
    session.user &&
    !auth.hasPermission("tutorialsView", session.user.role.permissions)
  ) {
    history.push("dashboard");
  }
  return (
    <TutorialsInfoListView
      tutorials={{ loading: loadingTutorialInfo, data: dataTutorialInfo }}
    />
  );
};

export default TutorialInfoList;
