import gql from "graphql-tag";

export const MESSAGE_MUTATION = gql`
  mutation CreateMessage($data: MessagesInput!) {
    createMessage(data: $data) {
      message
    }
  }
`;

export const SEND_EMAIL_MUTATION = gql`
  mutation SendEmail($data: MessagesInput!) {
  sendEmail(data: $data) {
		message
  }
}
`;

export const UPDATE_MESSAGE_MUTATION = gql`
  mutation UpdateMessage($data: MessagesInput! $where: MessageWhereUniqueInput!) {
  updateMessage(data: $data, where: $where) {
		message
  }
}
`;