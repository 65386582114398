import gql from "graphql-tag";

export const CREATE_ACTIVITY = gql`
  mutation CREATE_ACTIVITY($data: ActivityInput!) {
    createActivity(data: $data) {
      _id
      app
      title
      description
      userId
      estimatedDate
      status
      created
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CREATE_COMMENT($data: ActivityCommentInput!) {
    createComment(data: $data) {
      comment
      created
      updated
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation UPDATE_ACTIVITY(
    $data: ActivityInput!
    $where: ActivityUniqueWhereInput
  ) {
    updateActivity(data: $data, where: $where) {
      _id
      app
      title
      description
      userId
      estimatedDate
      status
      created
    }
  }
`;