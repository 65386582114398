import React from 'react';
import history from '../../utils/history';
import { useMutation } from "react-apollo-hooks";
import { UPDATE_TUTORIAL_CATEGORY_MUTATION } from './gql/Mutations';
import useGlobal from "../../store";
import UpdateTutorialCategoryView from '../../views/Tutorials/UpdateTutorialCategoryView';
import auth from '../../utils/auth';

const UpdateTutorialCategory = ({ location }) => {
    const [{session}, globalActions] = useGlobal();
    const { state } = location;
    const updateCategory = useMutation(UPDATE_TUTORIAL_CATEGORY_MUTATION);

    if (
      session.user &&
      !auth.hasPermission("categoryVideoUpdate", session.user.role.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: "No tienes permisos",
        color: "danger",
      });
  
      history.push('/');
    } 

    return (
        <UpdateTutorialCategoryView 
            category={state.category}
            submitSuccess={async (values) => {
                let variables = {
                  data: {
                    ...values
                  },
                  where: {
                    _id: state.category._id
                  }
                  
                }
                
                const resultMutation = await updateCategory({ variables: variables});
                const { loading, error } = resultMutation;

                if (!loading) {
                  if (!error) {
                    history.goBack();
                    // Add success notification
                    globalActions.notification.openNotification(
                      "tr",
                      {
                        message: "Categoria Actualizada",
                        color: "success"
                      }
                    );
                  }else{
                    globalActions.notification.openNotification('tr', {
                        message: 'Error al editar categoria',
                        color: 'danger'
                    });
                  }
                }
              }}
        />
    )
}

export default UpdateTutorialCategory;
