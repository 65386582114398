import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { compose } from "recompose";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Login from "containers/Login";

import PagesHeader from "components/Header/PagesHeader";
import Footer from "components/Footer";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";

function Pages({ t, classes, ...rest }) {
  const [page, setPage] = useState(null);

  useEffect(() => {
    document.body.style.overflow = "unset";

    let { pathname } = rest.location;
    getPage(pathname.replace("/", ""));
  });

  const getPage = type => {
    const pages = {
      login: () => setPage("login"),
      default: () => setPage("login")
    };

    return (pages[type] || pages.default)();
  };

  const renderPage = page => {
    const pages = {
      login: () => <Login {...rest} />,
      default: () => <p>Render default</p>
    };

    return (pages[page] || pages.default)();
  };

  return (
    <div className="Pages">
      <PagesHeader {...rest} />
      <div className={classes.wrapper}>
        <div className={classes.fullPage}>
          {renderPage(page)}
          <Footer white />
        </div>
      </div>
    </div>
  );
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withStyles(pagesStyle),
  withTranslation("tickets")
)(Pages);
