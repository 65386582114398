import gql from "graphql-tag";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      user {
        _id
        firstName
        lastName
        email
        resetPassword
        role{
          _id
          name
          permissions
          master
        }
        franchise
      }
      expiration
      token
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPasswordMutation(
    $data: UserBoPasswordInput!
    $where: UserWhereUniqueInput!
  ) {
    resetPassword(data: $data, where: $where) {
      _id
      firstName
      lastName
      email
    }
  }
`;
