import React from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx"
import { CircularProgress } from '@material-ui/core';
import history from '../../utils/history';
import Button from "components/CustomButtons/Button.jsx";
import TutorialsInfoTable from '../../tables/Tutorials/TutorialsInfoTable';

const TutorialsInfoListView = ({tutorials}) => {
    const { loading, data } = tutorials;
    return (
      <Card>
        <CardHeader>
          <div style={styles.right}>
            <Button
              size="sm"
              color="info"
              onClick={() => history.push(`/tutorials/tutorial/new`)}
            >
              Nuevo Tutorial
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <div style={styles.progressCnt}>
              <CircularProgress style={styles.progress} />
            </div>
          ) : (
            <TutorialsInfoTable
              tutorials={data.getAllTutorialInfo ? data.getAllTutorialInfo : []}
            />
          )}
        </CardBody>
      </Card>
    );
}

const styles = {
  progress: {
    margin: "16px"
  },
  progressCnt: {
    width: "100px !important",
    marginLeft: "50%",
    marginRight: "50%"
  },
  right: {
    float: "right"
  }
};

export default TutorialsInfoListView;