import Home from "../containers/Home";
import Pages from "../containers/Pages";
import RecoverPassword from "../containers/Users/RecoverPassword";

const indexRoutes = [
  { path: "/login", name: "Pages", component: Pages, public: true },
  { path: "/resetPassword/:id", name: "Password", component: RecoverPassword, public: true },
  { path: "/", name: "Home", component: Home},
];

export default indexRoutes;
