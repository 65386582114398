import React from 'react'
import { useTranslation } from "react-i18next";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import EditDemoForm from '../../forms/Instances/EditDemoForm';
import EditOpportunityForm from '../../forms/Instances/EditOpportunityForm';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from 'components/Button';
import history from '../../utils/history';

const InstanceForm = props => {
  const { instanceType, instance, cities, submitSuccess, updateAppToken, archivedAllappTokens, paginationState } = props;
  switch (instanceType) {
    case "demos":
      return (
        <EditDemoForm
          archivedAllappTokens={archivedAllappTokens}
          updateAppToken={updateAppToken}
          instanceType={instanceType}
          instance={instance}
          cities={cities}
          submitSuccess={submitSuccess}
          paginationState={paginationState}
        />
      );
    case "opportunity":
      return (
        <EditOpportunityForm
          archivedAllappTokens={archivedAllappTokens}
          updateAppToken={updateAppToken}
          instanceType={instanceType}
          instance={instance}
          cities={cities}
          submitSuccess={submitSuccess}
          paginationState={paginationState}
        />
      );
    case "subscription":
      break;
  }
};


const EditInstanceView = 
(props) => {
    const [t,] = useTranslation('instances');
    const { instanceType, instance, cities, submitSuccess, updateAppToken, archivedAllappTokens, paginationState} = props;

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <h2>{t("instances.edit.title")}</h2>
          </GridItem>
          <GridItem xs={12}>
              <Button
                simple
                size="lg"
                color="info"
                onClick={() => history.push(`/instances/${instanceType}`, { paginationState: paginationState })}
                className={styles.buttonNoMargin}
              >
              <ArrowBackIcon/><span>{t('instances.goBackInstance')}</span>
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <InstanceForm
              archivedAllappTokens={archivedAllappTokens}
              updateAppToken={updateAppToken}
              instanceType={instanceType}
              instance={instance}
              cities={cities}
              submitSuccess={submitSuccess}
              paginationState={paginationState}
            />
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
}

const styles = {
  buttonNoMargin:{
    padding: "10px 0px"
  },
}

export default EditInstanceView;
