import React, { useEffect, useState } from "react";
import AddPlanInstanceView from "../../views/PlansView/AddPlanInstanceView";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import useGlobal from "../../store";
import { PLANS_FEED } from "./gql/Queries";
import { ADD_PLAN_TO_INSTANCE } from "./gql/Mutations";
import history from "../../utils/history";
import _ from "lodash";
import auth from "../../utils/auth";

const AddPlanInstance = ({ match, location }) => {
  const instanceId = match?.params?.app;
  const instance = location?.state?.instance;

  const [{ session }, globalActions] = useGlobal();
  const [disabled, setDisabled] = useState(false);

  const [filters] = useState({
      where: {
          archived: false
      }
  });

  const [getPlans, { data: dataPlans, loading: loadingPlans }] =
    useLazyQuery(PLANS_FEED);

    const [addPlanInstance] = useMutation(ADD_PLAN_TO_INSTANCE, {
      onCompleted: (result) => {
        globalActions.notification.openNotification("tr", {
          message: "Plan agregado",
          color: "success",
        });

        //history.goBack();
      },
      onError: (error) => {
        globalActions.notification.openNotification("tr", {
          message: "Error al agregar plan",
          color: "danger",
        });
      },
    }); 

  useEffect(() => {
    if (session.user && auth.hasPermission("planToInstanceView", session.user.role.permissions)) {
      getPlans({ variables: filters });
      setDisabled(!auth.hasPermission("planToInstanceUpdate", session.user.role.permissions));
    }else{
      history.goBack();
    }
  }, [session]);

  

  return (
    <AddPlanInstanceView
      disabled={disabled}
      plans={{
        dataPlans: dataPlans?.plans ? dataPlans.plans : [],
        loading: loadingPlans,
      }}
      instance={instance}
      addPlanToInstance={(values) => {

        let variables = {
          data: {
              planId: values.planId._id,
              customPlanFeatures: values.customPlanFeatures
          },
          where: {
            _id: instanceId
          }
        };

        addPlanInstance({ variables: variables });
      }}
    />
  );
};

export default AddPlanInstance;
