import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import history from "./utils/history";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import apolloClient from "./utils/apolloClient";
import "assets/scss/material-dashboard-pro-react.css";
import "react-table/react-table.css"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import "./i18n";

const MOUNT_NODE = document.getElementById("root");
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={apolloClient}>
      <ApolloHooksProvider client={apolloClient}>
        <App />
      </ApolloHooksProvider>
    </ApolloProvider>
  </Router>,
  MOUNT_NODE
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();