import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useTranslation } from "react-i18next";
import { CircularProgress } from '@material-ui/core';
import RolesTable from '../../tables/Users/RolesTable';

const RolesView = (props) => {
    const { t } = useTranslation("users");
    const {roles, loading} = props;

    return (
        <GridContainer justify="center">
        <GridItem xs={8}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <GroupAddIcon />
              </CardIcon>
              <h4>{t("users.roles.title")}</h4>
            </CardHeader>
            <CardBody>
              {loading ? (
                <div style={styles.progressCnt}>
                  <CircularProgress style={styles.progress} />
                </div>
              ) : (
                <RolesTable 
                    roles={roles && roles.rolesBo ? roles.rolesBo : []}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
}

const styles = {
    progressCnt: {
      width: "100px !important",
      margin: "auto",
    },
    progress: {
      margin: "16px",
    },
  };

export default RolesView;
