import React, { useState } from "react";
import GeofenceRecordView from "../../views/Geofences/GeofenceRecordView";
import { GEOFENCE_RECORD } from "./gql/Queries";
import { useQuery, useMutation } from "react-apollo-hooks";
import { UPDATE_GEOFENCE_RECORD_MUTATION } from "./gql/Mutations";
import useGlobal from "../../store";
import auth from '../../utils/auth';
import history from "../../utils/history";

const GeofenceRecord = (props) => {
  const [filters] = useState({
    archived: false,
  });

  const { data, loading } = useQuery(GEOFENCE_RECORD, {
    variables: {
      where: filters,
    },
    fetchPolicy: "network-only",
  });
  const updateGeofenceRecord = useMutation(
    UPDATE_GEOFENCE_RECORD_MUTATION
  );
  const [{session}, globalActions] = useGlobal();

  if (
    session.user &&
    !auth.hasPermission("geofenceBuy", session.user.role.permissions)
  ) {
    history.push('dashboard');
  }

  return (
    <GeofenceRecordView
      geofences={data}
      loading={loading}
      updatedGeofence={async (geofence) => {
        let variables = {
          data: {
            ...geofence,
            paymentGeofenceGroup: geofence.paymentGeofenceGroup._id,
            activated: !geofence.activated,
          },
          where: {
            _id: geofence._id,
          },
        };
        delete variables.data._id;
        delete variables.data.appRef;

        const resultMutation = await updateGeofenceRecord({
          variables: variables,
        });

        if (!resultMutation.loading) {
          if (!resultMutation.error) {
            window.location.reload();
            // Add success notification
            globalActions.notification.openNotification("tr", {
              message: "Geocerca Actualizada",
              color: "success",
            });
          } else {
            globalActions.notification.openNotification("tr", {
              message: "Error al actualizar geocerca",
              color: "danger",
            });
          }
        }
      }}
    />
  );
};

export default GeofenceRecord;
