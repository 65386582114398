import session from '../utils/session';
import {CHECK_SESSION} from "../containers/App/Queries";
import history from '../utils/history';


export const signin = async (store, sessionResult) => {
  await store.setState({ session: sessionResult });
  await session.setAuthToken(sessionResult.token);
  history.push("/");
};

export const logout = async (store) => {
  await store.setState({ session: {user: null} });
  await session.clearAuthToken();
  history.push("login");
};

export const checkSession = async (store, client) => {
  try {
    if (session.isAuthenticated()){
      const token = await session.getAuthToken();

      const {data} = await client.query({
        query: CHECK_SESSION,
        variables: {
          token: token
        }
      });
      const sessionResult = data.checkSession;
      if (sessionResult) {
        /* signin(store, sessionResult); */
        await store.setState({ session: sessionResult });
        await session.setAuthToken(sessionResult.token);
      }else{
        logout(store);
      }
    }
  }catch (e) {
    console.log(e);
  }
};
