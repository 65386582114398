import React from 'react';
import { useMutation } from "react-apollo-hooks";
import useGlobal from "../../store";
import TutorialCategoryView from '../../views/Tutorials/TutorialCategoryView';
import { CREATE_TUTORIAL_CATEGORY_MUTATION } from './gql/Mutations';
import history from '../../utils/history';
import auth from '../../utils/auth';

const TutorialCategory = () => {
  const [{ session }, globalActions] = useGlobal();
  const createCategory = useMutation(
    CREATE_TUTORIAL_CATEGORY_MUTATION
  );

  if (
    session.user &&
    !auth.hasPermission("categoryVideoCreate", session.user.role.permissions)
  ) {
    history.push("dashboard");
  }

  return (
    <TutorialCategoryView
      submitSuccess={async (values) => {
        let variables = {
          data: {
            ...values,
          },
        };

        const resultMutation = await createCategory({ variables: variables });
        const { loading, error } = resultMutation;

        if (!loading) {
          if (!error) {
            // Add success notification
            globalActions.notification.openNotification("tr", {
              message: "Categoria creada",
              color: "success",
            });
            history.push(`/tutorials/categoriesList`);
          } else {
            globalActions.notification.openNotification("tr", {
              message: "Error al crear categoria",
              color: "danger",
            });
          }
        }
      }}
    />
  );
}

export default TutorialCategory;
