import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import logo from "assets/img/bq_mobility_large.png";
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useTranslation } from "react-i18next";
import CardIcon from "components/Card/CardIcon.jsx";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ResetPassowrdForm from '../../forms/Users/ResetPassowrdForm';

const ResetPasswordView = (props) => {
    const { classes, submitSuccess } = props;
    const { t } = useTranslation("users");

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={8}>
            <img style={styles.logo} src={logo} alt="logo" />
          </GridItem>
          <GridItem xs={8}>
            <Card>
              <CardHeader>
                <CardIcon color="info">
                  <VpnKeyIcon />
                </CardIcon>
                <h4>{t("users.passwordTitle")}</h4>
              </CardHeader>
              <CardBody>
                  <ResetPassowrdForm submitSuccess={submitSuccess} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
}


const styles = {
    logo: {
        width: '100%',
    }
}

export default withStyles(loginPageStyle)(ResetPasswordView);