import React from 'react';
import ReactTable from "react-table";
import ButtonIcon from "../../components/styled/ButtonIcon";
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const AdminsTable = (props) => {
    const { t } = useTranslation('instances')
    const { data } = props;

    return (
      <ReactTable
        data={data}
        filterable
        columns={[
          {
            Header: t("instances.users.firstname"),
            accessor: "firstName",
            filterable: false,
            sortable: false,
            maxWidth: 200
          },
          {
            Header: t("instances.users.lastname"),
            accessor: "lastName",
            filterable: false,
            sortable: false,
            maxWidth: 200
          },
          {
            Header: t("instances.users.email"),
            accessor: "email",
            filterable: false,
            sortable: false,
            maxWidth: 200
          },
          {
            id: "role",
            Header: t("instances.forms.fields.role.label"),
            style: { textAlign: "center" },
            Cell: (row) => { 
              return row.original.access[0].roleName;
            },
            filterable: false,
            sortable: false,
            maxWidth: 200
          },
        ]}
        defaultPageSize={15}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    );
}

AdminsTable.prototype = {
  data: PropTypes.object
}

export default AdminsTable;