import React from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import NewPartnerView from '../../views/Partners/NewPartnerView';
import { CITIES } from '../Instances/gql/Queries';
import history from '../../utils/history';
import useGlobal from "../../store";
import { UPDATED_PARTNER } from './gql/Mutations';
import auth from '../../utils/auth';

const EditPartner = (props) => {
    const { location, match } = props;
    const { partner } = location.state;
    const [{session}, globalActions] = useGlobal();
    const updatePartner = useMutation(UPDATED_PARTNER);
    const { data } = useQuery(CITIES);

    if (
      session.user &&
      !auth.hasPermission("partnerUpdate", session.user.role.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: "No tienes permisos",
        color: "danger",
      });
  
      history.push('/');
    } 

    return (
      <NewPartnerView
        partner={partner}
        cities={data && data.cities ? data.cities : []}
        submitSuccess={async (values) => {
          let file = values.logo;
          delete values.logo;
          delete values.logos;
          delete values._id;

          let variables = {
            data: {
              ...values,
            },
            where:{
                _id: match.params.id
            }
          };

          if (typeof file === 'object') {
            variables.file = file[file.length -1];
          }

          const mutationResult = await updatePartner({
            variables: variables,
          });

          const { loading, error } = mutationResult;

          if (!loading) {
            if (!error) {
              history.push("/partners/list");
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: "Partner Actualizado",
                color: "success",
              });
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al actualizar partner",
                color: "danger",
              });
            }
          }
        }}
      />
    );
}

export default EditPartner;
