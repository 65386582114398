import gql from "graphql-tag";

export const PLANS_FEED = gql`
  query plans($where: PlanWhereInput!){
    plans(where: $where) {
        _id
        name
        description
        features {
            _id
            name
            type
            value
        }
    }
    }
`;