import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import WarningIcon from '@material-ui/icons/Warning';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const WarningChangePlan = (props) => {
    const { openModal, setOpenModal, confirm} = props;
    
    return (
      <Dialog
        open={openModal}
        keepMounted
        onClose={setOpenModal}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="lert-dialog-title">
          <GridContainer>
            <GridItem xs={2}>
              <WarningIcon style={styles.icon} />
            </GridItem>
            <GridItem xs={10}>
              <h4>Advertencia</h4>
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si seleccionas otro plan modificara la configuracacion previa.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModal(false);
              confirm(false);
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenModal(false);
              confirm(true);
            }}
            color="primary"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
}

const styles = {
    icon: {
        fontSize: '40px',
        textAlign: 'center',
        marginLeft: '50%',
        marginRight: '50%',
        marginTop: '10%'
    }
}

export default WarningChangePlan;
