import React, { useState } from 'react'
import GeofencesGroupView from '../../views/Geofences/GeofencesGroupView';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { GEOFENCE_GROUP } from './gql/Queries';
import { CREATE_GEOFENCE_GROUP_MUTATION, UPDATE_GEOFENCE_GROUP_MUTATION } from './gql/Mutations';
import history from '../../utils/history';
import useGlobal from "../../store";
import auth from '../../utils/auth';

const GeofenceGroup = () => {
    const [{session}, globalActions] = useGlobal();

    const [filters, ] = useState({
        archived: false
    });

    const createGeofenceGroup = useMutation(CREATE_GEOFENCE_GROUP_MUTATION);
    const updateGeofenceGroup = useMutation(UPDATE_GEOFENCE_GROUP_MUTATION);

    const { data, loading } = useQuery(GEOFENCE_GROUP, {
        variables: {
          where: filters,
          orderBy: 'created_ASC'
        },
        fetchPolicy: "network-only"
      });

      if (
        session.user &&
        !auth.hasPermission("geofenceView", session.user.role.permissions)
      ) {
        history.push('dashboard');
      } 

      return (
        <GeofencesGroupView
          onSubmit={async (values) => {
            const variables = {
              data: {
                ...values,
              },
            };

            const resultMutation = await createGeofenceGroup({
              variables: variables,
            });

            if (!resultMutation.loading) {
              if (!resultMutation.error) {
                history.push(
                  `/geofence-group/edit/${resultMutation.data.createGeofenceGroup._id}`
                );
                // Add success notification
                globalActions.notification.openNotification("tr", {
                  message: "Nuevo grupo de geocercas",
                  color: "success",
                });
              } else {
                globalActions.notification.openNotification("tr", {
                  message: "Error al crear geocerca",
                  color: "danger",
                });
              }
            }
          }}
          updateGeofence={async (geofence) => {
            let variables = {
              data: {
                ...geofence,
                archived: true,
              },
              where: {
                _id: geofence._id,
              },
            };

            delete variables.data._id;

            const resultMutation = await updateGeofenceGroup({
              variables: variables,
            });

            if (!resultMutation.loading) {
              if (!resultMutation.error) {
                window.location.reload();
                // Add success notification
                globalActions.notification.openNotification("tr", {
                  message: "Geocerca archivada",
                  color: "success",
                });
              } else {
                globalActions.notification.openNotification("tr", {
                  message: "Error al archivar geocerca",
                  color: "danger",
                });
              }
            }
          }}
          geofences={data}
          loading={loading}
        />
      );
}

export default GeofenceGroup;
