import React from 'react'
import AddGeofenceView from '../../views/Geofences/AddGeofenceView';
import { useMutation } from 'react-apollo-hooks';
import { UPDATE_GEOFENCE_MUTATION, CREATE_GEOFENCE_MUTATION, UPDATE_GEOFENCE_GROUP_MUTATION } from './gql/Mutations';
import useGlobal from "../../store";
import history from '../../utils/history';

const AddGeofence = (props) => {
    const { location } = props;
    const { state } = location;
    const updateGeofence = useMutation(UPDATE_GEOFENCE_MUTATION);
    const createGeofence = useMutation(CREATE_GEOFENCE_MUTATION);
    const updateGeofenceGroup = useMutation(UPDATE_GEOFENCE_GROUP_MUTATION);
    const [, globalActions] = useGlobal();

    return (
        <AddGeofenceView
            geofence={state}
            onSubmit={async (values) => {
                let variables = {
                    data: {
                        ...values
                    }
                };

                if (state.geofence) {
                    variables.where = {
                        _id: state.geofence._id
                    }
                    const resultMutation = await updateGeofence({ variables: variables });

                    if (!resultMutation.loading) {
                        if (!resultMutation.error) {
                          history.push(`/geofence-group/edit/${state.geofence.paymentGeofenceGroup}`);
                          // Add success notification
                          globalActions.notification.openNotification(
                            "tr",
                            {
                              message: "Geocerca Actualizada",
                              color: "success"
                            }
                          );
                        }else{
                          globalActions.notification.openNotification('tr', {
                              message: 'Error al actualizar geocerca',
                              color: 'danger'
                          });
                        }
                      }
                }else{
                    variables.data.paymentGeofenceGroup = state.geofenceGroup._id;
                    const resultMutation = await createGeofence({ variables: variables });
                    if (!resultMutation.loading) {
                        if (!resultMutation.error) {

                            let groupGeofence = { ...state.geofenceGroup };
                            groupGeofence.geofences.push({_id: resultMutation.data.createGeofence._id})
                            const resultGeofenceGroupMutation = await updateGeofenceGroup({ variables: {
                                data: {
                                    name: groupGeofence.name,
                                    geofences: groupGeofence.geofences,
                                    archived: groupGeofence.archived,
                                    created: groupGeofence.created
                                },
                                where:{
                                    _id: groupGeofence._id
                                }
                            } });

                          history.push(`/geofence-group/edit/${state.geofenceGroup._id}`);
                          // Add success notification
                          globalActions.notification.openNotification(
                            "tr",
                            {
                              message: "Geocerca Agregada",
                              color: "success"
                            }
                          );
                        }else{
                          globalActions.notification.openNotification('tr', {
                              message: 'Error al agregar geocerca',
                              color: 'danger'
                          });
                        }
                      }
                }
            }}
        />
    )
}

export default AddGeofence;
