import React, { useState } from "react";
import PartnersView from "../../views/Partners/PartnersView";
import { useMutation, useQuery } from "react-apollo-hooks";
import { PARTNERS } from "./gql/Queries";
import { UPDATED_PARTNER } from "./gql/Mutations";
import useGlobal from "../../store";
import auth from '../../utils/auth';
import history from "../../utils/history";

const Partners = (props) => {
  const [{session}, globalActions] = useGlobal();

  const [filters, ] = useState({
    archived: false,
    isPartner: true
  });

  const [pagination, setPagination] = useState({
    first: 15,
    skip: 0,
  });

  const { data, loading } = useQuery(PARTNERS, {
    variables: {
      where: filters,
      ...pagination
    },
    fetchPolicy: "network-only",
  });

  async function paginationAction(values) {
    setPagination(values);
  }

  const updatePartner = useMutation(UPDATED_PARTNER);

  if (
    session.user &&
    !auth.hasPermission("partnerView", session.user.role.permissions)
  ) {
    history.push('dashboard');
  }

  return (
    <PartnersView
      loading={loading}
      partners={data}
      paginationAction={paginationAction}
      paginationOptions={pagination}
      archivedPartner={async (partner) => {
        delete partner.logos;
        const id = partner._id;
        delete partner._id;

        let variables = {
          data: {
            ...partner,
            archived: true,
          },
          where: {
            _id: id,
          },
        };

        const mutationResult = await updatePartner({
          variables: variables,
        });

        if (!mutationResult.loading) {
          if (!mutationResult.error) {
            window.location.reload();
            // Add success notification
            globalActions.notification.openNotification("tr", {
              message: "Partner Actualizado",
              color: "success",
            });
          } else {
            globalActions.notification.openNotification("tr", {
              message: "Error al actualizar partner",
              color: "danger",
            });
          }
        }
      }}
    />
  );
};

export default Partners;
