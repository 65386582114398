import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CategoryFormWF from '../../forms/Tutorials/CategoryForm';

const TutorialCategoryView = ({submitSuccess}) => {
    return (
        <GridContainer justify="center">
        <GridItem xs={8}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <AssignmentTurnedInIcon />
              </CardIcon>
              <h4>Nueva categoria</h4>
            </CardHeader>
            <CardBody>
                <CategoryFormWF  submitSuccess={submitSuccess} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
}

export default TutorialCategoryView;