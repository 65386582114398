import React, { useState } from "react";
import _ from "lodash";
import { compose, withProps, lifecycle } from "recompose";
import { Field } from "formik";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import SelectTimezone, { getTimezoneProps } from "@capaj/react-select-timezone";
import SelectTo from 'react-select';


import { APP_CATEGORIES, APP_TYPES, APP_STATUS, FIREBASE_PROJECTS } from "../../../config/constants";
import { useTranslation } from "react-i18next";

//Utils
import { hasError } from "../../../utils/forms/inputs";
import ButtonIcon from "../../../components/styled/ButtonIcon";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { TextField } from "@material-ui/core";

const GoogleMaps = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDe9IxqJ9JxwmqtVVeKpJozUpNXytWLgLw&v=3&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};
      const { setFieldValue, location } = this.props;
      this.setState({
        bounds: null,
        center: {
          lat: location.coordinates[1],
          lng: location.coordinates[0]
        },
        markers: [
          {
            position: {
              lat: location.coordinates[1],
              lng: location.coordinates[0]
            }
          }
        ],
        onMapMounted: ref => {
          refs.map = ref;
        },
        onMapClick: e => {
          const nextMarkers = [{ position: e.latLng }];

          this.setState({
            markers: nextMarkers
          });

          setFieldValue("location", {
            coordinates: [
              nextMarkers[0].position.lng(),
              nextMarkers[0].position.lat()
            ]
          });
          
        },
        onBoundsChanged: _.debounce(() => {
          if (refs.map) {
            this.setState({
              bounds: refs.map.getBounds(),
              center: refs.map.getCenter()
            });
          }
          
        },100,{ maxWait: 500 }),
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location
          }));

          const nextCenter = _.get(
            nextMarkers,
            "0.position",
            this.state.center
          );

          setFieldValue("location", {
            coordinates: [
              nextMarkers[0].position.lng(),
              nextMarkers[0].position.lat()
            ]
          });

          this.setState({
            center: nextCenter,
            markers: nextMarkers
          });
          // refs.map.fitBounds(bounds);
        }
      });
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={15}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}
    onClick={props.onMapClick}
  >
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder={props.placeholder}
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `32px`,
          marginTop: `20px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`
        }}
      />
    </SearchBox>
    {props.markers.map((marker, index) => (
      <Marker key={index} position={marker.position} />
    ))}
  </GoogleMap>
));

const InfoInstance = props => {
  const {
    classes,
    errors,
    touched,
    setFieldValue,
    cities,
    editOpportunity,
    values,
    updateAppToken
  } = props;

  const { t } = useTranslation("instances");
  const [projectFB, setProjectFB] = useState(
    values && values.firebaseProject ? values.firebaseProject : 0
  );
  
  return (
    <GridContainer>
      <GridItem xs={6}>
        <Field
          name="name"
          render={({ field }) => {
            return (
              <CustomInput
                labelText={t("instances.forms.fields.name.placeholder")}
                id="name"
                type={"text"}
                success={!hasError(errors, "name") && touched.name}
                error={hasError(errors, "name")}
                formControlProps={{
                  fullWidth: true,
                  required: true
                }}
                inputProps={{
                  ...field
                }}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel className={classes.selectLabel}>
            {t("instances.forms.fields.appType.placeholder")}
          </InputLabel>
          <Field
            name="appType"
            render={({ field }) => {
              return (
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  onChange={(e) => {}}
                  inputProps={{ ...field }}
                >
                  {Object.keys(APP_TYPES).map((appType) => (
                    <MenuItem
                      disabled
                      classes={{ root: classes.selectMenuItem }}
                      value={APP_TYPES[appType]}
                      key={APP_TYPES[appType]}
                    >
                      {t(`instances.forms.fields.appType.list.${appType}`)}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem xs={6}>
        <Field
          name="address"
          render={({ field }) => {
            return (
              <CustomInput
                labelText={t("instances.forms.fields.address.placeholder")}
                id="address"
                type={"text"}
                success={!hasError(errors, "address") && touched.name}
                error={hasError(errors, "address")}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{ ...field }}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel className={classes.selectLabel}>
            {t("instances.forms.fields.category.placeholder")}
          </InputLabel>
          <Field
            name="category"
            render={({ field }) => {
              return (
                <Select
                  id={!hasError(errors, "category") ? "demo-simple-select": 'demo-simple-select-error'}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  onChange={(e) => {
                    console.log(e);
                  }}
                  inputProps={{ ...field }}
                >
                  {Object.keys(APP_CATEGORIES).map((category) => (
                    <MenuItem
                      classes={{ root: classes.selectMenuItem }}
                      value={APP_CATEGORIES[category]}
                      key={APP_CATEGORIES[category]}
                    >
                      {t(`instances.forms.fields.category.list.${category}`)}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem xs={6}>
        <Field
          name="phone"
          render={({ field }) => {
            return (
              <CustomInput
                labelText={t("instances.forms.fields.phone.placeholder")}
                id="phone"
                type={"text"}
                success={!hasError(errors, "phone") && touched.name}
                error={hasError(errors, "phone")}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...field,
                }}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={6}>
        <Field
          name="description"
          render={({ field }) => {
            return (
              <CustomInput
                labelText={t("instances.forms.fields.description.placeholder")}
                id="description"
                type={"textarea"}
                success={!hasError(errors, "description") && touched.name}
                error={hasError(errors, "description")}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...field,
                }}
              />
            );
          }}
        />
      </GridItem>
      {values && values.token ? (
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={6}>
              <Field
                name="token"
                render={({ field }) => {
                  return (
                    <CustomInput
                      labelText={t("instances.forms.fields.token.label")}
                      id="token"
                      desable
                      type={"text"}
                      formControlProps={{
                        fullWidth: true,
                        disabled: true,
                      }}
                      inputProps={{
                        ...field,
                      }}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={3}>
              <ButtonIcon
                icon={AutorenewIcon}
                onClick={updateAppToken}
                color={"info"}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      ) : null}
      {values && values.token ? (
        <GridItem xs={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel className={classes.selectLabel}>
              {t("instances.forms.fields.firebaseProject.label")}
            </InputLabel>
            <Field
              name="firebaseProject"
              render={({ field }) => {
                return (
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={projectFB}
                    onChange={(option) => {
                      setFieldValue('firebaseProject', option.target.value);
                      setProjectFB(option.target.value)
                    }}
                    inputProps={{ ...field }}
                  >
                    {FIREBASE_PROJECTS.map((project) => (
                      <MenuItem
                        classes={{ root: classes.selectMenuItem }}
                        value={project.id}
                        key={project.id}
                      >
                        {!project.id ? t("instances.forms.fields.firebaseProject.options.select") : project.name}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        </GridItem>
      ) : null}
      <GridItem xs={12}>
        <Field
          name="webPage"
          render={({ field }) => {
            return (
              <CustomInput
                labelText={t("instances.forms.fields.webpage.placeholder")}
                id="webpage"
                type={"text"}
                success={!hasError(errors, "webPage") && touched.name}
                error={hasError(errors, "webPage")}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...field,
                }}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={6}>
        <Field
          name="maxAppUsers"
          render={({ field }) => {
            return (
              <TextField
              style={{marginTop: '10px'}}
                desable
                label={t("instances.forms.fields.noUsers.placeholder")}
                id="maxAppUsers"
                type={"number"}
                formControlProps={{
                  fullWidth: true,
                  min: 0,
                  disabled: (values && values.instanceType === 'Subscription' ? false : true),
                }}
                inputProps={{
                  ...field,
                }}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={6}>
        <Field
          name="instanceStatus"
          render={({ field }) => {
            if (editOpportunity) {
              return (
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t("instances.forms.fields.instanceStatus.placeholder")}
                  </InputLabel>
                  <Select
                    id={!hasError(errors, "instanceStatus") ? "demo-simple-select": 'demo-simple-select-error'}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    inputProps={{ ...field }}
                  >
                    <MenuItem
                      classes={{ root: classes.selectMenuItem }}
                      value={APP_STATUS["DEMO"]}
                      key={APP_STATUS["DEMO"]}
                    >
                      {t(`instances.status.DEMO`)}
                    </MenuItem>
                    <MenuItem
                      classes={{ root: classes.selectMenuItem }}
                      value={APP_STATUS["APPROACH"]}
                      key={APP_STATUS["APPROACH"]}
                    >
                      {t(`instances.status.APPROACH`)}
                    </MenuItem>
                    <MenuItem
                      classes={{ root: classes.selectMenuItem }}
                      value={APP_STATUS["PRESALE"]}
                      key={APP_STATUS["PRESALE"]}
                    >
                      {t(`instances.status.PRESALE`)}
                    </MenuItem>
                    <MenuItem
                      classes={{ root: classes.selectMenuItem }}
                      value={APP_STATUS["ACTIVE"]}
                      key={APP_STATUS["ACTIVE"]}
                    >
                      {t(`instances.status.ACTIVE`)}
                    </MenuItem>
                  </Select>
                </FormControl>
              );
            } else {
              return (
                <CustomInput
                  desable
                  labelText={t(
                    "instances.forms.fields.instanceStatus.placeholder"
                  )}
                  id="instanceStatus"
                  type={"text"}
                  formControlProps={{
                    fullWidth: true,
                    disabled: true,
                  }}
                  inputProps={{
                    ...field,
                  }}
                />
              );
            }
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <Field
          name="requirementDescription"
          render={({ field }) => {
            return (
              <CustomInput
                labelText={t(
                  "instances.forms.fields.requirementDescription.placeholder"
                )}
                id="requirementDescription"
                type={"text"}
                success={
                  !hasError(errors, "requirementDescription") && touched.name
                }
                error={hasError(errors, "requirementDescription")}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...field,
                }}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <Field
          name="city"
          render={({ field }) => {
            return (
              <SelectTo
                value={field.value}
                onChange={(selectedOption) =>
                  setFieldValue("city", selectedOption)
                }
                options={cities ? cities.cities : []}
                getOptionLabel={(option) => option.cityName}
                getOptionValue={(option) => option}
              />
            );
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <Field
          name="timezone"
          render={({ field }) => {
            return (
              <SelectTimezone
                value={field.value.zone}
                isClearable
                guess
                onChange={(val) => {
                  const timezoneProps = getTimezoneProps(val);
                  setFieldValue("timezone", {
                    zone: val,
                    utc: timezoneProps.time,
                  });
                }}
              />
            );
          }}
        />
      </GridItem>

      <GridItem xs={12}>
        <Field
          name="location"
          render={({ field }) => {
            return (
              <GoogleMaps
                placeholder={t("instances.search")}
                setFieldValue={setFieldValue}
                location={field.value}
              />
            );
          }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default compose(withStyles(extendedFormsStyle))(InfoInstance);
