import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import NewTaskForm from '../forms/ToDoList/NewTaskForm';

const NewTaskModal = ({ openModal, setOpenModal, app, userId, submitSuccess }) => {
    const { t } = useTranslation('todolist');
    return (
      <Dialog
        open={openModal}
        keepMounted
        onClose={setOpenModal}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="lert-dialog-title">
          <GridContainer>
            <GridItem xs={2}>
              <PlaylistAddIcon style={styles.icon} />
            </GridItem>
            <GridItem xs={10}>
              <h4>{t("todolist.modal.newTask.title")}</h4>
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <NewTaskForm
          app={app}
          userId={userId}
          setOpenModal={setOpenModal}
          submitSuccess={submitSuccess}
        />
      </Dialog>
    );
}

const styles = {
    icon: {
        fontSize: '40px',
        textAlign: 'center',
        marginLeft: '50%',
        marginRight: '50%',
        marginTop: '10%'
    }
}

export default NewTaskModal;
