import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import { NavLink } from "react-router-dom";
import cx from "classnames";

const LinksMenu = ({
  classes,
  openCollapse,
  routes,
  activeRoute,
  miniActiveProps,
  miniActiveState,
  isRouteActive,
  color,
  user
}) => (
  <List className={classes.list} id={'links-menu'}>
    {routes.map((prop, key) => {
      if (prop.permission) {
        if(user && user.role){
          const permission = user.role.permissions.find(permission => permission === prop.permission);
          if (!permission) {
            return null;
          }
        }
      }
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: activeRoute(prop.path)
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]: miniActiveProps && miniActiveState
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]: miniActiveProps && miniActiveState
          });
        const itemIcon = classes.itemIcon;
        const caret = classes.caret;
        return (
          <ListItem key={key} className={classes.item} id={prop.id}>
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={() => openCollapse(prop.state)}
            >
              <ListItemIcon className={itemIcon}>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <prop.icon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (isRouteActive(prop.state) ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={itemText}
              />
            </NavLink>
            <Collapse in={isRouteActive(prop.state)} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {prop.views.map((prop, key) => {
                  if (prop.permission) {
                    if(user && user.role){
                      const permission = user.role.permissions.find(permission => permission === prop.permission);
                      if (!permission) {
                        return null;
                      }
                    }
                  }
                  if(prop.show){
                    if (prop.redirect) {
                      return null;
                    }
                    const navLinkClasses =
                      classes.collapseItemLink +
                      " " +
                      cx({
                        [" " + classes[color]]: activeRoute(prop.path)
                      });
                    const collapseItemMini = classes.collapseItemMini;

                    return (
                      <ListItem key={key} className={classes.collapseItem} id={prop.id} button>
                        <NavLink to={prop.path} className={navLinkClasses}>
                          <span className={collapseItemMini}>{prop.mini}</span>
                          <ListItemText
                            primary={prop.name}
                            disableTypography={true}
                            className={collapseItemText}
                          />
                        </NavLink>
                      </ListItem>
                    );
                  } else {
                    return null;
                  }

                })}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: activeRoute(prop.path)
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            miniActiveProps && miniActiveState
        });
      const itemIcon =
        classes.itemIcon
      return (
        <ListItem key={key} className={classes.item} button>
          <NavLink to={prop.path} className={navLinkClasses}>
            <ListItemIcon className={itemIcon}>
              {typeof prop.icon === "string" ? (
                <Icon>{prop.icon}</Icon>
              ) : (
                <prop.icon />
              )}
            </ListItemIcon>
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={itemText}
            />
          </NavLink>
        </ListItem>
      );
    })}
  </List>
);

export default LinksMenu;
