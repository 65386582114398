import gql from "graphql-tag";

export const ACTIVITY_INDICATORS = gql`
  query ACTIVITY_INDICATORS($first: Int!, $skip: Int!, $where: IndexesInput!){
    indexesConnection(first: $first, skip: $skip, where: $where){
      totalCount
      edges{
        name
        instanceStatus
        instanceType
        created
        numUsersMobile
        numSegments
        numAdmins
        numScreens
        numModules
        #numActiveVisits
        #numClosedVisits
        #numQuestionEngineSend
        #numReportsDownloadUser
        #numReportautoSend
        instanceStatus
        instanceType
      }
      pageInfo{
        hasNextPage
        hasPreviousPage
      }
    }
  }  
`;
