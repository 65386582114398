import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { useTranslation } from "react-i18next";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import UserForm from '../../forms/Users/UserForm';
import EditUserForm from '../../forms/Users/EditUserForm';

const UserView = (props) => {
    const { t } = useTranslation("users");
    const { roles, submitSuccess, user, partners } = props;

    return (
      <GridContainer justify="center">
        <GridItem xs={6}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <GroupAddIcon />
              </CardIcon>
              <h4>{t("users.title")}</h4>
            </CardHeader>
            <CardBody>
              {user._id ? (
                <EditUserForm
                  partners={partners}
                  user={user}
                  roles={roles}
                  submitSuccess={submitSuccess}
                />
              ) : (
                <UserForm
                  partners={partners}
                  user={user}
                  roles={roles}
                  submitSuccess={submitSuccess}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

export default UserView;
