import React from "react";
import { useMutation, useQuery } from "react-apollo-hooks";
import ResetPasswordView from "../../views/Users/ResetPasswordView";
import useGlobal from "../../store";
import { RESET_PASSWORD_MUTATION } from "../Login/Mutations";
import history from "../../utils/history";
import { USER_BO } from "./gql/Queries";

const RecoverPassword = (props) => {
  const { match } = props;
  const [, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const { data, loading } = useQuery(USER_BO, {
    variables: {
      where: { _id: match.params.id },
    },
    fetchPolicy: "network-only",
  });

  const updateUser = useMutation(RESET_PASSWORD_MUTATION);

  if (!loading) {
    if (data.userBoById && data.userBoById.resetPassword === true) {
      return (
        <ResetPasswordView
          submitSuccess={async (values) => {
            delete values.passwordConfirm;

            const variables = {
              data: {
                ...values,
                resetPassword: false,
              },
              where: {
                _id: match.params.id,
              },
            };

            const resultMutation = await updateUser({ variables: variables });
            if (!resultMutation.loading) {
              if (!resultMutation.error) {
                history.push("/login");
              } else {
                globalActions.notification.openNotification("tr", {
                  message: "Error al actualizar usuario",
                  color: "danger",
                });
              }
            }
          }}
        />
      );
    } else{
        history.push("/login");
        return null;
    }
  } else {
    return null;
  }
};

export default RecoverPassword;
