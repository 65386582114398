import React, { useState, useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import { Form, withFormik } from "formik";
import * as yup from "yup";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { TextField, makeStyles, Button } from "@material-ui/core";
import { INSTANCE_FEATURES } from "../../config/constants";
import DivCenter from "../../components/styled-components/DivCenter";
import ReactTable from "react-table";
import TextFieldContentWidth from "../../views/PlansView/components/TextFieldContentWidth";
import SwitchContent from "../../views/PlansView/components/SwitchContent";
import history from "../../utils/history";
import WarningChangePlan from "../../modals/WarningChangePlan";
import _ from "lodash";

const planToInstanceSchema = yup.object().shape({
  planId: yup.object().required("isRequired"),
  customPlanFeatures: yup.array(),
});

const PlanToInstanceForm = ({
  values,
  setFieldValue,
  submitForm,
  customPlanFeatures,
  plans,
  disabled
}) => {
  const { t } = useTranslation("plans");
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currentEditField, setCurrentEditField] = useState(null);
  const [customFeatures, setCustomFeatures] = useState(customPlanFeatures);

  const dataTable = useMemo(() => {
    const features = INSTANCE_FEATURES.map((feature) => ({ ...feature }));

    const plan = values.planId
      ? plans.find((plan) => plan._id === values.planId._id)
      : null;

    const currentData = plan ? _.differenceBy(plan?.features, customFeatures, '_id').concat(customFeatures) : [];

    const columns = plan ? [
      {
        Header: <DivCenter>{plan?.name || ""}</DivCenter>,
        accessor: "name",
        sortable: false,
        filterable: false,
        with: 200,
        style: { cursor: "pointer" },
        Cell: ({ original }) => {
          const feature = currentData.find(
            (feature) => feature.name === original.name
          );

          return rendetTypeField[feature.type](
            feature.value,
            feature._id,
            plan._id
          );
        },
      },
    ] : [];

    if (columns.length) {
      columns.unshift({
        Header: <DivCenter>{t("plans.featuresTitle")}</DivCenter>,
        fixed: "left",
        accessor: "name",
        sortable: false,
        filterable: false,
        with: 200,
        style: { textAlign: "center", whiteSpace: "unset", fontWeight: "400" },
        Cell: ({ original }) => {
          return t(`plans.features.${original.name}`);
        },
      });
    }

    return {
      data: features,
      columns: columns,
    };
  }, [customFeatures, currentEditField, values.planId]);

  const DivDoubleClick = ({ callback, children }) => {
    const [clickCount, setClickCount] = useState(0);
    return (
      <DivCenter
        onClick={() => {
          if (clickCount === 0) {
            setClickCount(1);
            setTimeout(() => {
              setClickCount(0);
            }, 200);
          } else {
            callback();
          }
        }}
      >
        {children}
      </DivCenter>
    );
  };

  const rendetTypeField = {
    Number: (value, featureId, planId) => {
      if (
        currentEditField &&
        currentEditField.featureId === featureId &&
        currentEditField.planId === planId
      ) {
        return (
          <DivCenter>
            <TextFieldContentWidth
              value={value}
              type="number"
              onChange={(newValue) => {
                if (value !== newValue.toString()) {

                  const feature = customFeatures.find(customFeature => customFeature._id === featureId);

                  let customFeature = feature ? feature : values.planId.features.find(feature => feature._id === featureId);
                  customFeature.value = newValue.toString();
                  
                  const filterCustomFilter = customFeatures.filter(customFeature => customFeature._id !== featureId);
                  setCustomFeatures([...filterCustomFilter, customFeature]);
                  setFieldValue('customPlanFeatures', [...filterCustomFilter, customFeature]);

                }
              }}
              endEdit={() => {
                setCurrentEditField(null);
              }}
            />
          </DivCenter>
        );
      } else if(!disabled){
        return (
          <DivDoubleClick
            callback={() => {
              setCurrentEditField({
                featureId: featureId,
                planId: planId,
              });
            }}
          >
            {value}
          </DivDoubleClick>
        );
      }else{
        return (
          <DivCenter> {value} </DivCenter>
        )
      }
    },
    Boolean: (value, featureId, planId) => {
      return (
        <DivCenter>
          <SwitchContent
            value={value}
            disabled={disabled}
            onChange={(newValue) => {

              const feature = customFeatures.find(customFeature => customFeature._id === featureId);

              let customFeature = feature ? feature : values.planId.features.find(feature => feature._id === featureId);
              customFeature.value = newValue ? "on" : "off";  
              
              const filterCustomFilter = customFeatures.filter(customFeature => customFeature._id !== featureId);
              setCustomFeatures([...filterCustomFilter, customFeature]);
              setFieldValue('customPlanFeatures', [...filterCustomFilter, customFeature]);          
            }}
            name={featureId}
          />
        </DivCenter>
      );
    },
  };

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={6}>
          <Autocomplete
            id="combo-box-demo"
            options={plans}
            disabled={disabled}
            getOptionLabel={(option) => option.name}
            value={values.planId}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Seleccione el plan"
                variant="outlined"
              />
            )}
            onChange={(event, item) => {
              if (values.planId) {
               setOpenModal(true); 
              }
              setSelectedPlan(item);

              if (!values.planId) {
                setFieldValue("planId", item);
                setCustomFeatures([]);
                setFieldValue('customPlanFeatures', []);
              }
            }}
          />
        </GridItem>

        <GridItem xs={8} className={classes.marginTop}>
          <ReactTable
            data={dataTable.data}
            columns={dataTable.columns}
            defaultPageSize={dataTable.data.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight"
            style={{
              maxHeight: "650px",
              overflowY: "scroll",
            }}
          />
        </GridItem>

        <GridItem xs={6} className={classes.centerButtons}>
          <Button
            variant="contained"
            className={classes.marginRight}
            onClick={() => { history.goBack(); }}
          >
            <span>Regresar</span>
          </Button>
          <Button
            variant="contained"
            className={classes.colorButton}
            onClick={() => { submitForm(); }}
          >
            <span>Guardar</span>
          </Button>
        </GridItem>

        <WarningChangePlan 
          openModal={openModal}
          setOpenModal={setOpenModal}
          confirm={(confirm) => {
              if (confirm) {
                setFieldValue("planId", selectedPlan);
                setCustomFeatures([]);
                setFieldValue('customPlanFeatures', []);
              }else{
                setSelectedPlan(null);
              }
          }}
        />
      </GridContainer>
    </Form>
  );
};

const useStyles = makeStyles((theme) => ({
    marginTop: {
      marginTop: "30px",
    },
    colorButton: {
        backgroundColor: '#00acc1',
        color: 'white',
        '&:hover': {
            backgroundColor: '#059FB2',
        },
    },
    marginRight: {
        marginRight: '10px'
    },
    centerButtons: {
        marginTop: "30px",
        textAlign: 'center'
    }
  }));

const PlanToInstanceFormWF = withFormik({
  enableReinitialize: true,
  validationSchema: planToInstanceSchema,
  mapPropsToValues: ({ planId, customPlanFeatures, plans }) => {
    const plan = planId ? plans.find((plan) => plan._id === planId) : null;

    return {
      planId: plan ? plan : "",
      customPlanFeatures: customPlanFeatures ? customPlanFeatures : [],
    };
  },
  handleSubmit: (values, { props }) => {
      props.addPlanToInstance(values)
  },
})(PlanToInstanceForm);

export default PlanToInstanceFormWF;
