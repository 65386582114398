import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { NavLink } from "react-router-dom";
import UserIcon from "@material-ui/icons/Person"
// import avatar from "assets/img/faces/marc.jpg";
import useGlobal from "../../store";

const UserMenu = ({
  classes,
  userWrapperClass,
  photo,
  openCollapse,
  caret,
  openAvatar,
  itemText,
  collapseItemText,
  collapseItemMini
}) => {

  const [{session}, globalActions] = useGlobal();

  return (
    <div className={userWrapperClass}>
      <div className={photo}>
        <UserIcon className={classes.avatarImg}/>
        {/*<img src={avatar} className={classes.avatarImg} alt="..." />*/}
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item + " " + classes.userItem}>
          <NavLink
            to={"#"}
            className={classes.itemLink + " " + classes.userCollapseButton}
            onClick={() => openCollapse("openAvatar")}
          >
            {session.user ?
              <ListItemText
                primary={`${session.user.firstName} ${session.user.lastName}`}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      classes.userCaret +
                      " " +
                      (openAvatar ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              /> : []
            }
          </NavLink>
          <Collapse in={openAvatar} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
              <ListItem className={classes.collapseItem} button onClick={() => {
                globalActions.session.logout();
              }}>
                <NavLink
                  to="#"
                  className={classes.itemLink + " " + classes.userCollapseLinks}
                >
                  <span className={collapseItemMini}>{"L"}</span>
                  <ListItemText
                    primary={"Log Out"}
                    disableTypography={true}
                    className={collapseItemText}
                  />
                </NavLink>
              </ListItem>
            </List>
          </Collapse>

        </ListItem>
      </List>
    </div>
  );
};

export default UserMenu;
