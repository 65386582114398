import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const ArchivedUserModal = (props) => {
    const { openModal, setOpenModal, archivedUser, user } = props;
    const { t } = useTranslation("users");

    return (
      <Dialog
        open={openModal}
        keepMounted
        onClose={setOpenModal}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="lert-dialog-title">
          <GridContainer>
            <GridItem xs={2}>
              <WarningIcon style={styles.icon} />
            </GridItem>
            <GridItem xs={10}>
              <h4>{t("users.modal.archived.title")} : <span>{user ? user.email : ''}</span></h4> 
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("users.modal.archived.body")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            {t("users.cancel")}
          </Button>
          <Button onClick={() => {
              archivedUser(user);
              setOpenModal(false);
          }} color="primary" autoFocus>
            {t("users.save")}
          </Button>
        </DialogActions>
      </Dialog>
    );
}

const styles = {
    icon: {
        fontSize: '40px',
        textAlign: 'center',
        marginLeft: '50%',
        marginRight: '50%',
        marginTop: '10%'
    }
}

export default ArchivedUserModal
