import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from "formik";
import { TextField } from 'formik-material-ui';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import { MenuItem } from '@material-ui/core';
import ButtonIcon from "../../components/styled/ButtonIcon";
import { APP_STATUS } from "../../config/constants";
import Search from "@material-ui/icons/Search";

const fillYearsSelect = () => {
    let currentDate = new Date().getFullYear();
    let lastDate = currentDate - 5;
    let years = [0];
  
    for (let i = currentDate; i >= lastDate; i--) {
      years.push(i); 
    }

    return years;
}

const IndexesForm = ({classes, filterAction, paginationAction, filterOptions, t}) =>{
    const { created } = filterOptions
    return (
        <Formik
            initialValues={{ 
            name: '', 
            status: '' ,
            created: created,
            }}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);
                (values.created === '') ? values.created = 0 : values.created = values.created;
                filterAction(values);
                paginationAction({ first: 0, skip: 0, numPage: 1 });

            }}
            render={({handleSubmit, isSubmitting, setFieldValue}) => (
            <form onSubmit={handleSubmit}>
                <GridContainer>
                    <GridItem xs={2}>
                        <Field
                            label={t("indexes.nameApp")} 
                            name="name" 
                            type="text"
                            component={TextField}
                            className={classes.Field}
                        />
                    </GridItem>
                    <GridItem xs={2}>
                        <Field
                            label={t("indexes.status")} 
                            name="status" 
                            component={TextField}
                            select
                            className={classes.Field}
                            >
                            {Object.keys(APP_STATUS).map((key)=>(
                                <MenuItem key={APP_STATUS[key]} value={APP_STATUS[key]}>
                                {t(`indexes.${key}`)}
                                </MenuItem>
                            ))
                            }
                        </Field>
                    </GridItem>
                    <GridItem xs={2}>
                        <Field
                            label={t("indexes.year")}
                            name="created" 
                            component={TextField}
                            select
                            className={classes.Field}
                            >
                            {fillYearsSelect().map((year)=>(
                                <MenuItem key={year} value={year}>
                                {year ? year : t("indexes.all")}
                                </MenuItem>
                            ))
                            }
                        </Field>
                    </GridItem>
                    
                    <GridItem xs={2}> 
                        <ButtonIcon 
                            icon={Search}
                            label=""
                            color={'info'}
                            type="submit" disabled={isSubmitting}>{t("indexes.submit")}</ButtonIcon>
                    </GridItem>
                </GridContainer>  

                <GridContainer>
                
                </GridContainer>   
            </form>
            )}
        />
    );
}

IndexesForm.propTypes = {
    classes: PropTypes.object.isRequired,
    filterAction: PropTypes.func.isRequired,
    paginationAction: PropTypes.func.isRequired,
    filterOptions: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default IndexesForm;