import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import InputLabel from '@material-ui/core/InputLabel';
import { useTranslation } from "react-i18next";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const TablePagination = ({ classes, paginationAction, paginationOptions, paginationStatus, totalCount }) =>{
    const { skip, numPage } = paginationOptions;
    const { t } = useTranslation('common');
    return (
        <GridContainer className={classes.paginationPage}>
            <GridItem xs={4}>
                <Button className={classes.btnPreview} disabled={!paginationStatus.hasPreviousPage}
                    onClick={() => {
                        paginationAction({...paginationOptions, first: 15, skip: skip - 15, numPage: numPage - 1}); 
                }}>
                    <KeyboardArrowLeft className={classes.icons} /> {t('common.pagination.preview')}
                </Button>
            </GridItem>
            <GridItem xs={4}>
                <InputLabel> {t('common.pagination.page')} { numPage } {t('common.pagination.of')} { Math.ceil(totalCount / 15) } </InputLabel>
            </GridItem>
            <GridItem xs={4}>
                <Button className={classes.btnNext} disabled={!paginationStatus.hasNextPage}
                    onClick={() => {                     
                        paginationAction({...paginationOptions, first: 15, skip: skip + 15, numPage: numPage + 1 });
                    }}
                >
                    {t('common.pagination.next')} <KeyboardArrowRight className={classes.icons} />
                </Button>
            </GridItem>
        </GridContainer>
    );
}

TablePagination.propTypes = {
    classes: PropTypes.object.isRequired,
    paginationAction: PropTypes.func.isRequired,
    paginationOptions: PropTypes.object.isRequired,
    paginationStatus: PropTypes.object.isRequired,
    totalCount: PropTypes.number.isRequired,
};
export default TablePagination;