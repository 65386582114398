import gql from "graphql-tag";

export const VISIT_ANSWER_CLOSE = gql`
  mutation visitAnswersClose($data: VisitAnswersCloseInput!) {
    visitAnswersClose(data: $data) {
      _id
      result
    }
  }
`;
