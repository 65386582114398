 import React, {useLayoutEffect} from "react";
import {Switch, Route} from "react-router-dom";
import {withApollo} from "react-apollo";
import {Helmet} from "react-helmet";
import {compose} from "recompose";

import indexRoutes from "../../routes";
import PrivateRoute from "../../utils/routing/PrivateRoute";
import useGlobal from "../../store";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Snackbar from "components/Snackbar/Snackbar.jsx";
function App({lng, client}) {
  const [{notifications}, globalActions] = useGlobal();

  useLayoutEffect(() => {
    globalActions.session.checkSession(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {tr} = notifications; 
  return (
    <div className="App">
      <Helmet>
        {/* <html lang={lng}/> */}
      </Helmet>
      <Switch>
        {indexRoutes.map((prop, key) => {
          if (!prop.public) {
            return (
              <PrivateRoute path={prop.path} component={prop.component} key={key}/>
            );
          } else {
            return (
              <Route path={prop.path} component={prop.component} key={key}/>
            );
          }
        })}
      </Switch>
      <Snackbar
        place={'tr'}
        color={tr.color}
        icon={AddAlert}
        message={tr.message || ''}
        open={tr.open}
        closeNotification={() => {
          globalActions.notification.closeNotification('tr');
        }}
        autoHideDuration={tr.autoHideDuration}
      />
    </div>
  );
}

export default compose(
  withApollo,
)(App);

