export const hasError = (errors, fieldName, secondLevel) => {

  if (errors && Object.getOwnPropertyNames(errors).length >= 1) {
    if (typeof errors.fieldName === 'object') {
      return errors.fieldName.hasOwnProperty(secondLevel);
    }
    return errors.hasOwnProperty(fieldName);
  }
  return false;
};
