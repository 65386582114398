import React from 'react'
import { useTranslation } from "react-i18next";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import AddUserForm from '../../forms/Admins/AddUserForm';

const AddUserView = (props) => {
    const {t,} = useTranslation('instances');
    const { roles, teams, submitSuccess, app } = props;
    let allTeams = [{
      id: '',
      name: 'Todos los equipos'
    }];

    teams.map(team => allTeams.push(team));

    return (
        <React.Fragment>
          <GridContainer justify="center">
            <GridItem xs={6}>
              <Card>
                <CardHeader>
                  <CardIcon color="info">
                    <Apps />
                  </CardIcon>
                  <h4>{t("instances.users.addUser")}</h4>
                </CardHeader>
                <CardBody>
                    <AddUserForm
                      roles={roles}
                      teams={allTeams} 
                      app={app}
                      submitSuccess={submitSuccess}
                     />

                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </React.Fragment>
    );
}

export default AddUserView;
