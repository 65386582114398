import React from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import NewPartnerView from '../../views/Partners/NewPartnerView';
import { CITIES } from '../Instances/gql/Queries';
import history from '../../utils/history';
import useGlobal from "../../store";
import { CREATE_PARTNER } from './gql/Mutations';
import auth from '../../utils/auth';

const NewPartner = (props) => {

    const [{session}, globalActions] = useGlobal();
    const createPartner = useMutation(CREATE_PARTNER);
    const { data } = useQuery(CITIES);

    if (
      session.user &&
      !auth.hasPermission("partnerCreate", session.user.role.permissions)
    ) {
      globalActions.notification.openNotification("tr", {
        message: "No tienes permisos",
        color: "danger",
      });

      history.push('dashboard');
    } 

    return (
        <NewPartnerView
            cities={data && data.cities ? data.cities : []}
            submitSuccess={async (values)=> {
                let image = values.logo;
                delete values.logo;
                delete values.logos;
                let variables = {
                    data: {
                        ...values
                    },
                    file: image[0]
                };

                const mutationResult = await createPartner({
                  variables: variables
                });

                const { loading, error } = mutationResult;

                if (!loading) {
                    if (!error) {
                      history.push("/partners/list");
                      // Add success notification
                      globalActions.notification.openNotification(
                        "tr",
                        {
                          message: "Nuevo Partner creado",
                          color: "success"
                        }
                      );
                    }else{
                      globalActions.notification.openNotification('tr', {
                          message: 'Error al crear partner',
                          color: 'danger'
                      });
                    }
                  }
            }}
        />
    )
}

export default NewPartner;
