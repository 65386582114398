import gql from "graphql-tag";

export const ALL_APPS = gql`
  query ALL_APPS {
    getAllApps {
      _id
      name
    }
  }
`;

export const APP_VISITS = gql`
  query visits($appId: ID!) {
    visits(where: { app: $appId, isArchived: false }) {
      _id
      name
      keys
    }
  }
`;

export const APP_USERS = gql`
  query usersMobile($appId: ID!, $email_contains: String!) {
    usersMobile(
      where: { app: $appId, email_contains: $email_contains, archived: false }
    ) {
      _id
      firstName
      lastName
      email
    }
  }
`;

export const USER_VISIT_ANSWERS = gql`
  query userVisitAnswers(
    $appId: ID!
    $userId: ID!
    $visitId: ID!
    $checkInDate_lte: DateTime!
    $checkInDate_gte: DateTime!
  ) {
    visitAnswers(
      where: {
        app: $appId
        userId: $userId
        visitId: $visitId
        checkInDate_lte: $checkInDate_lte
        checkInDate_gte: $checkInDate_gte
      }
      orderBy: checkInDate_ASC
    ) {
      _id
      app
      visitId
      userId
      folio
      checkInDate
      checkOutDate
      keys
      isPaused
      created
      duration
      moduleAnswers
    }
  }
`;
