import React, { useEffect, useState} from 'react';
import {  ACTIVITY_INDICATORS } from "./Queries";
import moment from 'moment';
import ActivityIndexesView from "../../views/ActivityIndexes/ActivityIndexesView";
import auth from '../../utils/auth';
import useGlobal from "../../store";
import history from '../../utils/history';
import { useQuery } from 'react-apollo-hooks';

const ActivityIndexes = (props) => {
  const [{ session }, ] = useGlobal();
  const [paginationOptions, setPaginationOptions] = useState({
      numPage: 1,
      first: 15, 
      skip: 0,
    });
  const [filterOptions, setFilterOptions] = useState({
    name: "",
    status: "",
    instanceType: 'Subscription',
    created: 0,
  });

  useEffect(() => {
    const { user } = session;
    if (user) {
      if (user.role.master) {
        setFilterOptions({...filterOptions});
      }else{
        setFilterOptions({...filterOptions, franchise: user.franchise});
      }
    }
    
  }, [session]);

  async function paginationAction(values) {
    setPaginationOptions(values);
  }

  async function filterAction(values) {
    setFilterOptions({...filterOptions, ...values});
  }

  if (
    session.user &&
    !auth.hasPermission("indexesView", session.user.role.permissions)
  ) {
    history.push('dashboard');
  }

  const { data, loading } = useQuery(ACTIVITY_INDICATORS, {
    variables: {
      first: paginationOptions.first,
      skip: paginationOptions.skip,
      where: filterOptions,
    },
    fetchPolicy: "network-only"
  });

  return (
    <ActivityIndexesView
      data={
        data && data.indexesConnection
          ? data.indexesConnection
          : {
              totalCount: 0,
              edges: [],
              pageInfo: {
                hasNextPage: false,
                hastPreviousPage: false,
              }
            }
      }
      paginationAction={paginationAction}
      paginationOptions={paginationOptions}
      filterOptions={filterOptions}
      filterAction={filterAction}
      loading={loading}
    />
  );
};


export default ActivityIndexes;
