import React from "react";
import { Field } from "formik";
import { compose } from "recompose";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { useTranslation } from 'react-i18next';
import { IDENTIFICATION_TYPES } from '../../../config/constants';
import { hasError } from "../../../utils/forms/inputs";

//Utils

const IndentityIntance = props => {
  const { classes, errors, touched } = props;
  const { t, } = useTranslation('instances');
  
  return (
    <GridContainer>
      <GridItem xs={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel className={classes.selectLabel}>
            {t('instances.forms.fields.identificationType.label')}
          </InputLabel>
          <Field
            name="identification.type"
            render={({field}) => {
              return (
                <Select 
                  id={field.value ? "demo-simple-select": 'demo-simple-select-error'}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  onChange={e => {
                    /* setIndentityType(e.target.value); */
                  }}
                  inputProps={{ ...field }}
                >
                  {Object.keys(IDENTIFICATION_TYPES).map(identityType => (
                    <MenuItem
                      classes={{ root: classes.selectMenuItem }}
                      value={IDENTIFICATION_TYPES[identityType]}
                      key={IDENTIFICATION_TYPES[identityType]}
                    >
                      {t(`instances.forms.fields.identificationType.list.${identityType}`)}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>
      </GridItem>

      <GridItem xs={12}>
        <Field
          name="identification.value"
          render={({field}) => {
            return (
              <CustomInput
                labelText={t('instances.forms.fields.identificationValue.label')}
                id="value"
                type={"text"}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{ ...field }}
                success={!hasError(errors, 'identification', 'value') && touched.identification}
                error={hasError(errors, "identification",'value')}
              />
            );
          }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default compose(withStyles(extendedFormsStyle))(IndentityIntance);
