import React, { useState } from 'react';
import ToDoListTable from '../../tables/ToDoList/ToDoListTable';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { useTranslation } from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "components/Button";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import NewTaskModal from '../../modals/NewTaskModal';
import DrawerTask from './DrawerTask';
import TablePagination from '../../paginations/table/SimpleTaablePagination';
import ConfirmEmailTaskModal from '../../modals/ConfirmEmailTaskModal';
import FilterDemoForm from "../../forms/Instances/FilterDemoForm";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from '../../utils/history';

const ToDoListView = ({
  activitiesData,
  classes,
  app,
  userId,
  submitSuccess,
  newComment,
  updateActivity,
  paginationAction,
  paginationOptions,
  sendEmail,
  filterAction,
  instanceType,
  paginationState
}) => {
  const { activities, loading } = activitiesData;
  const { t } = useTranslation("todolist");
  const [openModal, setOpenModal] = useState(false);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activity, setActivity] = useState({});

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button
          simple
          size="lg"
          color="info"
          onClick={() => history.push(`/instances/${instanceType}`, { paginationState: paginationState })}
          className={styles.buttonNoMargin}
        >
          <ArrowBackIcon/><span>{t('todolist.goBackInstance')}</span>
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <CardIcon color="info">
              <PlaylistAddCheckIcon />
            </CardIcon>
            <div style={styles.right}>
              <Button size="sm" color="info" onClick={() => setOpenModal(true)}>
                {t("todolist.newActivity")}
              </Button>
            </div>
            <h4>{t("todolist.title")}</h4>
          </CardHeader>
          <CardBody>
            <FilterDemoForm submitSuccess={filterAction} />

            <TablePagination
              data={activities}
              paginationAction={paginationAction}
              filter={paginationOptions}
            />
            {loading ? (
              <div className={classes.progressCnt}>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              <ToDoListTable
                activities={activities}
                setOpenDrawer={setOpenDrawer}
                setActivity={setActivity}
                updateActivity={updateActivity}
                setOpenModalEmail={setOpenModalEmail}
                paginationAction={paginationAction}
                paginationOptions={paginationOptions}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={7}>
        <DrawerTask
          userId={userId}
          activity={activity}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          newComment={newComment}
          updateActivity={updateActivity}
        />
      </GridItem>
      <NewTaskModal
        app={app}
        userId={userId}
        openModal={openModal}
        submitSuccess={submitSuccess}
        setOpenModal={() => setOpenModal(false)}
      />

      <ConfirmEmailTaskModal
        openModalEmail={openModalEmail}
        setOpenModalEmail={setOpenModalEmail}
        sendEmail={sendEmail}
      />
    </GridContainer>
  );
};

const styles = {
    right: {
      float: "right"
    }
};

export default compose(
    withStyles(indexesPageStyle),
)(ToDoListView);