import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import UsersView from '../../views/Users/UsersView';
import { RESET_PASSWORD, UPDATE_USER_BO } from './gql/Mutations';
import { USERS_BO } from './gql/Queries';
import useGlobal from "../../store";
import auth from '../../utils/auth';
import history from '../../utils/history';

const Users = (props) => {
    const [{session}, globalActions] = useGlobal();

    const [filters, setFilters] = useState({
        where:{
            archived: false
        },
        first: 15,
        skip: 0,
    });

    const paginationAction = (values) => {
        setFilters(values);
    }

    const { data, loading } = useQuery(USERS_BO,{
        variables: filters,
        fetchPolicy: "network-only"
    });

    const updateUser = useMutation(UPDATE_USER_BO);
    const recoverPassword = useMutation(RESET_PASSWORD);

    if (
      session.user &&
      !auth.hasPermission("userView", session.user.role.permissions)
    ) {
      history.push('dashboard');
    } 

    return (
      <UsersView
        users={data}
        loading={loading}
        paginationAction={paginationAction}
        filter={filters}
        resetPassword={async (user) => {
          const variables = {
            data: {
              resetPassword: true,
            },
            where: {
              _id: user._id,
            },
          };

          const mutationResult = await recoverPassword({
            variables: variables,
          });

          if (!mutationResult.loading) {
            if (!mutationResult.error) {
              window.location.reload();
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: "Usuario Actualizado",
                color: "success",
              });
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al actualizar usuario",
                color: "danger",
              });
            }
          }
        }}
        archivedUser={async (user) => {
          user.role = user.role_id;
          const id = user._id;

          delete user._id;
          delete user.franchiseRef;

          const variables = {
            data: {
              ...user,
              archived: true,
            },
            where: {
              _id: id,
            },
          };

          const mutationResult = await updateUser({
            variables: variables,
          });

          if (!mutationResult.loading) {
            if (!mutationResult.error) {
              window.location.reload();
              // Add success notification
              globalActions.notification.openNotification("tr", {
                message: "Usuario Actualizado",
                color: "success",
              });
            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al actualizar usuario",
                color: "danger",
              });
            }
          }
        }}
      />
    );
}

export default Users;
