import React from 'react'
import { useTranslation } from "react-i18next";

// own components
import EditSubscriptionForm from '../../forms/Instances/EditSubscriptionForm';
import RealtimeLocationsConfig from "../../containers/Instances/RealtimeLocationsConfig";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Apps from "@material-ui/icons/Apps";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from 'components/Button';
import history from '../../utils/history';

const EditSubscriptionView = (props) => {
    const {
      instance,
      submitSuccess,
      updateAppToken,
      archivedAllappTokens,
      cities,
      partners,
      paginationState
    } = props;
    const {t} = useTranslation('instances');
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
              <Button
                simple
                size="lg"
                color="info"
                onClick={() => history.push(`/instances/subscriptions`, { paginationState: paginationState })}
                className={styles.buttonNoMargin}
              >
              <ArrowBackIcon/><span>{t('instances.goBackInstance')}</span>
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="info">
                  <Apps />
                </CardIcon>
                <h4>{t("instances.subscription.newSubscription")}</h4>
              </CardHeader>
              <CardBody>
                <EditSubscriptionForm
                  cities={cities}
                  updateAppToken={updateAppToken}
                  archivedAllappTokens={archivedAllappTokens}
                  instance={instance}
                  submitSuccess={submitSuccess}
                  partners={partners}
                  paginationState={paginationState}
                />
                <RealtimeLocationsConfig instance={instance} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
}

const styles = {
  buttonNoMargin:{
    padding: "10px 0px"
  },
}

export default EditSubscriptionView;
