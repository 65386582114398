import React from 'react'
import * as yup from "yup";
import { Form, withFormik, Field } from "formik";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from 'react-i18next';
import Button from "components/CustomButtons/Button.jsx";
import { hasError } from "../../utils/forms/inputs";

const geofenceSchema = yup.object().shape({
    name: yup.string().required()
  });
 
const GeofenceGroupForm = (props) => {
    const { errors, touched, submitForm, handleClose } = props;
    const { t } = useTranslation("geofences");
    return (
        <Form>
        <GridContainer>
          <GridItem xs={8}>
            <Field
              name="name"
              render={({ field }) => {
                return (
                  <CustomInput
                    desable
                    labelText={t("geofences.forms.fields.groupName.label")}
                    id="name"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      ...field,
                    }}
                    success={!hasError(errors, "name") && touched.name}
                    error={hasError(errors, "name")}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <Button style={styles.right} size="sm" color="info" onClick={() => submitForm()}>
              {t("geofences.save")}
            </Button>
            <Button style={styles.right} size="sm" onClick={()=> handleClose()}>
              {t("geofences.cancel")}
            </Button>
          </GridItem>
        </GridContainer>
      </Form>
    )
}

const styles = {
    right: {
      float: "right",
    },
  };


const GeofenceGroupModalForm = withFormik({
    validationSchema: geofenceSchema,
    mapPropsToValues : (props) => {
        return {
            name: ''
        }
    },
    handleSubmit: (values, {props}) => {
        props.onSubmit(values);
      }
})(GeofenceGroupForm);
export default GeofenceGroupModalForm;
