import React, {useState} from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { graphql } from "react-apollo";

import { LOGIN_MUTATION } from "./Mutations.js";
import LoginView from '../../views/Login/LoginView';
import useGlobal from "../../store";

function Login({ history, client, signin, ...props }) {
  const [errors, setErrors] = useState([]);
  const [, globalActions] = useGlobal();

  const doSignIn = async (values) => {
    try {

      const {data} = await signin({
        variables: values
      });

      await globalActions.session.signin(data.signin, history);

    } catch (e) {
      if (e.graphQLErrors) {
        setErrors(e.graphQLErrors.map(err => err.code));
      }else{
        console.log(e);
      }
    }
  };

  return (
    <LoginView doSignIn={doSignIn} errors={errors} />
  );
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default compose(
  graphql(LOGIN_MUTATION, {
    name: "signin"
  }),
)(Login);
