import gql from "graphql-tag";

export const CREATE_USER_BO = gql`
  mutation CREATE_USER($data: UserBoCreateInput!) {
    createUserBo(data: $data) {
      lastName
      firstName
      email
    }
  }
`;

export const UPDATE_USER_BO = gql`
  mutation UPDATE_USER_BO(
    $data: UserBoWhereInput!
    $where: UserWhereUniqueInput!
  ) {
    updateUserBo(data: $data, where: $where) {
      firstName
      lastName
      email
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: UserBoPasswordInput!, $where: UserWhereUniqueInput!) {
    recoverPassword(data: $data, where: $where) {
      _id
      firstName
      lastName
      role {
        _id
        name
        permissions
      }
      archived
      profileImage
      created
      resetPassword
    }
  }
`;