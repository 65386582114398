import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Form, withFormik, Field } from "formik";
import * as yup from "yup";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ButtonIcon from "../../components/styled/ButtonIcon";
import Send from "@material-ui/icons/Send";
import Cancel from "@material-ui/icons/Cancel";
import { NavLink } from "react-router-dom";


const categoryFormSchema = yup.object().shape({
    name: yup.string(),
  });

const UpdateCategoryForm = (props) => {
    const {
        submitForm,
      } = props;
    

    return (
      <Form>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              name="name"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText="Nombre: "
                    id="name"
                    type={"text"}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      ...field
                    }}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>
        
        
        
        <GridContainer justify="center">
          <GridItem xs={2}>
            <ButtonIcon
              icon={Send}
              label="Enviar"
              color={"info"}
              block={true}
              onClick={() => {
                submitForm();
              }}
            />
          </GridItem>
          <GridItem xs={3}>
            <NavLink to={"/tutorials/categoriesList"}>
              <ButtonIcon icon={Cancel} label="Cancelar" />
            </NavLink>
          </GridItem>
        </GridContainer>
      </Form>
    );
};

const UpdateCategoryFormWF = withFormik({
    validationSchema: categoryFormSchema,
    mapPropsToValues: (props) => {
        const { category } = props;
      return {
        name: category.name
    }
    },
    handleSubmit: (values, {props}) => {
        props.submitSuccess(values);
    },
})(UpdateCategoryForm);

export default UpdateCategoryFormWF;
