import React, { useState } from 'react'
import AdminsView from '../../views/Admins/AdminsView';
import { useQuery } from 'react-apollo-hooks';
import { USERS_FEED } from './gql/Queries';

const Admins = (props) => {
    const { match } = props;
    const [pagination, setPagination] = useState({
        first: 15,
        skip: 0,
    });

    const { data, loading } = useQuery(USERS_FEED, 
        {
            variables: { 
                where: { 
                    app: match.params.app 
                },
                ...pagination 
            }
        }
    );
   
    async function paginationAction(values) {
        setPagination(values);
    }

    return (
        <AdminsView 
            loading={loading} 
            data={data} 
            app={match.params.app} 
            paginationAction={paginationAction}
            paginationOptions={pagination}
        />
    );
}

export default Admins;
