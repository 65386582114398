import React, { useState } from "react";
import * as yup from "yup";
import { Form, withFormik, Field } from "formik";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/Button.jsx";
import ColorPicker from "material-ui-color-picker";
import GoogleMaps from './Map';
import { hasError } from "../../utils/forms/inputs";
const geofenceSchema = yup.object().shape({
  name: yup.string().required(),
  color: yup.string(),
  type: yup.number(),
  zoom: yup.number(),
  shape: yup.object(),
});

const AddGeofenceForm = (props) => {
  const { setFieldValue, submitForm, errors, touched, values} = props;
  const { t } = useTranslation("geofences");
  const [color, setColor] = useState(values.color);

  const handleColor = (event) => {
    setColor(event);
    setFieldValue('color', event);
  };

  return (
    <Form>
      <GridContainer>
        <GridItem xs={4}>
          <Field
            name="name"
            render={({ field }) => {
              return (
                <CustomInput
                  desable
                  labelText={t("geofences.forms.fields.name.label")}
                  id="name"
                  type={"text"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    ...field,
                  }}
                  success={!hasError(errors, "name") && touched.name}
                  error={hasError(errors, "name")}
                />
              );
            }}
          />
        </GridItem>
        <GridItem xs={2}>
          <Field
            name="color"
            render={({ field }) => {
              return (
                <ColorPicker
                  name="color"
                  floatingLabelText={`${t(
                    "geofences.forms.fields.color.label"
                  )}`}
                  defaultValue={"Color"}
                  value={color}
                  onChange={handleColor}
                />
              );
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <GoogleMaps
            color={color}
            setFieldValue={setFieldValue}
            geofence={values}
          />
        </GridItem>
        <GridItem xs={12}>
          <Button
            style={styles.right}
            size="sm"
            color="info"
            onClick={() => submitForm()}
          >
            {t("geofences.save")}
          </Button>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
const styles = {
  right: {
    float: "right",
  },
  formControl: {
    margin: 1,
    minWidth: 120,
  },
};
const GeofenceFormFK = withFormik({
  validationSchema: geofenceSchema,
  mapPropsToValues: ({ geofence }) => {
    const { name, color, type, shape, zoom } = geofence;

    return {
      name: name ? name : "",
      color: color ? color : "green",
      type: type ? type : 1,
      shape: shape ? shape : {},
      zoom: zoom ? zoom : 13,
    };
  },
  handleSubmit: (values, {props}) => {
    props.onSubmit(values);
  }
})(AddGeofenceForm);

export default GeofenceFormFK;

