import React from 'react'
import { useTranslation } from 'react-i18next';
import ReactTable from "react-table";
import DeleteForever from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import DivCenter from '../../components/styled-components/DivCenter';

const GeofencesTable = (props) => {
    const { geofences, archived } = props;
    const { t } = useTranslation('geofences')
    return (
      <ReactTable
        data={geofences}
        columns={[
          {
          Header: <DivCenter>{t("geofences.list.name")}</DivCenter>,
            accessor: "name",
            filterable: false,
            sortable: true,
          },
          {
          Header: <DivCenter>{t("geofences.list.type")}</DivCenter>,
            id: 'type',
            accessor: d => {
                switch (d.type) {
                  case 1:
                    return t("geofences.types.circle");
                  case 2:
                    return t("geofences.types.rectangle");
                  case 3:
                    return t("geofences.types.polygon");
                }
            },
            style: { textAlign: "center" },
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("geofences.list.options")}</DivCenter>,
            accessor: "actions",
            Cell: ({ row }) => (
                <div className="actions-right">
                  <Tooltip title={t("geofences.edit")}>
                    <Link
                      to={{
                        pathname: `/geofence-group/add`,
                        state: {
                          geofence: row._original
                        }
                      }}
                    >
                      <Button justIcon round simple color="info">
                        <EditIcon />
                      </Button>
                    </Link>
                  </Tooltip>
                  <Tooltip title={t("geofences.archived")}>
                    <Button justIcon round simple color="danger" onClick={() => {archived(row._original)}}>
                      <DeleteForever />
                    </Button>
                  </Tooltip>
                </div>
              ),
            filterable: false,
            sortable: false,
          }
        ]}
        defaultPageSize={geofences.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
        style={{
          height: "600px",
          overflowY: 'scroll',
        }}
      />
    );
}

export default GeofencesTable;
