import gql from "graphql-tag";

export const GET_ALL_ACTIVITIES = gql`
  query GET_ALL_ACTIVITIES(
    $where: ActivityWhereInput!
    $first: Int
    $skip: Int
    $orderBy: ActivityOrderBy
  ) {
    getAllActivitiesbyUser(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      _id
      app
      title
      description
      userId
      user{
        firstName
        lastName
      }
      estimatedDate
      status
      comments {
        _id
        comment
        created
        updated
        user{
          firstName
          lastName
        }
      }
      archived
      created
    }
  }
`;