import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { MESSAGES_APP_FEED } from "./gql/Queries";
import CustomMessagesListView from "../../views/Messages/CustomMessagesListView";
import useGlobal from "../../store";
import history from "../../utils/history";
import auth from "../../utils/auth";

const CustomMessagesList = () => {
  const [{ session }, globalActions] = useGlobal();
  const [messagesCustom, setMessageCustom] = useState({
    where: {},
    skip: 0,
    first: 15
  });

  useEffect(() => {
    const { user } = session;
    if (user) {
      
      if (user.role.master) {
        setMessageCustom({ ...messagesCustom, where: { ...messagesCustom.where }});
      }else{
        setMessageCustom({ ...messagesCustom, where: { ...messagesCustom.where,  franchise: user.franchise  }});
      }
    }
    
  }, [session]);

  const paginationAction = (values) => {
    setMessageCustom(values);
  }

  const { data: dataMessageCustom, loading: loadingMessageCustom } = useQuery(
    MESSAGES_APP_FEED,
    {
      variables: messagesCustom,
      fetchPolicy: "network-only",
    }
  );

  if (
    session.user &&
    !auth.hasPermission("messageCustomView", session.user.role.permissions)
  ) {
    history.push("dashboard");
  }
  return (
    <CustomMessagesListView
      messages={{ loading: loadingMessageCustom, data: dataMessageCustom }}
      paginationAction={paginationAction}
      filter={messagesCustom}
    />
  );
};

export default CustomMessagesList;
