import React from 'react'
import AddUserView from '../../views/Admins/AddUserView';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { ROLES_FEED, TEAMS_FEED } from './gql/Queries';
import { SEND_INVITATION_MUTATION } from './gql/Mutations';

// utils
import history from '../../utils/history';

const AddUser = (props) => {
    const { match } = props;
    const { data } = useQuery(ROLES_FEED, {variables: { where: { app: match.params.app } }});
    const { data: dataTeams } = useQuery(TEAMS_FEED, {variables: { where: { app: match.params.app } }});
    const sendInvitation = useMutation(SEND_INVITATION_MUTATION);

    return (
        <AddUserView 
            roles={data?.roles || []} 
            teams={dataTeams?.teams || []} 
            app={match.params.app}
            submitSuccess={async (values)=>{
                const variables = {
                    data: {
                        email: values.email,
                        role: values.role,
                        teams: values.team === "" ? [] : [values.team],
                        app: match.params.app
                    }
                    
                }
                const resultMutation = await sendInvitation({
                  variables: variables
                });

                history.push(`/instances/users/${match.params.app}`);
            }} 
        />
    )
}

export default AddUser;
