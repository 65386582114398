export const APP_STATUS = {
    ALL: '',
    DEMO: 'Demo',
    APPROACH: 'Approach',
    PRESALE: 'Presale',
    ACTIVE: 'Active',
    LOSE: 'Lose'
};

export const APP_TYPES = {
    CLIENT: 1,
    COURTESY: 2,
    FRANCHISE: 3
}

export const IDENTIFICATION_TYPES = {
    RFC: 1,
    NIT: 2,
    ID: 3,
    OTHER: 4
}

export const APP_CATEGORIES = {
    STORE_SUPERVISION: 33,
    SHOP_MANAGEMENT: 34,
    RESTAURANT_SUPERVISION: 35,
    PROMOTORY: 36,
    SECURITY_SUPERVISION: 37,
    CLEANING_FUMIGATION: 38,
    AUDITS: 39,
    PREVENTIVE_MAINTENANCE: 40,
    OTHER_AUDITS: 41,
    INFORMATIVE: 42
}

export const APP_FEATURES = {
    OFFLINE: 'Offline',
    ROUTES: 'Routes',
    API: 'Api',
    GEOFENCES: 'Geofences',
    TICKETS: 'Tickets',
}

export const FIREBASE_PROJECTS = [
    {
        name: '',
        id: 0
    },
    {
        name: 'Beepquest',
        id: 1
    },
    {
        name: 'Beepquest2',
        id: 2
    }
]

export const PERMISSIONS = [
  "indexesView",
  "demoView",
  "demoCreate",
  "demoUpdate",
  "instanceInvitation",
  "demoToOpportunity",
  "demoDelete",
  "opportunityView",
  "opportunityUpdate",
  "opportunityToSubscription",
  "opportunityDelete",
  "subscriptionView",
  "subscriptionUpdate",
  "locationSegmentUpdate",
  "tokenUpdate",
  "firebaseProjectUpdate",
  "maxUserUpdate",
  "appTokensDelete",
  "accountMapCreate",
  "commentCreate",
  "todoListCreate",
  "selectPartner",
  "partnerView",
  "partnerCreate",
  "partnerUpdate",
  "partnerDelete",
  "matrixClientView",
  "matrixClientUpdate",
  "matrixClientInvitation",
  "matrixClientDetele",
  "messageGeneralView",
  "messageGeneralCreate",
  "messageCustomView",
  "messageCustomCreate",
  "messageEmailView",
  "messageEmailCreate",
  "categoryVideoView",
  "categoryVideoCreate",
  "tutorialsView",
  "tutorialsCreate",
  "geofenceView",
  "geofenceCreate",
  "geofenceUpdate",
  "geofenceBuy",
  "userView",
  "userCreate",
  "userUpdate",
  "userDelete",
  "roleView",
  "updateMessage",
  "categoryVideoUpdate",
  "tutorialsUpdate",
  "subscriptionDelete",
  "geofenceDelete",
  "toDoListView",
  "toDoListCreate",
  "toDoListEdit",
  "toDoListArchived",
  "toDoListViewAll",
  "addPlanInstance",
  "plansGlobal",
  "planToInstanceView",
  "planToInstanceUpdate"
];

export const APPS_FEATURES = [
  {
    groupName: "features.general",
    name: "generalScreens",
    label: "features.labels.generalScreens",
    type: "required",
  },
  {
    groupName: "features.general",
    name: "generalMediaManager",
    label: "features.labels.generalMediaManager",
    type: "required",
  },
  {
    groupName: "features.general",
    name: "generalEventsLog",
    label: "features.labels.generalEventsLog",
    type: "feature",
  },
  {
    groupName: "features.general",
    name: "generalAppGlobalSecurity",
    label: "features.labels.generalAppGlobalSecurity",
    type: "feature",
  },
  {
    groupName: "features.general",
    name: "generalTour",
    label: "features.labels.generalTour",
    type: "feature",
  },
  {
    groupName: "features.general",
    name: "generalSideMenu",
    label: "features.labels.generalSideMenu",
    type: "required",
  },
  {
    groupName: "features.users",
    name: "usersAppRegister",
    label: "features.labels.usersAppRegister",
    type: "required",
  },
  {
    groupName: "features.users",
    name: "usersVisitorsRegister",
    label: "features.labels.usersVisitorsRegister",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersSegmentation",
    label: "features.labels.usersSegmentation",
    type: "required",
  },
  {
    groupName: "features.users",
    name: "usersRegisterUi",
    label: "features.labels.usersRegisterUi",
    type: "required",
  },
  {
    groupName: "features.users",
    name: "usersCustomRegisterUi",
    label: "features.labels.usersCustomRegisterUi",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersOpenInput",
    label: "features.labels.usersOpenInput",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersMultilineInput",
    label: "features.labels.usersMultilineInput",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersEmailInput",
    label: "features.labels.usersEmailInput",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersNumberInput",
    label: "features.labels.usersNumberInput",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersDateInput",
    label: "features.labels.usersDateInput",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersTelInput",
    label: "features.labels.usersTelInput",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersPasswordInput",
    label: "features.labels.usersPasswordInput",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersRadioInput",
    label: "features.labels.usersRadioInput",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersCheckboxInput",
    label: "features.labels.usersCheckboxInput",
    type: "feature",
  },
  {
    groupName: "features.users",
    name: "usersRegisterSegmentation",
    label: "features.labels.usersRegisterSegmentation",
    type: "feature",
  },
  {
    groupName: "features.navigation",
    name: "navigationLocalizationOrder",
    label: "features.labels.navigationLocalizationOrder",
    type: "feature",
  },
  {
    groupName: "features.navigation",
    name: "navigationScreenSecurity",
    label: "features.labels.navigationScreenSecurity",
    type: "feature",
  },
  {
    groupName: "features.navigation",
    name: "navigationFloatingButtons",
    label: "features.labels.navigationFloatingButtons",
    type: "feature",
  },
  {
    groupName: "features.navigation",
    name: "navigationBanners",
    label: "features.labels.navigationBanners",
    type: "feature",
  },
  {
    groupName: "features.navigation",
    name: "navigationQrCamera",
    label: "features.labels.navigationQrCamera",
    type: "feature",
  },
  {
    groupName: "features.template",
    name: "templateGridNx3",
    label: "features.labels.templateGridNx3",
    type: "required",
  },
  {
    groupName: "features.template",
    name: "templateGridNx2",
    label: "features.labels.templateGridNx2",
    type: "feature",
  },
  {
    groupName: "features.template",
    name: "templateListBackgroundImage",
    label: "features.labels.templateListBackgroundImage",
    type: "feature",
  },
  {
    groupName: "features.template",
    name: "templateListLeftIcon",
    label: "features.labels.templateListLeftIcon",
    type: "feature",
  },
  {
    groupName: "features.template",
    name: "templateListRightIcon",
    label: "features.labels.templateListRightIcon",
    type: "feature",
  },
  {
    groupName: "features.template",
    name: "templateListRightLeft",
    label: "features.labels.templateListRightLeft",
    type: "feature",
  },
  {
    groupName: "features.template",
    name: "templateBook",
    label: "features.labels.templateBook",
    type: "feature",
  },
  {
    groupName: "features.template",
    name: "templateListStepped",
    label: "features.labels.templateListStepped",
    type: "feature",
  },
  {
    groupName: "features.template",
    name: "templateGridNx2Header",
    label: "features.labels.templateGridNx2Header",
    type: "feature",
  },
  {
    groupName: "features.template",
    name: "templateGridList",
    label: "features.labels.templateGridList",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsGoScreen",
    label: "features.labels.actionsGoScreen",
    type: "required",
  },
  {
    groupName: "features.actions",
    name: "actionsModules",
    label: "features.labels.actionsModules",
    type: "required",
  },
  {
    groupName: "features.actions",
    name: "actionsContainer",
    label: "features.labels.actionsContainer",
    type: "required",
  },
  {
    groupName: "features.actions",
    name: "actionsGoLogin",
    label: "features.labels.actionsGoLogin",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsGoHome",
    label: "features.labels.actionsGoHome",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsGoTour",
    label: "features.labels.actionsGoTour",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsGoSettings",
    label: "features.labels.actionsGoSettings",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsGoPrivacyPolicy",
    label: "features.labels.actionsGoPrivacyPolicy",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsQrScanner",
    label: "features.labels.actionsQrScanner",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsWeb",
    label: "features.labels.actionsWeb",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsSearch",
    label: "features.labels.actionsSearch",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsStatusVisit",
    label: "features.labels.actionsStatusVisit",
    type: "feature",
  },
  {
    groupName: "features.actions",
    name: "actionsSearchVisit",
    label: "features.labels.actionsSearchVisit",
    type: "feature",
  },
  {
    groupName: "features.modules",
    name: "modulesInformation",
    label: "features.labels.modulesInformation",
    type: "required",
  },
  {
    groupName: "features.modules",
    name: "modulesVideo",
    label: "features.labels.modulesVideo",
    type: "feature",
  },
  {
    groupName: "features.modules",
    name: "modulesDocument",
    label: "features.labels.modulesDocument",
    type: "feature",
  },
  {
    groupName: "features.modules",
    name: "modulesGallery",
    label: "features.labels.modulesGallery",
    type: "feature",
  },
  {
    groupName: "features.modules",
    name: "modulesMap",
    label: "features.labels.modulesMap",
    type: "feature",
  },
  {
    groupName: "features.modules",
    name: "modulesQuestionEngine",
    label: "features.labels.modulesQuestionEngine",
    type: "feature",
  },
  {
    groupName: "features.modules",
    name: "modulesPersonalInformation",
    label: "features.labels.modulesPersonalInformation",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineLocalization",
    label: "features.labels.questionEngineLocalization",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineTrivia",
    label: "features.labels.questionEngineTrivia",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineSendResults",
    label: "features.labels.questionEngineSendResults",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineOpenType",
    label: "features.labels.questionEngineOpenType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineMultilineType",
    label: "features.labels.questionEngineMultilineType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineEmailType",
    label: "features.labels.questionEngineEmailType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineNumberType",
    label: "features.labels.questionEngineNumberType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineRadioType",
    label: "features.labels.questionEngineRadioType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineDateType",
    label: "features.labels.questionEngineDateType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEnginePasswordType",
    label: "features.labels.questionEnginePasswordType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineCheckboxType",
    label: "features.labels.questionEngineCheckboxType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEnginePhotoReportType",
    label: "features.labels.questionEnginePhotoReportType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineMapType",
    label: "features.labels.questionEngineMapType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineTelType",
    label: "features.labels.questionEngineTelType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineSignatureType",
    label: "features.labels.questionEngineSignatureType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineBarCodeType",
    label: "features.labels.questionEngineBarCodeType",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineSegmentation",
    label: "features.labels.questionEngineSegmentation",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineMassiveListUnique",
    label: "features.labels.questionEngineMassiveListUnique",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineMassiveListMultiSelection",
    label: "features.labels.questionEngineMassiveListMultiSelection",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineCheckList",
    label: "features.labels.questionEngineCheckList",
    type: "feature",
  },
  {
    groupName: "features.questionengine",
    name: "questionEngineFileType",
    label: "features.labels.questionEngineFileType",
    type: "feature",
  },
];

export const INSTANCE_FEATURES = [
  {
    "name": "api",
    "type": "Boolean"
  },
  {
    "name": "visits",
    "type": "Number"
  },
  {
    "name": "processRelationship",
    "type": "Number"
  },
  {
    "name": "soCounterReport",
    "type": "Number"
  },
  {
    "name": "geofences",
    "type": "Boolean"
  },
  {
    "name": "massiveList",
    "type": "Number"
  },
  {
    "name": "checklist",
    "type": "Number"
  },
  {
    "name": "sku",
    "type": "Boolean"
  },
  {
    "name": "pushNotification",
    "type": "Number"
  },
  {
    "name": "ponderationChecklist",
    "type": "Boolean"
  },
  {
    "name": "segments",
    "type": "Number"
  },
  {
    "name": "teams",
    "type": "Number"
  },
  {
    "name": "signUp",
    "type": "Boolean"
  },
  {
    "name": "offline",
    "type": "Boolean"
  },
  {
    "name": "pauseVisit",
    "type": "Boolean"
  },
  {
    "name": "activitiesVisit",
    "type": "Number"
  },
  {
    "name": "tickets",
    "type": "Number"
  },
  {
    "name": "locations",
    "type": "Boolean"
  },
  {
    "name": "dashboard",
    "type": "Boolean"
  },
  {
    "name": "programmableReport",
    "type": "Number"
  },
  {
    "name": "searchVisit",
    "type": "Boolean"
  },
  {
    "name": "visitScheduling",
    "type": "Boolean"
  },
  {
    "name": "pricing",
    "type": "Number"
  }
];