import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from '@material-ui/core/Button';
import * as yup from "yup";
import { Form, withFormik, Field } from "formik";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { hasError } from "../utils/forms/inputs";
import SendIcon from '@material-ui/icons/Send';

const emailSchema = yup.object().shape({
    email: yup.string().email().required()
});

const ConfirmEmailTaskModal = ({
  openModalEmail,
  setOpenModalEmail,
  submitForm,
  errors,
  touched,
}) => {
  const { t } = useTranslation("todolist");

  return (
    <Dialog
      open={openModalEmail}
      keepMounted
      onClose={setOpenModalEmail}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle id="lert-dialog-title">
        <GridContainer>
          <GridItem xs={2}>
            <SendIcon style={styles.icon} />
          </GridItem>
          <GridItem xs={10}>
            <h4>{t("todolist.modal.reportEmail.title")}</h4>
          </GridItem>
        </GridContainer>
      </DialogTitle>
      <DialogContent>
        <Form>
          <GridContainer>
            <GridItem xs={12}>
              <Field
                name="email"
                render={({ field }) => {
                  return (
                    <CustomInput
                      labelText={t("todolist.forms.fields.email.label")}
                      id="email"
                      type={"text"}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        ...field,
                      }}
                      success={!hasError(errors, "email") && touched.email}
                      error={hasError(errors, "email")}
                    />
                  );
                }}
              />
            </GridItem>
          </GridContainer>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenModalEmail(false)} color="primary">
          {t("todolist.finishTask")}
        </Button>
        <Button
          onClick={() => {
            submitForm();
          }}
          color="primary"
          autoFocus
        >
          {t("todolist.finishTaskEmail")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
    icon: {
        fontSize: '40px',
        textAlign: 'center',
        marginLeft: '50%',
        marginRight: '50%',
        marginTop: '10%'
    }
}

export default withFormik({
    validationSchema: emailSchema,
    mapPropsToValues: () => {
        return {
            email: ''
        }
    },
    handleSubmit: (values, {props, resetForm}) => {
        resetForm({ email: '' })
        props.sendEmail(values);
        props.setOpenModalEmail(false);
    }
})(ConfirmEmailTaskModal);