import React from "react";
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";

const MessageTable = ({ messages, config }) => {
  const { app, title, to, expirationDate } = config;
  return (
    <ReactTable
      data={messages}
      columns={[
        {
          Header: <DivCenter>Cliente</DivCenter>,
          accessor: "name",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10,
          show: app
        },
        {
          Header: <DivCenter>Titulo</DivCenter>,
          accessor: "title",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10,
          show: title
        },
        {
          Header: <DivCenter>Correos</DivCenter>,
          accessor: "to",
          Cell: d => {
            return d.original.to.join(", ");
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10,
          show: to
        },
        {
          Header: <DivCenter>Asunto</DivCenter>,
          accessor: "subject",
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10
        },
        {
          Header: <DivCenter>Fecha Expiración</DivCenter>,
          accessor: "expirationDate",
          Cell: d => {
            return moment(d.original.expirationDate)
              .local()
              .format("DD-MM-YY : hh:mm A");
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10,
          show: expirationDate
        },
        {
          Header: <DivCenter>Fecha de creación</DivCenter>,
          accessor: "created",
          Cell: d => {
            return moment(d.original.created)
              .local()
              .format("DD-MM-YY : hh:mm A");
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10
        },
        {
          Header: "",
          id: "actions",
          Cell: d => {
            return (
              <div>
                <Tooltip title="Ver mensaje">
                  <Link
                    to={{
                      pathname: `/message/content`,
                      state: {
                        message: d.original
                      }
                    }}
                  >
                    <Button justIcon round simple color="info">
                      <VisibilityIcon />
                    </Button>{" "}
                  </Link>
                </Tooltip>
                {!to ? (
                  <Tooltip title="Editar mensaje">
                    <Link
                      to={{
                        pathname: `/message/update`,
                        state: {
                          message: d.original
                        }
                      }}
                    >
                      <Button justIcon round simple color="info">
                        <EditIcon />
                      </Button>{" "}
                    </Link>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10
        }
      ]}
      defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

export default MessageTable;
