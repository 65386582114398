import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import indexesPageStyle from "assets/jss/material-dashboard-pro-react/views/indexesPageStyle.jsx";
import Position from 'components/Position';
import Button from 'components/Button';
import Apps from "@material-ui/icons/Apps";
import CircularProgress from '@material-ui/core/CircularProgress';
import history from '../../utils/history';
import AdminsTable from '../../tables/Admins/AdminsTable';
import TablePagination from '../../paginations/table/SimpleTaablePagination';


const AdminsView = (props) => {
    const { t } = useTranslation('instances');
    const { classes, data, loading, app, paginationAction, paginationOptions } = props;
    const [ users, setUsers ] = useState([]);

    const getData = async () => {
        if (data && data?.users) {
            const { users } = await data;
            setUsers(users);
        }
    }

    useEffect(()=>{
        getData();
     });

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <Position rigth>
              <Button
                color={"primary"}
                onClick={() => history.push(`/instances/invited/${app}`)}
              >
                {t("instances.users.invited")}
              </Button>
            </Position>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="info">
                  <Apps />
                </CardIcon>
                <h4>{t("instances.users.title")}</h4>
              </CardHeader>
              <CardBody>
                <TablePagination
                    data={users}
                    paginationAction={paginationAction}
                    filter={paginationOptions}
                  />
                {loading ? (
                  <div className={classes.progressCnt}>
                    <CircularProgress className={classes.progress} />
                  </div>
                ) : (
                    <AdminsTable data={users} />

                )}

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
}

export default compose(
    withStyles(indexesPageStyle)
)(AdminsView);
