import gql from "graphql-tag";

export const CREATE_PARTNER = gql`
  mutation CREATE_PARTNER($data: PartnerInput, $file: Upload) {
    createPartner(data: $data, file: $file ) {
      _id
      name
      logo
      logos
      address
      phone
      webPage
      city {
        cityName
        countryName
      }
      timezone {
        utc
        zone
      }
      location {
        type
        coordinates
      }
      isPartner
      archived
    }
  }
`;

export const UPDATED_PARTNER = gql`
  mutation UPDATED_PARTNER($data: PartnerInput, $file: Upload, $where: PartnerWhereUniqueInput) {
    updatePartner(data: $data, file: $file, where: $where, ) {
      _id
      name
      logo
      logos
      address
      phone
      webPage
      city {
        cityName
        countryName
      }
      timezone {
        utc
        zone
      }
      location {
        type
        coordinates
      }
      isPartner
      archived
    }
  }
`;