import React from 'react';
import { Form, withFormik, Field } from "formik";
import * as yup from "yup";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { useTranslation } from "react-i18next";
import { hasError } from '../../utils/forms/inputs';
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";
import ButtonIcon from "../../components/styled/ButtonIcon";
import { NavLink } from "react-router-dom";

const userFormSchema = yup.object().shape({
  password: yup.string().required(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required(),
});

const ResetPassowrdForm = (props) => {
    const { t } = useTranslation("users");
    const { errors, touched, submitForm } = props;

    return (
      <Form>
        <GridContainer justify="center">
          <GridItem xs={8}>
            <Field
              name="password"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText={t("users.forms.fields.password.label")}
                    id="password"
                    success={!hasError(errors, "password") && touched.password}
                    error={hasError(errors, "password")}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    inputProps={{
                      ...field,
                      type: "password",
                    }}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={8}>
            <Field
              name="passwordConfirm"
              render={({ field }) => {
                return (
                  <CustomInput
                    labelText={t("users.forms.fields.passwordConfirm.label")}
                    id="passwordConfirm"
                    success={
                      !hasError(errors, "passwordConfirm") &&
                      touched.passwordConfirm
                    }
                    error={hasError(errors, "passwordConfirm")}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    inputProps={{
                      ...field,
                      type: "password",
                    }}
                  />
                );
              }}
            />
          </GridItem>
          <GridItem xs={8} style={styles.marginTop}>
            <GridContainer justify="center">
              <GridItem xs={6}>
                <NavLink to={"/login"}>
                  <ButtonIcon icon={Cancel} label={t("users.cancel")} />
                </NavLink>
              </GridItem>
              <GridItem xs={6}>
                <ButtonIcon
                  icon={Save}
                  label={t("users.save")}
                  color={"info"}
                  onClick={() => {
                    submitForm();
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
    );
}
const styles = {
    marginTop: {
      marginTop: "2%",
      marginBottom: "2%",
    }
  };
const PasswordFormWF = withFormik({
    validationSchema: userFormSchema,
    mapPropsToValues: (props) => {
        return {
            password: '',
            passwordConfirm: ''           
        }
    },
    handleSubmit: (values, { props }) => {
        props.submitSuccess(values);
    }
})(ResetPassowrdForm);

export default PasswordFormWF;