import React from 'react';
import { Form, withFormik, Field } from "formik";
import * as yup from "yup";
import moment from 'moment-timezone';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { useTranslation } from "react-i18next";
import { hasError } from '../../utils/forms/inputs';
import SelectTo from 'react-select';
import SelectTimezone, { getTimezoneProps } from "@capaj/react-select-timezone";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
  } from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { compose, withProps, lifecycle } from "recompose";
import _ from "lodash";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";
import ButtonIcon from "../../components/styled/ButtonIcon";
import { NavLink } from "react-router-dom";
import ImageUploader from 'react-images-upload';

const partnerFormSchema = yup.object().shape({
    name: yup.string().required(),
	logo: yup.array().required(),
	address: yup.string().required(),
	phone: yup.string().required(),
	webPage: yup.string(),
	city: yup.object().required(),
	timezone: yup.object().shape({
        zone: yup.string(),
        utc: yup.string()
    }),
    location: yup.object().required(),

});

const PartnerForm = (props) => {
    const { errors, touched, setFieldValue, cities, submitForm, values } = props;
    const { t } = useTranslation("partners");

    return (
      <Form>
        <GridContainer justify="center">
          <GridItem xs={6}>
            <GridContainer justify="center">
              <GridItem xs={8}>
                <Field
                  name="name"
                  render={({ field }) => {
                    return (
                      <CustomInput
                        labelText={t("partners.forms.fields.name.label")}
                        id="name"
                        type={"text"}
                        success={!hasError(errors, "name") && touched.name}
                        error={hasError(errors, "name")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          ...field,
                        }}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={8}>
                <Field
                  name="address"
                  render={({ field }) => {
                    return (
                      <CustomInput
                        labelText={t("partners.forms.fields.address.label")}
                        id="address"
                        type={"text"}
                        success={!hasError(errors, "address") && touched.name}
                        error={hasError(errors, "address")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{ ...field }}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={8}>
                <Field
                  name="phone"
                  render={({ field }) => {
                    return (
                      <CustomInput
                        labelText={t("partners.forms.fields.phone.label")}
                        id="phone"
                        type={"text"}
                        success={!hasError(errors, "phone") && touched.phone}
                        error={hasError(errors, "phone")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{ ...field }}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={8}>
                <Field
                  name="webPage"
                  render={({ field }) => {
                    return (
                      <CustomInput
                        labelText={t("partners.forms.fields.webPage.label")}
                        id="webPage"
                        type={"text"}
                        success={
                          !hasError(errors, "webPage") && touched.webPage
                        }
                        error={hasError(errors, "webPage")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{ ...field }}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={8} style={styles.marginTop}>
                <Field
                  name="city"
                  render={({ field }) => {
                    return (
                      <SelectTo
                        value={field.value}
                        onChange={(selectedOption) => {
                          setFieldValue("city", selectedOption);
                        }}
                        options={cities ? cities : []}
                        getOptionLabel={(option) => option.cityName}
                        getOptionValue={(option) => option}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={8} style={styles.marginTop}>
                <Field
                  name="timezone"
                  render={({ field }) => {
                    return (
                      <SelectTimezone
                        value={field.value.zone}
                        isClearable
                        guess
                        onChange={(val) => {
                          const timezoneProps = getTimezoneProps(val);
                          setFieldValue("timezone", {
                            zone: val,
                            utc: timezoneProps.time,
                          });
                        }}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={8}>
                <Field
                  name={"logos"}
                  render={({ field }) => {
                    if(values.logos){
                        return (
                            <ImageUploader
                              defaultImages={Array.isArray(values.logos) ? values.logos[480] : [values.logos[480]]}
                              withIcon={true}
                              singleImage={true}
                              withLabel={false}
                              withPreview={true}
                              buttonText={t("partners.forms.fields.logo.label")}
                              onChange={(image, pictures) => {
                                setFieldValue("logo", image);
                                setFieldValue("logos[480]", pictures);
                              }}
                              imgExtension={[".jpg", ".png", ".jpeg"]}
                              maxFileSize={5242880}
                              buttonStyles={hasError(errors, "logo") ? styles.error : {}}
                            />
                          );
                    }else{
                        return (
                            <ImageUploader
                              withIcon={true}
                              singleImage={true}
                              withLabel={false}
                              withPreview={true}
                              buttonText={t("partners.forms.fields.logo.label")}
                              onChange={(image) => {
                                setFieldValue("logo", image);
                              }}
                              imgExtension={[".jpg", ".png", ".jpeg"]}
                              maxFileSize={5242880}
                              buttonStyles={hasError(errors, "logo") ? styles.error : {}}
                            />
                          );
                    }
                    
                  }}
                />
              </GridItem>
              <GridItem xs={8} style={styles.marginTop}>
                <GridContainer>
                  <GridItem xs={6}>
                    <NavLink to={"/partners/list"}>
                      <ButtonIcon icon={Cancel} label={t("instances.cancel")} />
                    </NavLink>
                  </GridItem>
                  <GridItem xs={6}>
                    <ButtonIcon
                      icon={Save}
                      label={t("instances.save")}
                      color={"info"}
                      onClick={() => {
                        submitForm();
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6}>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <Field
                  name="location"
                  render={({ field }) => {
                    return (
                      <GoogleMaps
                        placeholder={t("partners.forms.fields.location.search")}
                        setFieldValue={setFieldValue}
                        location={field.value}
                      />
                    );
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
    );
}

const styles ={
    marginTop: {
        marginTop: '4%'
    },
    error: {
        backgroundColor: "red"
    }
}
const GoogleMaps = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDe9IxqJ9JxwmqtVVeKpJozUpNXytWLgLw&v=3&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `600px` }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    lifecycle({
      componentWillMount() {
        const refs = {};
        const { setFieldValue, location } = this.props;
        this.setState({
          bounds: null,
          center: {
            lat: location.coordinates[1],
            lng: location.coordinates[0]
          },
          markers: [
            {
              position: {
                lat: location.coordinates[1],
                lng: location.coordinates[0]
              }
            }
          ],
          onMapMounted: ref => {
            refs.map = ref;
          },
          onMapClick: e => {
            const nextMarkers = [{ position: e.latLng }];
  
            this.setState({
              markers: nextMarkers
            });
  
            setFieldValue("location", {
              coordinates: [
                nextMarkers[0].position.lng(),
                nextMarkers[0].position.lat()
              ]
            });
            
          },
          onBoundsChanged: _.debounce(() => {
            if (refs.map) {
              this.setState({
                bounds: refs.map.getBounds(),
                center: refs.map.getCenter()
              });
            }
            
          },100,{ maxWait: 500 }),
          onSearchBoxMounted: ref => {
            refs.searchBox = ref;
          },
          onPlacesChanged: () => {
            const places = refs.searchBox.getPlaces();
            const bounds = new window.google.maps.LatLngBounds();
  
            places.forEach(place => {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            const nextMarkers = places.map(place => ({
              position: place.geometry.location
            }));
  
            const nextCenter = _.get(
              nextMarkers,
              "0.position",
              this.state.center
            );
  
            setFieldValue("location", {
              coordinates: [
                nextMarkers[0].position.lng(),
                nextMarkers[0].position.lat()
              ]
            });
  
            this.setState({
              center: nextCenter,
              markers: nextMarkers
            });
            // refs.map.fitBounds(bounds);
          }
        });
      }
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={15}
      center={props.center}
      onBoundsChanged={props.onBoundsChanged}
      onClick={props.onMapClick}
    >
      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder={props.placeholder}
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            marginTop: `20px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`
          }}
        />
      </SearchBox>
      {props.markers.map((marker, index) => (
        <Marker key={index} position={marker.position} />
      ))}
    </GoogleMap>
  ));

const PartnerFormWF = withFormik({
    validationSchema: partnerFormSchema,
    mapPropsToValues: (props) => {
        const { partner } = props;
        const { name, logo, logos, address, phone, webPage, city, timezone, location } = partner;

        return {
          name: name ? name : "",
          logo: logo ? logo : "",
          logos: logos ? logos : null,
          address: address ? address : "",
          phone: phone ? phone : "",
          webPage: webPage ? webPage : "",
          city: city
            ? city
            : {
                id: "53ca9109745628ea47b6b458",
                timezone: "",
                continentName: "North America",
                geonameId: "3530597",
                continentCode: "NA",
                countryIsoCode: "MX",
                subdivisionName: "The Federal District",
                cityName: "Mexico City",
                countryName: "Mexico",
              },
          timezone: timezone
            ? timezone
            : {
                zone: moment.tz.guess(),
                utc: getTimezoneProps(moment.tz.guess()).time,
              },
          location: location
            ? location
            : {
                coordinates: [-99.138498, 19.414254],
                type: 'Point'
              }
        };
        

    },
    handleSubmit: (values, { props }) => {
        props.submitSuccess(values);
    }
})(PartnerForm);

export default PartnerFormWF;
