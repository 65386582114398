// @material-ui/icons
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Timeline from "@material-ui/icons/Timeline";
import Apps from "@material-ui/icons/Apps";

import Home from "../containers/Home";
import ActivityIndexes from "../containers/Indexes/";
import Demos from "../containers/Instances/demos";
import NewInstance from "../containers/Instances/NewInstance";
import Oportunity from "../containers/Instances/Oportunity";
import EditInstance from "../containers/Instances/EditInstance";
import Admins from "../containers/Admins/Admins";
import AddUser from "../containers/Admins/AddUser";
import Subscriptions from "../containers/Instances/Subscriptions";
import NewSubscription from "../containers/Instances/NewSubscription";
import EditSubscription from "../containers/Instances/EditSubscription";
import EmailMessages from "../containers/Messages/EmailMessages";
import GeneralMessages from "../containers/Messages/GeneralMessages";
import CustomMessages from "../containers/Messages/CustomMessages";
import GeneralMessagesList from "../containers/Messages/GeneralMessagesList";
import CustomMessagesList from "../containers/Messages/CustomMessagesList";
import EmailMessagesList from "../containers/Messages/EmailMessagesList";
import MessageContent from "../containers/Messages/MessageContent";
import UpdateMessage from "../containers/Messages/UpdateMessage";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TutorialsCategoryList from "../containers/Tutorials/TutorialsCategoryList";
import TutorialCategory from "../containers/Tutorials/TutorialCategory";
import UpdateTutorialCategory from "../containers/Tutorials/UpdateTutorialCategory";
import TutorialInfoList from "../containers/Tutorials/TutorialInfoList";
import TutorialInfo from "../containers/Tutorials/TutorialInfo";
import UpdateTutorialInfo from "../containers/Tutorials/UpdateTutorialInfo";
import FormatShapesIcon from "@material-ui/icons/FormatShapes";
import GeofenceGroup from "../containers/Geofence/GeofenceGroup";
import NewGeofence from "../containers/Geofence/NewGeofence";
import AddGeofence from "../containers/Geofence/AddGeofence";
import GeofenceRecord from "../containers/Geofence/GeofenceRecord";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Partners from "../containers/Partners/Partners";
import NewPartner from "../containers/Partners/NewPartner";
import EditPartner from "../containers/Partners/EditPartner";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Roles from "../containers/Users/Roles";
import Users from "../containers/Users/Users";
import NewUser from "../containers/Users/NewUser";
import EditUser from "../containers/Users/EditUser";
import ToDoList from "../containers/ToDoList/ToDoList";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import PlansList from "../containers/Plans/PlansList";
import AddPlanInstance from "../containers/Plans/AddPlanInstance";
import SupportVisitOperations from "../containers/Support/SupportVisitOperations";

const dashRoutes = [
  {
    path: "/indexes",
    name: "Indicadores",
    id: "indicators-menu",
    state: "openIndexes",
    icon: Timeline,
    component: ActivityIndexes,
    permission: "indexesView",
  },
  {
    collapse: true,
    path: "/instances",
    name: "Instancias",
    state: "openInstances",
    id: "instances-menu",
    icon: Apps,
    views: [
      {
        path: "/instances/demos",
        name: "Demos",
        mini: "ID",
        id: "demos-submenu",
        component: Demos,
        show: true,
        permission: "demoView",
      },
      {
        path: "/instances/opportunity",
        name: "Oportunidades",
        mini: "IO",
        id: "opportunities-submenu",
        component: Oportunity,
        show: true,
        permission: "opportunityView",
      },
      {
        path: "/instances/subscriptions",
        name: "Suscripciones",
        mini: "IS",
        id: "subscriptions-submenu",
        component: Subscriptions,
        show: true,
        permission: "subscriptionView",
      },
      {
        path: "/instances/new",
        name: "Nueva Instancia",
        component: NewInstance,
        show: false,
      },
      {
        path: "/instances/subscription/new/:id",
        name: "Nueva subscripción",
        component: NewSubscription,
        show: false,
      },
      {
        path: "/instances/subscription/edit/:id",
        name: "Editar subscripción",
        component: EditSubscription,
        show: false,
      },
      {
        path: "/instances/edit/:instance/:id",
        name: "Editar Instancia",
        component: EditInstance,
        show: false,
      },
      {
        path: "/instances/invited/:app",
        name: "Agregar usuario",
        component: AddUser,
        show: false,
      },
      {
        path: "/instances/users/:app",
        name: "Usuarios",
        component: Admins,
        show: false,
      },
      {
        path: "/instances/subscription/:app/toDo",
        name: "Actividades",
        component: ToDoList,
        show: false,
      },
      {
        path: "/instances/:app/plan",
        name: "Plan",
        component: AddPlanInstance,
        show: false,
      },
    ],
  },
  {
    path: "/plans",
    name: "Planes",
    state: "openPlans",
    icon: CardMembershipIcon,
    component: PlansList,
    permission: "plansGlobal",
  },
  {
    collapse: true,
    path: "/partners",
    name: "Partners",
    id: "partners",
    state: "openPartners",
    icon: SupervisorAccountIcon,
    views: [
      {
        path: "/partners/list",
        name: "Partners",
        mini: "P",
        component: Partners,
        show: true,
        permission: "partnerView",
      },
      {
        path: "/partners/new",
        name: "Nuevo Partner",
        mini: "NP",
        component: NewPartner,
        show: false,
        permission: "partnerCreate",
      },
      {
        path: "/partners/edit/:id",
        name: "Editar Partner",
        mini: "EP",
        component: EditPartner,
        show: false,
        permission: "partnerUpdate",
      },
    ],
  },
  {
    collapse: true,
    path: "/geofence-group",
    name: "Geo-Cercas",
    state: "openGeofences",
    icon: FormatShapesIcon,
    views: [
      {
        path: "/geofence-group/list",
        name: "Geo-cercas",
        mini: "GC",
        component: GeofenceGroup,
        show: true,
        permission: "geofenceView",
      },
      {
        path: "/geofence-group/reportes",
        name: "Reportes",
        mini: "GR",
        component: GeofenceRecord,
        show: true,
        permission: "geofenceBuy",
      },
      {
        path: "/geofence-group/edit/:id",
        name: "new-geo-cercas",
        mini: "GC",
        component: NewGeofence,
        show: false,
      },
      {
        path: "/geofence-group/add",
        name: "add-geo-cercas",
        mini: "GC",
        component: AddGeofence,
        show: false,
      },
    ],
  },
  {
    collapse: true,
    path: "/users",
    name: "Usuarios",
    state: "openUser",
    icon: GroupAddIcon,
    views: [
      {
        path: "/users/list",
        name: "Usuarios",
        mini: "U",
        component: Users,
        show: true,
        permission: "userView",
      },
      {
        path: "/users/roles",
        name: "Roles",
        mini: "R",
        component: Roles,
        show: true,
        permission: "roleView",
      },
      {
        path: "/users/new",
        name: "Usuario",
        mini: "U",
        component: NewUser,
        show: false,
      },
      {
        path: "/users/edit",
        name: "Usuario",
        mini: "U",
        component: EditUser,
        show: false,
      },
    ],
  },
  {
    collapse: true,
    path: "/message",
    name: "Mensajes",
    id: "messages-menu",
    state: "openMessages",
    icon: MailOutlineIcon,
    views: [
      {
        path: "/message/generalList",
        name: "Mensaje Generales",
        component: GeneralMessagesList,
        mini: "MG",
        id: "messages-general-submenu",
        show: true,
        permission: "messageGeneralView",
      },
      {
        path: "/message/customList",
        name: "Mensaje Personalizados",
        component: CustomMessagesList,
        mini: "MP",
        id: "messages-custom-submenu",
        show: true,
        permission: "messageCustomView",
      },
      {
        path: "/message/emailList",
        name: "Enviar Correo",
        component: EmailMessagesList,
        mini: "EC",
        id: "emails-send-submenu",
        show: true,
        permission: "messageEmailView",
      },
      {
        path: "/message/general",
        name: "Lista Mensaje Generales",
        component: GeneralMessages,
        mini: "MG",
        show: false,
      },
      {
        path: "/message/custom",
        name: "Mensaje Personalizado",
        component: CustomMessages,
        mini: "MP",
        show: false,
      },
      {
        path: "/message/email",
        name: "Enviar Correo",
        component: EmailMessages,
        mini: "EC",
        show: false,
      },
      {
        show: false,
        path: "/message/content",
        name: "Detalle de Mensaje",
        mini: "DM",
        component: MessageContent,
      },
      {
        show: false,
        path: "/message/update",
        name: "Detalle de Mensaje",
        mini: "UM",
        component: UpdateMessage,
      },
    ],
  },
  {
    collapse: true,
    path: "/support",
    name: "Soporte",
    id: "support-menu",
    state: "openSupport",
    icon: AssignmentIcon,
    views: [
      {
        path: "/support/visit-operations",
        name: "Visit Operations",
        component: SupportVisitOperations,
        mini: "VO",
        id: "visit-operations-submenu",
        show: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/tutorials",
    name: "Tutoriales",
    id: "tutorials-menu",
    state: "openTutorials",
    icon: AssignmentIcon,
    views: [
      {
        path: "/tutorials/categoriesList",
        name: "Categorias de Videos",
        component: TutorialsCategoryList,
        mini: "CV",
        id: "categories-submenu",
        show: true,
        permission: "categoryVideoView",
      },
      {
        path: "/tutorials/category/new",
        name: "Nueva Categoria",
        component: TutorialCategory,
        mini: "NV",
        show: false,
      },
      {
        path: "/tutorials/category/edit",
        name: "Edición de Categoria",
        component: UpdateTutorialCategory,
        mini: "EC",
        show: false,
      },
      {
        path: "/tutorials/tutorials",
        name: "Lista de Tutoriales",
        component: TutorialInfoList,
        mini: "LT",
        id: "tutorials-list-submenu",
        show: true,
        permission: "tutorialsView",
      },
      {
        path: "/tutorials/tutorial/new",
        name: "Nuevo Tutorial",
        component: TutorialInfo,
        mini: "NT",
        show: false,
      },
      {
        path: "/tutorials/tutorial/edit",
        name: "Edición de Tutorial",
        component: UpdateTutorialInfo,
        mini: "ET",
        show: false,
      },
    ],
  },
  { redirect: true, path: "/", pathTo: "/indexes", name: "Home" },
];

export default dashRoutes;
