import React, { useEffect, useRef, useState } from "react";

import { TextField } from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import Button from "../../../components/CustomButtons/Button";

const TextFieldContentWidth = ({ value, onChange, endEdit, type }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const formatValue = {
    "number": (value) => {
      return value ? (value.toString().includes('.') ? parseFloat(value) : parseInt(value)) : 0;
    },
    "text": (value) => {
      return value || '';
    }
  }

  return (
    <React.Fragment>
      <TextField
        inputRef={inputRef}
        defaultValue={currentValue}
        value={currentValue}
        type={type}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          style: {
            textAlign: "center",
            width: `${currentValue.toString().length * 10 + 20}px`,
          },
        }}
        onChange={(e) => {
          setCurrentValue(formatValue[type](e?.target?.value));
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            const updateValue = formatValue[type](e?.target?.value);
            onChange(updateValue);
            endEdit();
            e.preventDefault();
          }
        }}
      />
      <Button
        justIcon
        round
        simple
        color="success"
        onClick={() => {
          onChange(formatValue[type](currentValue));
          endEdit();
        }}
      >
        <CheckCircleIcon />
      </Button>
    </React.Fragment>
  );
};

export default React.memo(TextFieldContentWidth);
