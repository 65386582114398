import React, { useState } from "react";
import UpdateMessageView from "../../views/Messages/UpdateMessageView";
import useGlobal from "../../store";
import { useQuery, useMutation } from "react-apollo-hooks";
import { APPS_FEED, USERS_FEED } from "./gql/Queries";
import { UPDATE_MESSAGE_MUTATION } from "./gql/Mutation";
import history from '../../utils/history';
import auth from '../../utils/auth';

const UpdateMessage = ({ location }) => {
  const { state } = location;
  const [{session}, globalActions] = useGlobal();
  const [apps] = useState({
    where: {
      name: "",
      status: "",
      created: 0,
      dateIn: "",
      dateEnd: ""
    }
  });

  const defaultWhere = {
    app: state.message.app
  }
  const [users, setUsers] = useState({
    where : state.message.app ? defaultWhere : {}
  });

  const { data: dataApps, loading: loadingApps } = useQuery(APPS_FEED, {
    variables: apps,
    fetchPolicy: "network-only"
  });

  const { data: dataUsers, loading: loadingUsers } = useQuery(USERS_FEED, {
    variables: users,
    fetchPolicy: "network-only"
  });

  async function setUserFilter(app) {
    setUsers({ where: { app: app } });
  }

  const updateMessage = useMutation(UPDATE_MESSAGE_MUTATION);

  if (
    session.user &&
    !auth.hasPermission("updateMessage", session.user.role.permissions)
  ) {
    globalActions.notification.openNotification("tr", {
      message: "No tienes permisos",
      color: "danger",
    });

    history.push('/');
  } 

  return (
    <UpdateMessageView
      message={state.message}
      apps={{ loading: loadingApps, data: dataApps }}
      users={{ loadingUsers: loadingUsers, dataUsers: dataUsers }}
      setUserFilter={setUserFilter}
      submitSuccess={async (values) => {
        let variables = {
          data: {
            ...values
          },
          where: {
            _id: state.message._id
          }
          
        }
        
        const resultMutation = await updateMessage({ variables: variables});
        const { loading, error } = resultMutation;

        if (!loading) {
          if (!error) {
            history.goBack();
            // Add success notification
            globalActions.notification.openNotification(
              "tr",
              {
                message: "Mensaje Editado",
                color: "success"
              }
            );
          }else{
            globalActions.notification.openNotification('tr', {
                message: 'Error al editar mensaje',
                color: 'danger'
            });
          }
        }
      }}
    />
  );
};

export default UpdateMessage;
