import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import CloseOutlined from "@material-ui/icons/CloseOutlined";
import { Button, Modal, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ReactTable from "react-table";
import Datetime from "react-datetime";

import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  ALL_APPS,
  APP_USERS,
  APP_VISITS,
  USER_VISIT_ANSWERS,
} from "./gql/Queries";
import { VISIT_ANSWER_CLOSE } from "./gql/Mutations";

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const SupportVisitOperations = () => {
  const classes = useStyles();

  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract("months", 1).startOf("day").toDate()
  );
  const [endDate, setEndDate] = useState(moment().startOf("day").toDate());
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [selectedVisitAnswer, setSelectedVisitAnswer] = useState(null);
  const [closeVisitModalOpen, setCloseVisitModalOpen] = useState(false);

  const { data: dataApps, loading: loadingApps } = useQuery(ALL_APPS, {
    fetchPolicy: "network-only",
  });
  const [
    getVisits,
    { data: dataVisits, loading: loadingVisits, refetch: refetchVisits },
  ] = useLazyQuery(APP_VISITS, {
    fetchPolicy: "network-only",
  });
  const [getUsers, { data: dataUsers, loading: loadingUsers }] = useLazyQuery(
    APP_USERS,
    {
      fetchPolicy: "network-only",
    }
  );
  const [
    getVisitAnswers,
    {
      data: dataVisitAnswers,
      loading: loadingVisitAnswers,
      refetch: refetchVisitAnswers,
    },
  ] = useLazyQuery(USER_VISIT_ANSWERS, {
    fetchPolicy: "network-only",
  });
  const [visitAnswerClose] = useMutation(VISIT_ANSWER_CLOSE, {
    onCompleted: (mutationData) => {
      refetchVisitAnswers();
    },
  });

  useEffect(() => {
    setSelectedVisit(null);
    setSelectedUser(null);
  }, [selectedApp]);

  useEffect(() => {
    if (
      !!selectedApp &&
      !!selectedUser &&
      !!selectedVisit &&
      startDate &&
      endDate
    ) {
      getVisitAnswers({
        variables: {
          appId: selectedApp._id,
          userId: selectedUser._id,
          visitId: selectedVisit._id,
          checkInDate_gte: startDate.toISOString(),
          checkInDate_lte: endDate.toISOString(),
        },
      });
    }
  }, [selectedUser, selectedVisit, startDate, endDate]);

  const resultsTable = useMemo(() => {
    if (!dataVisitAnswers?.visitAnswers) {
      return { data: [], columns: [] };
    }
    const keyColumns = selectedVisit.keys.map((key) => ({
      Header: `${key}`,
      Cell: ({ original }) => original?.moduleAnswers[key]?.toString() || "",
      sortable: false,
      filterable: false,
    }));
    return {
      data: dataVisitAnswers?.visitAnswers || [],
      columns: [
        {
          Header: "Check In",
          Cell: ({ original }) =>
            original.checkInDate
              ? moment(original.checkInDate).format("MMMM Do YYYY, h:mm:ss a")
              : "",
          sortable: false,
          filterable: false,
        },
        {
          Header: "Check Out",
          Cell: ({ original }) =>
            original.checkOutDate
              ? moment(original.checkOutDate).format("MMMM Do YYYY, h:mm:ss a")
              : "",
          sortable: false,
          filterable: false,
        },
        ...keyColumns,
        {
          Header: "Check Out",
          Cell: ({ original }) => {
            if (!original.checkOutDate) {
              return (
                <React.Fragment>
                  <Button
                    onClick={() => {
                      setSelectedVisitAnswer(original);
                      setCloseVisitModalOpen(true);
                    }}
                  >
                    <CloseOutlined></CloseOutlined>
                  </Button>
                </React.Fragment>
              );
            } else {
              return [];
            }
          },
          sortable: false,
          filterable: false,
        },
      ],
    };
  }, [dataVisitAnswers?.visitAnswers, loadingVisitAnswers]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>Filtros</CardHeader>
        {loadingApps ? (
          []
        ) : (
          <CardBody>
            <GridContainer>
              <GridItem xs={4}>
                <Select
                  onChange={(selectedOption) => {
                    setSelectedApp(selectedOption);
                    getVisits({
                      variables: {
                        appId: selectedOption._id,
                      },
                    });
                  }}
                  options={dataApps.getAllApps}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                />
              </GridItem>
              <GridItem xs={4}>
                {!loadingVisits ? (
                  <Select
                    onChange={(selectedOption) => {
                      setSelectedVisit(selectedOption);
                    }}
                    options={dataVisits?.visits || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                  />
                ) : (
                  []
                )}
              </GridItem>
              <GridItem xs={4}>
                {!loadingVisits ? (
                  <Select
                    onChange={(selectedOption) => {
                      setSelectedUser(selectedOption);
                    }}
                    onInputChange={(inputValue) => {
                      if (selectedApp && !!inputValue) {
                        getUsers({
                          variables: {
                            appId: selectedApp._id,
                            email_contains: inputValue,
                          },
                        });
                      }
                    }}
                    options={dataUsers?.usersMobile || []}
                    getOptionLabel={(option) =>
                      `${option.email} - ${option.lastName} ${option.firstName}`
                    }
                    getOptionValue={(option) => option._id}
                  />
                ) : (
                  []
                )}
              </GridItem>
              <GridItem xs={4}>
                <Datetime
                  name={"startDate"}
                  timeFormat={false}
                  value={startDate}
                  onChange={(e) => {
                    if (e === "") {
                      setStartDate(null);
                    } else {
                      setStartDate(moment(e._d).startOf("day").toDate());
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={4}>
                <Datetime
                  name={"endDate"}
                  timeFormat={false}
                  value={endDate}
                  onChange={(e) => {
                    if (e === "") {
                      setEndDate(null);
                    } else {
                      setEndDate(moment(e._d).startOf("day").toDate());
                    }
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        )}
      </Card>
      <Card>
        <CardHeader>Resultados</CardHeader>
        <CardBody>
          <ReactTable
            data={resultsTable?.data || []}
            columns={resultsTable?.columns || []}
            defaultPageSize={10}
            pageSize={resultsTable?.data?.length || 0}
            showPaginationBottom={false}
            className="-striped -highlight"
          />
        </CardBody>
      </Card>
      <Modal
        open={closeVisitModalOpen}
        onClose={() => {
          setCloseVisitModalOpen(false);
        }}
      >
        <Card style={getModalStyle()} className={classes.paper}>
          <CardBody>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                style={{
                  flex: "1 1 auto",
                }}
              >
                Selecciona la fecha de cierre para la visita.
                <Datetime
                  name={"checkOutDate"}
                  timeFormat={true}
                  value={checkOutDate}
                  onChange={(e) => {
                    if (e === "") {
                      setCheckOutDate(null);
                    } else {
                      setCheckOutDate(moment(e._d).toDate());
                    }
                  }}
                />
              </div>
              <div
                style={{
                  flex: "0 1 auto",
                }}
              >
                <Button
                  onClick={() => {
                    visitAnswerClose({
                      variables: {
                        data: {
                          _id: selectedVisitAnswer._id,
                          visitId: selectedVisit._id,
                          appId: selectedApp._id,
                          userId: selectedUser._id,
                          checkOutDate: checkOutDate,
                        },
                      },
                    });
                    setCloseVisitModalOpen(false);
                  }}
                >
                  Aceptar
                </Button>
                <Button
                  onClick={() => {
                    setCloseVisitModalOpen(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    height: 250,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));

export default SupportVisitOperations;
