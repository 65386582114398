import { gql } from "apollo-boost";

export const CREATE_INSTANCE_MUTATION = gql`
  mutation CreateInstance($data: InstancesCreateInput!, $file: Upload!) {
    createInstance(data: $data, file: $file) {
        id
        name
        description
        instanceType
        instanceStatus
    }
  }
`;

export const UPDATE_INSTANCE = gql`
  mutation UpdateInstance($data: InstancesUpdateInput!, $instanceId: InstanceWhereUniqueInput!, $file: Upload) {
    updateInstance(data: $data, where: $instanceId, file: $file) {
      id
      name
      description
      instanceType
      instanceStatus
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($data: InstancesUpdateInput!, $instanceId: InstanceWhereUniqueInput!, $features: InstanceFeatureInput, $file: File) {
    createSubscription(data: $data, where: $instanceId, features: $features, file: $file) {
      id
      name
      description
      instanceType
      instanceStatus
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($data: InstancesUpdateInput!, $instanceWhere: InstanceWhereSubscriptionInput!, $features: InstanceFeatureInput, $file: File) {
    updateSubscription(data: $data, where: $instanceWhere, features: $features, file: $file) {
      id
      name
      description
      instanceType
      instanceStatus
    }
  }
`;

export const UPSERT_REALTIME_LOCATION_CONFIG =gql`
  mutation upsertRealtimeLocationConfig($data: RealtimeLocationConfigInput!) {
    upsertRealtimeLocationConfig(data: $data){
      _id
      created
    }
  }
`;

export const UPDATE_APP_TOKEN = gql`
  mutation UpdateAppToken($data: UpdateAppTokeninput!) {
    updateAppToken(data: $data) {
      name
      token
    }
  }
`;

export const ARCHIVED_ALL_APP_TOKENS = gql`
  mutation ArchivedAllAppTokens($data: InstanceWhereUniqueInput!){
    archivedAllApptoken(data: $data) {
      name
      token
    }
  }
`;

export const ARCHIVED_INSTANCE = gql`
  mutation archivedInstance($data: InstancesUpdateInput $where: InstanceWhereUniqueInput){
    archivedInstance(data: $data, where: $where) {
      id
      name
    }
  }
`;
