import React from 'react';
import { useMutation } from 'react-apollo-hooks';
import ResetPasswordView from '../../views/Users/ResetPasswordView';
import useGlobal from '../../store';
import { RESET_PASSWORD_MUTATION } from '../Login/Mutations';
import history from '../../utils/history';

const ResetPassword = (props) => {
  const [session, globalActions] = useGlobal(
    (state) => state.session,
    (actions) => actions
  );

  const updateUser = useMutation(RESET_PASSWORD_MUTATION);

  return (
    <ResetPasswordView
      submitSuccess={async (values) => {
        delete values.passwordConfirm;

        const variables = {
          data: {
            ...values,
            resetPassword: false,
          },
          where: {
            _id: session.session.user._id,
          },
        };

        const resultMutation = await updateUser({ variables: variables });
          if (!resultMutation.loading) {
            if (!resultMutation.error) {
              history.push('login');

            } else {
              globalActions.notification.openNotification("tr", {
                message: "Error al actualizar usuario",
                color: "danger",
              });
            }
          }
      }}
    />
  );
}

export default ResetPassword;
