import React, { useEffect, useState } from "react";
import EmailMessagesListView from "../../views/Messages/EmailMessagesListView";
import { useQuery } from "react-apollo-hooks";
import { MESSAGES_APP_FEED } from "./gql/Queries";
import useGlobal from "../../store";
import history from "../../utils/history";
import auth from "../../utils/auth";

const EmailMessagesList = () => {
  const [{ session }, globalActions] = useGlobal();
  const [messagesEmails, setMessageEmails] = useState({
    where: {
      to: true
    },
    skip: 0,
    first: 15
  });

  useEffect(() => {
    const { user } = session;
    if (user) {
      
      if (user.role.master) {
        setMessageEmails({ ...messagesEmails, where: { ...messagesEmails.where }});
      }else{
        setMessageEmails({ ...messagesEmails, where: { ...messagesEmails.where,  franchise: user.franchise  }});
      }
    }
    
  }, [session]);

  const paginationAction = (values) => {
    setMessageEmails(values);
  }

  const { data: dataMessageEmails, loading: loadingMessageEmails } = useQuery(
    MESSAGES_APP_FEED,
    {
      variables: messagesEmails,
      fetchPolicy: "network-only",
    }
  );

  if (
    session.user &&
    !auth.hasPermission("messageEmailView", session.user.role.permissions)
  ) {
    history.push("dashboard");
  }
  return (
    <EmailMessagesListView
      messages={{ loading: loadingMessageEmails, data: dataMessageEmails }}
      paginationAction={paginationAction}
      filter={messagesEmails}
    />
  );
};

export default EmailMessagesList;
