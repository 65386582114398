import React from "react";
import ReactTable from "react-table";
import DivCenter from "../../components/styled-components/DivCenter";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";

const TutorialsInfoTable = ({ tutorials }) => {
  //const [ allTutorials, setAllTutorials ] = useState(tutorials);


  return (
    <ReactTable
      data={tutorials}
      columns={[
        {
          Header: <DivCenter>Nombre</DivCenter>,
          accessor: "name",
          filterable: false,
          sortable: false,
          style: { textAlign: "left" },
          minWidth: 10,
        },
        {
          Header: <DivCenter>Categoria</DivCenter>,
          accessor: "category",
          Cell: d => {
            if (d.original.category !== null) {
              return d.original.category.name;
            }else{
              return 'Manual';
            }
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "left" },
          minWidth: 10,
        },
        {
          Header: <DivCenter>Descripción</DivCenter>,
          accessor: "description",
          filterable: false,
          sortable: false,
          style: { textAlign: "left" },
          minWidth: 10,
        },
        {
          Header: <DivCenter>Tipo</DivCenter>,
          accessor: "type",
          Cell: d =>{
            switch (d.original.type) {
              case 'Y':
                return 'Video';
            
              case 'D':
                return 'Documento';
            }
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10,
        },
        {
          Header: <DivCenter>Fecha de creación</DivCenter>,
          accessor: "created",
          Cell: d => {
            return moment(d.original.created)
              .local()
              .format("DD-MM-YY : hh:mm A");
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10
        },
        {
          Header: "",
          id: "actions",
          Cell: d => {
            return (
              <div>
                {d.original.type === "Y" ? (
                  <Tooltip title="Editar">
                    <Link
                      to={{
                        pathname: `/tutorials/tutorial/edit`,
                        state: {
                          tutorial: d.original
                        }
                      }}
                    >
                      <Button justIcon round simple color="info">
                        <EditIcon />
                      </Button>{" "}
                    </Link>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            );
          },
          filterable: false,
          sortable: false,
          style: { textAlign: "center" },
          minWidth: 10
        }
      ]}
      //defaultPageSize={15}
      showPaginationTop={false}
      showPaginationBottom={false}
      className="-striped -highlight"
    />
  );
};

export default TutorialsInfoTable;
