import React from 'react'
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { useTranslation } from 'react-i18next';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import GeofenceGroupFormFK from '../../forms/Geofences/GeofencesForm';
import history from '../../utils/history';
import Button from "components/CustomButtons/Button.jsx";

export const NewGeofenceGroupView = (props) => {
    const { t } = useTranslation("geofences");
    const { onSubmit, geofenceGroup, archived } = props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <CardIcon color="info">
                <FormatShapesIcon />
              </CardIcon>
              <div style={styles.right}>
                <Button
                  size="sm"
                  color="info"
                  onClick={() => {
                    
                    history.push(`/geofence-group/add`, { geofenceGroup: geofenceGroup })
                  }}
                >
                  {t("geofences.newGeofence")}
                </Button>
              </div>
              <h4>{t("geofences.forms.title")}</h4>
            </CardHeader>
            <CardBody>
                <GeofenceGroupFormFK geofenceGroup={geofenceGroup} onSubmit={onSubmit} archived={archived} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

const styles = {
    right: {
      float: "right"
    }
  };