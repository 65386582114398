import React from "react";
import {Field, Form, withFormik} from "formik";
import * as yup from 'yup';
import { useTranslation } from "react-i18next";

// custom components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ButtonIcon from "components/styled/ButtonIcon";

// core components
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";

// icons
import Send from "@material-ui/icons/Send";


/*
* Validation Schema for deFormSchema
* */
const realtimeLocationsConfigUpsertFormSchema = yup.object().shape({
  instanceId: yup.string().required(),
  segmentId: yup.string().required(),
  interval: yup.number().positive().required(),
  scheduleInit:
    yup.number().positive().min(0).max(23).required(),
  scheduleEnd:
    yup.number().positive().min(0).max(23).required(),
  active: yup.boolean()
});

const RealtimeLocationsConfigUpsertForm = (props) => {
  const { t } = useTranslation("checklistAnalisys");

  // Formik bag
  const {
    setFieldValue,
    submitForm,
    values,
    errors,
    touched
  } = props;
  console.log('errors', errors);
  console.log('values', values);

  // collections
  const {segments, cancelEdit} = props;
  const segmentSelected = segments.find((segment) => {
    return segment._id === values.segmentId
  });

  return (
    <Form>
      <GridContainer>
        <GridItem xs={4}>
          <Autocomplete
            id="segment"
            value={segmentSelected}
            options={segments}
            getOptionLabel={option => option.name}
            onChange={(event, newValue) => {
              if (newValue) {
                setFieldValue('segmentId', newValue._id);
              }else{
                setFieldValue('segmentId', '');
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={'Segmento'}
                margin="normal"
                fullWidth
                error={errors.segmentId && touched.segmentId}
              />
            )}
          />
        </GridItem>
        <GridItem xs={4}>
          <TextField
            label={'Hora Inicial'}
            margin="normal"
            fullWidth
            error={errors.scheduleInit && touched.scheduleInit}
            value={values.scheduleInit}
            defaultValue={values.scheduleInit}
            onChange={(event) => {
              setFieldValue('scheduleInit', event.target.value);
            }}
            inputProps={{
              type: 'number'
            }}
          />
        </GridItem>
        <GridItem xs={4}>
          <TextField
            label={'Hora Final'}
            margin="normal"
            fullWidth
            error={errors.scheduleEnd && touched.scheduleEnd}
            value={values.scheduleEnd}
            defaultValue={values.scheduleEnd}
            onChange={(event) => {
              setFieldValue('scheduleEnd', event.target.value);
            }}
            inputProps={{
              type: 'number'
            }}
          />
        </GridItem>
        <GridItem xs={4}>
          <TextField
            label={'Intervalo de toma de localizacion en minutos'}
            margin="normal"
            fullWidth
            error={errors.interval && touched.interval}
            value={values.interval}
            defaultValue={values.interval}
            onChange={(event) => {
              setFieldValue('interval', event.target.value);
            }}
            inputProps={{
              type: 'number'
            }}
          />
        </GridItem>
        <GridItem xs={4}>
          <div style={{marginTop: '32px', display: 'flex', justifyContent: 'center'}}>
            <FormControlLabel
              control={
                <Switch checked={values.active}
                        onChange={() => {
                          setFieldValue('active', !values.active)
                        }}
                        name="active"
                        inputProps={{'aria-label': 'secondary checkbox'}}/>
              }
              label="Activo"
            />
          </div>
        </GridItem>
        <GridItem xs={2}>
          <div style={{marginTop: '32px'}}>
            <ButtonIcon
              size="sm"
              icon={Send}
              block
              label={t('instances.send')}
              color={"info"}
              onClick={() => {
                submitForm();
              }}
            />
          </div>
        </GridItem>
        <GridItem xs={2}>
          <div style={{marginTop: '32px'}}>
            <ButtonIcon
              size="sm"
              icon={Send}
              block
              label={'Cancelar'}
              color={"danger"}
              onClick={cancelEdit}
            />
          </div>
        </GridItem>
      </GridContainer>
    </Form>
  );
};


const RealtimeLocationsConfigUpsertFormWF = withFormik({
  validationSchema: realtimeLocationsConfigUpsertFormSchema,
  enableReinitialize: true,
  mapPropsToValues: ({instance, config}) => {
    const [init, end] = config ? config.schedule.split('-') : '9-21';
    return ({
      instanceId: instance.id,
      segmentId: config ? config.segmentId : '',
      scheduleInit: init,
      scheduleEnd: end,
      interval: config ? config.interval : 10,
      active: config ? config.active : true
    });
  },
  handleSubmit: (values, {props}) => {
    console.log('submit values', values);
    props.submitSuccess(values);
  },
})(RealtimeLocationsConfigUpsertForm);

export default RealtimeLocationsConfigUpsertFormWF;
