import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import EmailIcon from '@material-ui/icons/Email';
import TextField from '@material-ui/core/TextField';
import DivCenter from '../../components/styled-components/DivCenter';

const MessagesContentView = ({message}) => {
    return (
      <GridContainer justify="center">
        <GridItem xs={8}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <EmailIcon />
              </CardIcon>
              <div style={style.left}>
                <h4 style={style.cardTitle}>Detalle de Mensaje</h4>
              </div>
            </CardHeader>
            <CardBody>
                <TextField style={style.inputStyle} id="standard-basic" defaultValue={message.title} label="Titulo"  fullWidth disabled />
                <TextField style={style.inputStyle} id="standard-basic" defaultValue={message.subject} label="Tema"  fullWidth disabled/>
                <div style={style.inputStyle} >
                  <DivCenter>Contenido</DivCenter>
                  <div className="content" dangerouslySetInnerHTML={{__html: message.contentHtml}} ></div>
                </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}

const style = {
    cardTitle: {
      color: "#3C4858"
    },
    cardHeight:{
      height: "430px"
    },
    left:{
      float: 'left'
    },
    right:{
      float: 'right'
    },
    inputStyle:{
      marginTop: '15px',
      color: '#000'
    }
  };

export default MessagesContentView;
