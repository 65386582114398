import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTable from "react-table";
import DivCenter from '../../components/styled-components/DivCenter';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { PERMISSIONS } from '../../config/constants';

const RolesTable = (props) => {
    const { roles, archived } = props;
    const { t } = useTranslation('users');

    return (
      <ReactTable
        data={roles}
        columns={[
          {
            Header: <DivCenter>{t("users.roles.list.name")}</DivCenter>,
            accessor: "name",
            filterable: false,
            sortable: false,
          },
          {
            Header: <DivCenter>{t("users.roles.list.permissions")}</DivCenter>,
            id: "permissions",
            Cell: ({row}) => {
                const { _original } = row; 
                return (
                  <GridContainer justify="center">
                    <GridItem xs={12}>
                        <DivCenter>{`${_original.permissions.length} / ${PERMISSIONS.length}`}</DivCenter>
                    </GridItem>
                    <GridItem xs={12}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={(_original.permissions.length * 100 / PERMISSIONS.length)}
                      />
                    </GridItem>
                  </GridContainer>
                );
            },
            filterable: false,
            sortable: false,
          },
        ]}
        defaultPageSize={roles.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
        style={{
          height: "600px",
          overflowY: "scroll",
        }}
      />
    );
}

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

export default RolesTable;
