import React from "react";
import useGlobalHook from "use-global-hook";

import * as actions from "../actions";


const sessionState = localStorage.getItem('session');

const initialState = {
  session: sessionState ? {...JSON.parse(sessionState), cached: true} : {
    user: null,
    token: '',
    expiration: '',
    creation: '',
    loading: false,
    cached: false
  },
  notifications: {
    'tr': {
      open: false
    }
  }
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;